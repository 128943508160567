import {
  getBlockNameInFlowChartNodeName,
  IFlowChartNode,
} from '@storyplay/common'
import React from 'react'
import styled from 'styled-components'

interface IProps {
  containerRef: React.MutableRefObject<any>
  node: IFlowChartNode
}

export const FlowChartOtherGroupBlock = (props: IProps) => {
  const { containerRef, node } = props

  // FlowChart 를 그리기 위해 nodeName 에 접미사를 붙였는데 해당 부분을 제거 한다.
  const nodeName = getBlockNameInFlowChartNodeName(node.nodeName)
  return (
    <Container
      ref={containerRef}
      className={
        'flex justify-center items-center border-1 border-gray-300 bg-gray-200 space-x-1.5'
      }
    >
      <span className={'text-sm text-black font-medium'}>{nodeName}</span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4714 1.52864C14.7318 1.78899 14.7318 2.2111 14.4714 2.47145L9.13807 7.80478C8.87772 8.06513 8.45561 8.06513 8.19526 7.80478C7.93491 7.54443 7.93491 7.12232 8.19526 6.86197L13.5286 1.52864C13.7889 1.26829 14.2111 1.26829 14.4714 1.52864Z"
          fill="#7E8185"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.666 2.00004C10.666 1.63185 10.9645 1.33337 11.3327 1.33337H13.9993C14.3675 1.33337 14.666 1.63185 14.666 2.00004V5.33337C14.666 5.70156 14.3675 6.00004 13.9993 6.00004C13.6312 6.00004 13.3327 5.70156 13.3327 5.33337V2.66671H11.3327C10.9645 2.66671 10.666 2.36823 10.666 2.00004Z"
          fill="#7E8185"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.50425 2.50364C3.36544 1.64244 4.6349 1.33337 6.20065 1.33337H7.40065C7.76884 1.33337 8.06732 1.63185 8.06732 2.00004C8.06732 2.36823 7.76884 2.66671 7.40065 2.66671H6.20065C4.7664 2.66671 3.93586 2.95764 3.44706 3.44645C2.95825 3.93525 2.66732 4.76579 2.66732 6.20004V9.80004C2.66732 11.2343 2.95825 12.0648 3.44706 12.5536C3.93586 13.0424 4.7664 13.3334 6.20065 13.3334H9.80065C11.2349 13.3334 12.0654 13.0424 12.5542 12.5536C13.0431 12.0648 13.334 11.2343 13.334 9.80004V8.60004C13.334 8.23185 13.6325 7.93337 14.0007 7.93337C14.3688 7.93337 14.6673 8.23185 14.6673 8.60004V9.80004C14.6673 11.3658 14.3582 12.6353 13.4971 13.4964C12.6359 14.3576 11.3664 14.6667 9.80065 14.6667H6.20065C4.6349 14.6667 3.36544 14.3576 2.50425 13.4964C1.64305 12.6353 1.33398 11.3658 1.33398 9.80004V6.20004C1.33398 4.63429 1.64305 3.36483 2.50425 2.50364Z"
          fill="#7E8185"
        />
      </svg>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  min-width: 180px;
  max-width: 180px;
  padding: 8px;
  min-height: 56px;
  border-radius: 8px;
`
