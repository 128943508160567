import { I_OD_ADMIN_OPTIONS, ODWebUtils } from '@odc/od-react-belt'
import { APP_ENV } from '@storyplay/common'
import moment from 'moment'
import preval from 'preval.macro'
import { ToastOptions } from 'react-toastify'
import { LOCAL_STORAGE_KEY_TOKEN } from '../common'
import { ANNOUNCEMENT_FILE_NAME } from '../IAnnouncement'

// 이 버전은 배포 전, 이해하기 쉬운 버전명으로 수동 기입합니다.
// 릴리즈 노트에 해당 내용이 포함될 수 있도록 해야 합니다.
// 버전은 package.json 버전을 이용하며, 개발시 react dev 서버를 재시작해야 반영됩니다.
// 버전은 앞으로 x.y.z 만 사용합니다. dash 를 포함하면 비교가 제대로 되지 않습니다.
export const STUDIO_VERSION_RAW = process.env.REACT_APP_VERSION ?? '0.0.0'
const STUDIO_VERSION = `v${process.env.REACT_APP_VERSION}`
const buildTime: Date = preval`module.exports = new Date()`
const RELEASE_NEW_UNTIL = moment(buildTime).add(7, 'day').endOf('day').toDate()

export interface APP_OPTIONS extends I_OD_ADMIN_OPTIONS {
  SERVER_ADDRESS: string
  WS_SERVER_ADDRESS: string
  SHOW_BUILD_INFO: boolean
  GOOGLE_CLIENT_ID: string
  GOOGLE_API_KEY: string
  SIMULATE_DELAY: number
  TOAST_ERROR_OPTIONS: Partial<ToastOptions>
  TOAST_INFO_OPTIONS: Partial<ToastOptions>
  TOAST_SUCCESS_OPTIONS: Partial<ToastOptions>
  FILL_MOCK_DATA: boolean
  SHOW_DEV_MENU: boolean
  STUDIO_VERSION: string
  RELEASE_NOTE_LINK: string
  RELEASE_NEW_UNTIL: Date
  PROMPT_BEFORE_LEAVE: boolean
  SHOW_ICON_AS_DEV: boolean
  SHOW_BETA_ICON: boolean
  FEEDBACK_CHANNEL_LINK: string
  LOCAL_BUNDLE_BACKUP_PREFIX: string
  SSO_LOGIN_URL: string
  EMAIL_REGISTER_URL: string
  ALLOW_STUDIO_EXPORT_EXCEL_TO_ANYONE: boolean
  SHOW_VERSION_UPDATE_NOTIFICATION: boolean
  CHECK_VERSION_JSON_FROM_PATH: string
  CHECK_ANNOUNCEMENT_JSON_FROM_PATH: string
  GOOGLE_ANALYTICS_ID: string | undefined
  GOOGLE_ANALYTICS_TEST_MODE: boolean
  CHANNEL_IO_PLUGIN_KEY: string | undefined
  CAN_PLAY_TUTORIAL: boolean
  MEDIA_SERVER_ADDRESS: string
  APP_ENV: APP_ENV
  USE_HELLOBOT: boolean
  USE_RICH_EDITOR: boolean
}

const DEF_TOAST_OPTIONS = { autoClose: 5000 }

const DevAppOptions: APP_OPTIONS = {
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS:
    process.env.REACT_APP_SERVICE === 'hellobot'
      ? 'https://dev-api.hellobotstudio.com/studio'
      : 'https://api.dev.storyplay.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  GOOGLE_CLIENT_ID:
    '911452634253-gn9gkabfu1hmbe429j8k703b978cqmie.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyDXtyyBdr-yzaHqNpBKR9ClU8LTyfwqxUk',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: true,
  SHOW_DEV_MENU: true,
  STUDIO_VERSION: `dev-${STUDIO_VERSION}`,
  RELEASE_NOTE_LINK: `https://odcode.notion.site/3d2ec0f4bfd74727b3ef875cbc6165a7`,
  RELEASE_NEW_UNTIL,
  PROMPT_BEFORE_LEAVE: false,
  SHOW_ICON_AS_DEV: true,
  SHOW_BETA_ICON: false,
  FEEDBACK_CHANNEL_LINK: 'https://game.naver.com/lounge/storyplay/board/13',
  LOCAL_BUNDLE_BACKUP_PREFIX: 'local_',
  // 로컬 환경에서 SSO_LOGIN 테스트를 하려면
  // SERVER_ADDRESS 를 QA 서버 주소로 바꾸어야 함
  SSO_LOGIN_URL:
    'https://dev.storyplay.com/auth/studio?redirect=http://localhost:3031/login',
  EMAIL_REGISTER_URL:
    'https://dev.storyplay.com/auth/register?redirect=http://localhost:3031/login',
  ALLOW_STUDIO_EXPORT_EXCEL_TO_ANYONE: true,
  SHOW_VERSION_UPDATE_NOTIFICATION: false,
  CHECK_VERSION_JSON_FROM_PATH:
    'http://localhost:8082/public/studio-version.json',
  CHECK_ANNOUNCEMENT_JSON_FROM_PATH: `http://localhost:8082/public/${ANNOUNCEMENT_FILE_NAME}`,
  GOOGLE_ANALYTICS_ID:
    process.env.REACT_APP_SERVICE === 'hellobot'
      ? 'G-B1C208L100'
      : 'G-RTFCEMPEP4',
  GOOGLE_ANALYTICS_TEST_MODE: true,
  CHANNEL_IO_PLUGIN_KEY: undefined,
  CAN_PLAY_TUTORIAL: true,
  MEDIA_SERVER_ADDRESS: 'https://dev-media.storyplay.com',
  APP_ENV: 'local',
  USE_HELLOBOT: process.env.REACT_APP_SERVICE === 'hellobot',
  USE_RICH_EDITOR: true,
}

const QAAppOptions: APP_OPTIONS = {
  ...DevAppOptions,
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS:
    process.env.REACT_APP_SERVICE === 'hellobot'
      ? 'https://dev-api.hellobotstudio.com/studio'
      : 'https://api.dev.storyplay.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: true,
  GOOGLE_CLIENT_ID:
    '213722218875-f4ohbasstcp8in8s7aiak23389ig823r.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyDn7470VQIjCh55AXp2POlhHm1bECRJ1Wk',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: false,
  SHOW_DEV_MENU: false,
  STUDIO_VERSION: `qa-${STUDIO_VERSION}`,
  PROMPT_BEFORE_LEAVE: false,
  LOCAL_BUNDLE_BACKUP_PREFIX: 'dev_',
  SSO_LOGIN_URL:
    'https://dev.storyplay.com/auth/studio?redirect=https://studio.dev.storyplay.com/login',
  EMAIL_REGISTER_URL:
    'https://dev.storyplay.com/auth/register?redirect=https://studio.dev.storyplay.com/login',

  ALLOW_STUDIO_EXPORT_EXCEL_TO_ANYONE: true,
  SHOW_VERSION_UPDATE_NOTIFICATION: true,
  CHECK_VERSION_JSON_FROM_PATH:
    'https://studio.dev.storyplay.com/studio-version.json',
  CHECK_ANNOUNCEMENT_JSON_FROM_PATH: `https://studio.dev.storyplay.com/${ANNOUNCEMENT_FILE_NAME}`,
  GOOGLE_ANALYTICS_ID:
    process.env.REACT_APP_SERVICE === 'hellobot'
      ? 'G-B1C208L100'
      : 'G-RTFCEMPEP4',
  GOOGLE_ANALYTICS_TEST_MODE: false,
  CHANNEL_IO_PLUGIN_KEY: '3c4930c1-2fdb-40d0-afc0-1950a36b14e8',
  CAN_PLAY_TUTORIAL: true,
  MEDIA_SERVER_ADDRESS: 'https://dev-media.storyplay.com',
  APP_ENV: 'dev',
  USE_HELLOBOT: process.env.REACT_APP_SERVICE === 'hellobot',
}

const StgAppOptions: APP_OPTIONS = {
  ...DevAppOptions,
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS: 'https://stg-api.storyplay.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: false,
  GOOGLE_CLIENT_ID:
    '213722218875-ohf5k9g3lhoudbl6ulhbomit7uotu8mh.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyDn7470VQIjCh55AXp2POlhHm1bECRJ1Wk',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: false,
  SHOW_DEV_MENU: false,
  STUDIO_VERSION: `${STUDIO_VERSION}`,
  PROMPT_BEFORE_LEAVE: true,
  SHOW_ICON_AS_DEV: false,
  SHOW_BETA_ICON: true,
  LOCAL_BUNDLE_BACKUP_PREFIX: 'stg_',
  SSO_LOGIN_URL:
    'https://storyplay.com/auth/studio?redirect=https://studio.storyplay.com/login',
  EMAIL_REGISTER_URL:
    'https://storyplay.com/auth/register?redirect=https://studio.storyplay.com/login',
  ALLOW_STUDIO_EXPORT_EXCEL_TO_ANYONE: false,
  SHOW_VERSION_UPDATE_NOTIFICATION: true,
  CHECK_VERSION_JSON_FROM_PATH:
    'https://studio.storyplay.com/studio-version.json',
  CHECK_ANNOUNCEMENT_JSON_FROM_PATH: `https://studio.storyplay.com/${ANNOUNCEMENT_FILE_NAME}`,
  GOOGLE_ANALYTICS_ID: 'G-WQKW09HB95',
  GOOGLE_ANALYTICS_TEST_MODE: false,
  CHANNEL_IO_PLUGIN_KEY: '3c4930c1-2fdb-40d0-afc0-1950a36b14e8',
  CAN_PLAY_TUTORIAL: true,
  MEDIA_SERVER_ADDRESS: 'https://media.storyplay.com',
  APP_ENV: 'stg',
}

const ProdAppOptions: APP_OPTIONS = {
  ...DevAppOptions,
  LOCAL_STORAGE_KEY_TOKEN: LOCAL_STORAGE_KEY_TOKEN,
  SERVER_DEFINED_ERROR_PREFIX: 'SP-',
  SERVER_ADDRESS:
    process.env.REACT_APP_SERVICE === 'hellobot'
      ? 'https://prod.hellobotstudio.com/studio'
      : 'https://studio-api.storyplay.com',
  WS_SERVER_ADDRESS: '',
  SHOW_BUILD_INFO: false,
  GOOGLE_CLIENT_ID:
    '213722218875-ohf5k9g3lhoudbl6ulhbomit7uotu8mh.apps.googleusercontent.com',
  GOOGLE_API_KEY: 'AIzaSyDn7470VQIjCh55AXp2POlhHm1bECRJ1Wk',
  SIMULATE_DELAY: 0,
  TOAST_ERROR_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_INFO_OPTIONS: DEF_TOAST_OPTIONS,
  TOAST_SUCCESS_OPTIONS: DEF_TOAST_OPTIONS,
  FILL_MOCK_DATA: false,
  SHOW_DEV_MENU: false,
  STUDIO_VERSION: `${STUDIO_VERSION}`,
  PROMPT_BEFORE_LEAVE: true,
  SHOW_ICON_AS_DEV: false,
  SHOW_BETA_ICON: true,
  LOCAL_BUNDLE_BACKUP_PREFIX: 'prod_',
  SSO_LOGIN_URL:
    'https://storyplay.com/auth/studio?redirect=https://studio.storyplay.com/login',
  EMAIL_REGISTER_URL:
    'https://storyplay.com/auth/register?redirect=https://studio.storyplay.com/login',
  ALLOW_STUDIO_EXPORT_EXCEL_TO_ANYONE: false,
  SHOW_VERSION_UPDATE_NOTIFICATION: true,
  CHECK_VERSION_JSON_FROM_PATH:
    'https://studio.storyplay.com/studio-version.json',
  CHECK_ANNOUNCEMENT_JSON_FROM_PATH: `https://studio.storyplay.com/${ANNOUNCEMENT_FILE_NAME}`,
  GOOGLE_ANALYTICS_ID:
    process.env.REACT_APP_SERVICE === 'hellobot'
      ? 'G-1PD0KR2Q5W'
      : 'G-WQKW09HB95',
  GOOGLE_ANALYTICS_TEST_MODE: false,
  CHANNEL_IO_PLUGIN_KEY: '3c4930c1-2fdb-40d0-afc0-1950a36b14e8',
  CAN_PLAY_TUTORIAL: true,
  MEDIA_SERVER_ADDRESS: 'https://media.storyplay.com',
  APP_ENV: 'prod',
  USE_HELLOBOT: process.env.REACT_APP_SERVICE === 'hellobot',
}

export const AppOptions =
  process.env.REACT_APP_ENV === 'prod'
    ? ProdAppOptions
    : process.env.REACT_APP_ENV === 'stg'
    ? StgAppOptions
    : process.env.REACT_APP_ENV === 'qa'
    ? QAAppOptions
    : DevAppOptions

ODWebUtils.setAppOptions(AppOptions)
