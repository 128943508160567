import { registerServerErrorMessageMap } from '@odc/od-react-belt'

export const Strings = {
  Add: 'Add',
  Edit: 'Edit',
}

type ErrorConvertingFunc = (message: string) => string

export const ServerErrors: { [key: number]: string | ErrorConvertingFunc } = {
  30_000: (msg: string) => {
    if (msg.includes(':')) {
      return msg.split(':')[1]
    }
    return msg
  },
  30_101: `어드민에 접근할 수 없는 계정입니다.`,
  30_102: `비밀번호 조건에 맞지 않습니다.`,
  40_000: `기존 비밀번호가 맞지 않습니다.`,
  // [ErrorCode.ServerWrongLoginInfo]: 'Failed to login.',
}

Object.keys(ServerErrors).forEach((key) => {
  // @ts-ignore
  registerServerErrorMessageMap(key, ServerErrors[key])
})
