import { makeObservable, observable, runInAction } from 'mobx'
import { createContext, useContext } from 'react'

//라이브러리를 새로 임포트하기가 싫어서 만든, 몹엑스객체 하나를 전역적으로 활용하는 리액트 컨텍스트이다.

export class GlobalTitleStore {
  title: string = 'Initial Global Title'

  setTitle = (newTitle: string) => {
    runInAction(() => {
      this.title = newTitle
    })
  }

  constructor() {
    makeObservable(this, {
      title: observable,
    })
  }
}

export const globalTitleStore = new GlobalTitleStore()

const GlobalTitleStoreContext = createContext(globalTitleStore)

export const useGlobalTitleStore = () => {
  return useContext(GlobalTitleStoreContext)
}
