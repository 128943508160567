import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { i18next, resources } from '@storyplay/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { initReactI18next } from 'react-i18next'
import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import './tailwind.css'
import { AppOptions } from './utils/AppOptions'

i18next.use(initReactI18next).init({
  lng: navigator.language === 'ko-KR' ? 'ko' : 'en',
  resources,
  fallbackLng: 'ko',
  interpolation: {
    escapeValue: false,
  },
})

Sentry.init({
  dsn:
    AppOptions.APP_ENV !== 'local'
      ? 'https://70323edfa63f46aeb652a537283c0ea5@sentry.thingsflow.com/4'
      : undefined,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  release: `${AppOptions.STUDIO_VERSION}`,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
})

ReactDOM.render(
  <HelmetProvider>
    <App />
  </HelmetProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
