import { padStart } from 'lodash'
import { AppOptions } from '../utils/AppOptions'

export const COLORS = {
  GRAY200: '#EEEEEE',
  GRAY600: '#939191',
  GRAY900: '#232222',
  GOLD500: '#b5944b',
  ACCENT: '#b5944b',
  Primary: '#b5944b',
  DimmedText: '#393939',
  DefaultIconColor: '',
  SIDEBAR_BG: '#000000',
  HEADER_BG: '#ffffff',
}

const SP_STUDIO_COLORS = {
  Primary100: '#F3E8FF',
  Primary300: '#AB85D4',
  Primary500: '#753BBD',
  Primary600: '#631FB7',
  PrimaryHover: '#F8F5FC',
  PrimaryDark: '#631FB7',
  PrimarySelectedChar: 'rgba(146, 109, 251, 0.3)',
  PrimaryTranslucent30: 'rgba(117, 59, 189, 0.3)',
  SubEmerald500: '#2CCCD3',
  SubEmeraldTranslucent: 'rgba(44, 204, 211, 0.1)',
  DangerPrimary500: '#DB2B00',
  DangerPrimary600: '#FF5B56',
  DangerBlock: '#FFD9D8',
  White: '#FFFFFF',
  Gray50: '#FAFAFA',
  Gray100: '#f5f5f5',
  Gray200: '#EDEDEE',
  Gray300: '#DDDEE1',
  Gray400: '#c6c8cc',
  Gray500: '#A4A7AD',
  Gray600: '#7e8185',
  Gray700: '#57595E',
  Gray800: '#3f4142',
  Gray900: '#242526',
  GrayTranslucent10: 'rgba(36, 37, 38, 0.1)',
  PointYellow: '#FFFE79',
  PointAmber: '#FFD31A',
  PointOrange: '#FF970C',
  PointGreen: '#24CA8E',
  PointRedHeart: '#FF5B56',
  PointPrimaryDark2: '#450891',
  Red100: '#FEE2E2',
  Red800: '#991B1B',
}

const HB_STUDIO_COLORS = {
  Primary100: '#E0E7FF',
  Primary300: '#A5B4FC',
  Primary500: '#5F63F4',
  Primary600: '#3D41CD',
  PrimaryHover: '#F7F7FE',
  PrimaryDark: '#3D41CD',
  PrimarySelectedChar: 'rgba(224, 231, 255, 0.3)',
  PrimaryTranslucent30: 'rgba(199, 210, 254, 0.3)',
  SubEmerald500: '#2CCCD3',
  SubEmeraldTranslucent: 'rgba(44, 204, 211, 0.1)',
  DangerPrimary500: '#DB2B00',
  DangerPrimary600: '#FF5B56',
  DangerBlock: '#FFD9D8',
  White: '#FFFFFF',
  Gray50: '#FAFAFA',
  Gray100: '#f5f5f5',
  Gray200: '#EDEDEE',
  Gray300: '#DDDEE1',
  Gray400: '#c6c8cc',
  Gray500: '#A4A7AD',
  Gray600: '#7e8185',
  Gray700: '#57595E',
  Gray800: '#3f4142',
  Gray900: '#242526',
  GrayTranslucent10: 'rgba(36, 37, 38, 0.1)',
  PointYellow: '#FFFE79',
  PointAmber: '#FFD31A',
  PointOrange: '#FF970C',
  PointGreen: '#24CA8E',
  PointRedHeart: '#FF5B56',
  PointPrimaryDark2: '#3730A3',
  Red100: '#FEE2E2',
  Red800: '#991B1B',
}

export const STUDIO_COLORS = AppOptions.USE_HELLOBOT
  ? HB_STUDIO_COLORS
  : SP_STUDIO_COLORS

export const alpha = (color: string, alpha: number) =>
  `${color}${padStart(Math.round(alpha * 255).toString(16), 2, '0')}`
