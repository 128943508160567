import * as Sentry from '@sentry/react'
import {
  FEATURE_FLAG,
  IFlowChartNode,
  IStudioAPIServer,
  IStudioDI,
  LocalStorageConfigProvider,
  StudioAPIServer,
} from '@storyplay/common'
import { HbStudioAPIServer } from '@storyplay/common/lib/api/HbStudioAPIServer'
import { isString, random } from 'lodash'
import React from 'react'
import ReactGA from 'react-ga4'
import { toast } from 'react-toastify'
import uuid from 'uuid'

import {
  StudioDiConstants,
  hellobotDiConstants,
  storyplayDiConstants,
} from '@storyplay/common'
import { adviceContainerProxy } from '@storyplay/common/lib/studio/aop/adviceContainterProxy'
import '../../../advisors/hellobot/advisorLoader'
import { hbAdvisorContainer } from '../../../advisors/hellobot/hbAdvisorContainer'
import { AppOptions } from '../../../utils/AppOptions'
import { StudioCommonToast } from '../ui/common/StudioCommonToast'
import { TutorialCompleteToast } from '../ui/common/TutorialCompleteToast'
import { FlowChartBlock } from '../ui/flowChart/FlowChartBlock'
import { RESTAPIClient } from './client/RESTAPIClient'
import { StudioAPIClient } from './client/StudioAPIClient'

export class ReactStudioDI implements IStudioDI {
  server: IStudioAPIServer
  config = new LocalStorageConfigProvider(localStorage)
  tokenStorageKey = AppOptions.LOCAL_STORAGE_KEY_TOKEN
  serverHashId = AppOptions.SERVER_ADDRESS
  localStorage = localStorage
  reactGA4 = ReactGA
  diConstants: StudioDiConstants

  devFillMockData = AppOptions.FILL_MOCK_DATA
  canPlayTutorial = AppOptions.CAN_PLAY_TUTORIAL
  allowStudioExportExcelToAnyOne =
    AppOptions.ALLOW_STUDIO_EXPORT_EXCEL_TO_ANYONE

  studioVersion = AppOptions.STUDIO_VERSION
  appEnv = AppOptions.APP_ENV
  // rootStore: IStudioRootStore
  // useHellobot = AppOptions.USE_HELLOBOT

  redirectToUrl(url: string) {
    // will be injected in configureRootStore.ts
  }

  constructor() {
    if (AppOptions.USE_HELLOBOT) {
      this.server = new HbStudioAPIServer(
        new RESTAPIClient(
          async () => localStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN),
          AppOptions.SERVER_ADDRESS
        )
      )
      this.diConstants = hellobotDiConstants
      adviceContainerProxy.setAdviceContainer(hbAdvisorContainer)
    } else {
      const client = new StudioAPIClient(
        async () => localStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN),
        AppOptions.SERVER_ADDRESS //https://dev-api.hellobotstudio.com
      )
      this.server = new StudioAPIServer(client)
      this.diConstants = storyplayDiConstants
    }
  }

  renderFlowChartBlock(block: IFlowChartNode): any {
    return <FlowChartBlock block={block} />
  }

  showMessage(message: string, title?: string) {
    toast(
      <StudioCommonToast
        icon={'SUCCESS'}
        message={message}
        title={title ?? '성공'}
      />,
      {
        containerId: 'default',
        hideProgressBar: true,
        closeButton: false,
        autoClose: 5000,
        position: 'top-right',
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    )
    // ODWebUtils.showInfo(message, undefined, { containerId: 'default' })
  }

  sentryCaptureError(error: string | Error) {
    const shouldSendError = (() => {
      if (isString(error)) {
        // 'SP' 가 포함되어 있으면 텍스트로 변환되지 않은 에러들 또는 알 수 없는 오류
        // 'UError' 가 붙어있으면 알 수 없는 오류 또는 웹 코드에서 try/catch 한 오류
        if (error.includes('SP') || error.includes('UError')) {
          return true
        }
      }

      return error instanceof Error
    })()

    if (shouldSendError) {
      const me = this.server.rootStore.loginStore.user

      if (!!me) {
        Sentry.setTag('userId', me.userId)
      }

      const err = error instanceof Error ? error : new Error(error)
      Sentry.captureException(err)
    }
  }

  showError(error: string | Error, title: string | undefined): void {
    console.error(error)
    // TODO: 오류 토스트가 이쁘게 뜨도록 해야 함.
    // ODWebUtils.showError(error, title, { containerId: 'default' })
    this.sentryCaptureError(error)
    toast(
      <StudioCommonToast
        icon={'WARNING'}
        message={error.toString()}
        title={title ?? '오류가 발생하였습니다.'}
      />,
      {
        containerId: 'default',
        hideProgressBar: true,
        closeButton: false,
        autoClose: false,
        position: 'top-right',
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    )
  }

  showTutorialCheck(
    title: string,
    bodyMessage: string,
    nextButtonName: string,
    onClick: () => void
  ) {
    toast(
      <TutorialCompleteToast
        title={title}
        bodyMessage={bodyMessage}
        nextButtonName={nextButtonName}
        onClick={onClick}
      />,
      {
        hideProgressBar: true,
        containerId: 'tutorial',
        autoClose: false,
        closeButton: false,
      }
    )
  }

  generateSourceLine() {
    // TODO: 좀 더 정확하게 unique 한 방식이 필요하다. 가능하면 number 가 아니면 좋겠는데..
    return new Date().getTime() * 1000 + random(0, 999)
  }

  generateCustomChapterId() {
    return (new Date().getTime() * 1000 + random(0, 999)).toString()
  }

  generateCustomChoiceId() {
    return `choice_${(new Date().getTime() * 1000 + random(0, 999)).toString()}`
  }

  generateCustomRemoteScriptId() {
    return `script_${(new Date().getTime() * 1000 + random(0, 999)).toString()}`
  }

  copyToClipboard(item: string | ClipboardItems) {
    if (isString(item)) {
      return navigator.clipboard.writeText(item)
    }
    return navigator.clipboard.write(item as ClipboardItems)
  }

  async readFromClipboard() {
    return navigator.clipboard.read()
  }

  /**
   * 특정 feature 의 on/off 여부를 반환한다.
   */
  isFeatureEnabled(feat: FEATURE_FLAG): boolean {
    switch (feat) {
      case FEATURE_FLAG.ADD_DEFAULT_TOAST_ON_BLOCK_CREATION:
        return false
      case FEATURE_FLAG.SAVE_FROM_STUDIO:
      case FEATURE_FLAG.SEARCH_AND_REPLACE:
        return true
      case FEATURE_FLAG.ADVANCED_FORMULA_IN_STATEMENT:
        return true
      case FEATURE_FLAG.EXECUTE_CONDITION:
        return true
      case FEATURE_FLAG.CHAT_BOT_BLOCK:
        // 내부작가만 사용할 수 있도록
        if (!!this.server.rootStore.loginStore.user?.isInternalAuthor) {
          return true
        }

        return this.appEnv !== 'prod'
      case FEATURE_FLAG.AI_BLOCK_GENERATOR:
        if (
          !!this.server.rootStore.loginStore.user?.isInternalAuthor ||
          !!this.server.rootStore.loginStore.user?.isSameOrGreaterThanAdmin
        ) {
          return true
        }

        return this.appEnv !== 'prod'
      case FEATURE_FLAG.STORY_DASHBOARD:
        return true
      case FEATURE_FLAG.STORY_WEEKDAYS:
        return true
      case FEATURE_FLAG.REMOTE_SCRIPT_CHAT_GPT:
        return true
      case FEATURE_FLAG.STORY_PROP_STRING:
        return true
      case FEATURE_FLAG.DIRECTION:
        return true
      case FEATURE_FLAG.WEB_NOVEL:
        return true
      case FEATURE_FLAG.EDIT_ENDING_DISPLAY_NAME:
      case FEATURE_FLAG.EDIT_ITEM_DISPLAY_NAME:
        return true
      case FEATURE_FLAG.GLOBAL:
        return true
      case FEATURE_FLAG.ADULT_CERTIFICATION:
        return true
      case FEATURE_FLAG.RICH_EDITOR:
        return true
      case FEATURE_FLAG.STORY_GAME:
        return (
          !!this.server.rootStore.loginStore.user?.isInternalAuthor ||
          !!this.server.rootStore.loginStore.user?.isSameOrGreaterThanAdmin
        )
      case FEATURE_FLAG.MANAGE_STORY_GAME_TABS:
        return !!this.server.rootStore.loginStore.user?.isSameOrGreaterThanAdmin
      default:
        return false
    }
  }

  generateInternalHashId() {
    return uuid.v4()
  }
}
