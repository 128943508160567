//
// https://stackoverflow.com/a/54292872/388351
//
import { DependencyList, useEffect, useRef } from 'react'

export function useOuterClick(
  callback: (e: any) => void,
  deps: DependencyList,
  clickType: string = 'click'
) {
  const innerRef = useRef<any>()
  const callbackRef = useRef<(e: any) => void>()

  // set current callback in ref, before second useEffect uses it
  useEffect(() => {
    // useEffect wrapper to be safe for concurrent mode
    callbackRef.current = callback
  })

  useEffect(() => {
    document.addEventListener(clickType, handleClick)
    return () => document.removeEventListener(clickType, handleClick)

    // read most recent callback and innerRef dom node from refs
    function handleClick(e: any) {
      if (
        innerRef.current &&
        callbackRef.current &&
        // @ts-ignore
        !innerRef.current!.contains(e.target)
      ) {
        callbackRef.current(e)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps) // no need for callback + innerRef dep

  return innerRef // return ref; client can omit `useRef`
}
