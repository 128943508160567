// Deprecated
class DOBlockAdvisor {
  //데이터가 스플에서 지워지기 전에 써야하므로 pre훅으로 한다.
  // @hbAdvisor(jointPointSymbols.DOBlock.applyChangeOp, 'pre')
  // async postApplyChangeOp(
  //   target: DOBlock,
  //   op: StudioChangeOp,
  //   type: StudioChangeActionType
  // ) {
  //   if (op.opType === StudioChangeOpType.RemoveLine) {
  //     const hbStatement = (op.lineToRemove as any).data as
  //       | HB_STATEMENT
  //       | undefined
  //     if (hbStatement?.hbExtensionData) {
  //       hbApiClient.message.delete(
  //         hbStatement.hbExtensionData.id!,
  //         this.isDoblockForAlgorithm(target)
  //       )
  //     }
  //   } else if (op.opType === StudioChangeOpType.RemoveLines) {
  //     target.statements.forEach((statement) => {
  //       if (
  //         op.lineUniqueIds.includes(
  //           (statement as DOScriptStatementType).uniqueId
  //         )
  //       ) {
  //         const hbStatement = (statement as any).data as
  //           | HB_STATEMENT
  //           | undefined
  //         if (hbStatement?.hbExtensionData) {
  //           hbApiClient.message.delete(
  //             hbStatement.hbExtensionData.id!,
  //             this.isDoblockForAlgorithm(target)
  //           )
  //         }
  //       }
  //     })
  //   } else if (op.opType === StudioChangeOpType.AddLine) {
  //     const doBlock = target as HbExtDoBlock //fix type
  //     const doStatement = op.lineToAdd
  //     const converter = new StoryplayToHellobotConverter(
  //       target.rootStore as HbStudioRootStore
  //     )
  //     const hbMessage = converter.newStatementToNewHbMessage(
  //       doBlock,
  //       doStatement,
  //       this.isDoblockForAlgorithm(doBlock)
  //     )
  //     if (hbMessage) {
  //       // console.log('hbmessage', hbMessage)
  //       const addedMessage = await hbApiClient.message.create(
  //         hbMessage,
  //         this.isDoblockForAlgorithm(doBlock)
  //       )
  //       const hbDoStatement = doStatement as DOBaseScriptStatement
  //       // console.log(hbDoStatement, 'hbDoStatement')
  //       const hbStatement = hbDoStatement.data as unknown as HbExtentionType<
  //         STATEMENT,
  //         HbMessage<HbMessageObject>
  //       >
  //       hbStatement.hbExtensionData = addedMessage
  //       this.onPostHellobotMessageCreation(doBlock, hbStatement)
  //     }
  //   }
  // }
  // // 스플과 달리 빈 메시지를 먼저 생성하고 수정해야 하는 타입의 헬로봇 메시지들이 있다. 그런 메시지들의 수정 동작을 위해 존재하는 함수다.
  // async onPostHellobotMessageCreation(
  //   targetBlock: HbExtDoBlock,
  //   savedStatement: STATEMENT
  // ) {
  //   if (savedStatement.statementType === STATEMENT_TYPE.CharacterTalk) {
  //     const hbStatement = savedStatement as HB_CHR_TALK_STATEMENT
  //     // console.log('hbStatement', hbStatement)
  //     const originalHbMessage =
  //       hbStatement.hbExtensionData as HbMessage<HbMessageText>
  //     if (originalHbMessage) {
  //       await hbApiClient.message.update(
  //         originalHbMessage.id!,
  //         { type: 'text', text: hbStatement.message },
  //         this.isDoblockForAlgorithm(targetBlock)
  //       )
  //     }
  //   }
  // }
  // isDoblockForAlgorithm(doBlock: DOBlock): boolean {
  //   // HACK, 데이터가 가진 챕터스토어의 객체 동일성 비교(실제 같은 객체인지 비교)를 통해 구별
  //   const storyId = doBlock.story.storyId
  //   const blockChapterStore = doBlock.story.chapterStore
  //   const algorithmChapterStore = (
  //     doBlock.rootStore as HbStudioRootStore
  //   ).algorithmStoryStore.getById(storyId)?.chapterStore
  //   return blockChapterStore === algorithmChapterStore
  // }
}

const doBlockAdvisor = new DOBlockAdvisor()

export { doBlockAdvisor }
