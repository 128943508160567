"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DIRECT_ADS_TYPE = exports.STORY_WEEKDAY_TO_KO_STRING = exports.STORY_WEEKDAY = exports.CHAPTER_BOUNCE_STAT_TYPE = exports.STORY_RANK_TYPE = exports.STORY_CONVERSION_STAT_TYPE = exports.STORY_STAT_CACHE_TYPE = exports.AGE_GROUP = exports.SEX_DISTINCTION = exports.UserPropRemoteScriptChatGPTPrefix = exports.UserPropUserChoiceInputTextPrefix = exports.UserPropUserChoicePrefix = exports.REMOTE_SCRIPT_TYPE_TO_KO_STRING = exports.TOSS_PAYMENTS_PAY_TYPE = exports.TOSS_PAYMENTS_STATUS = exports.IMP_STATE = exports.STORY_SORTING = exports.STORY_STATUS_IN_STUDIO = exports.STORY_PLAY_TYPE = exports.FALLBACK_CURRENCY = exports.CURRENCY = exports.FALLBACK_COUNTRY_CODE = exports.COUNTRY_CODE = exports.PUBLIC_RESOURCE_TYPE = exports.STORY_GENRE_SUBTITLE_TO_EN_STRING = exports.STORY_GENRE_SUBTITLE_TO_KO_STRING = exports.CHALLENGE_STORY_GENRE_TO_KO_STRING = exports.STORY_GENRE_TO_EN_STRING = exports.STORY_GENRE_TO_KO_STRING = exports.STORY_GENRE_TITLE_TO_EN_STRING = exports.STORY_GENRE_TITLE_TO_KO_STRING = exports.CHALLENGE_STORY_GENRE = exports.PUSH_CATEGORY = exports.CHALLENGE_STORY_SORTING = exports.ScreenSizeToNumber = exports.ScreenSize = exports.ADULT_CERTIFICATION_AGE = exports.TIME_SALE_TYPE = exports.CHAPTER_COMMENT_TYPE = exports.CHOICE_TYPE = exports.PAUST_AFFECTION_LEVEL_EN = exports.PAUST_AFFECTION_LEVEL = exports.EndingRateRangeLevel = exports.StoryEndingArrivalRateType = exports.StoryType = exports.StorySectionType = exports.STUDIO_SHEET_CONST = exports.StudioFileType = exports.STUDIO_FILE_TYPE = exports.USER_PRIV = void 0;
exports.StorePageSectionUIType = exports.StorePageTabType = exports.StorygameSectionType = exports.StorygameSectionPeriodToString = exports.StorygameSectionPeriod = exports.StorygameSectionAlgorithmToString = exports.StorygameSectionAlgorithm = exports.StorygameSectionUITypeToString = exports.StorygameSectionUIType = exports.StorygameTabType = exports.STORYGAME_PREVIEW_RESOURCE_TYPE = exports.STORYGAME_IMAGE_PLATFORM_TYPE = exports.AGE_LIMIT_NOTIFICATION_URL = exports.ActivatedCountryCacheId = exports.SECTION_SUPPORTED_LANGUAGE_CODE_TO_KO_STRING = exports.SECTION_SUPPORTED_COUNTRY_CODE_TO_KO_STRING = exports.COUNTRY_CODE_TO_KO_STRING = exports.COUNTRY_CODE_BY_STUDIO_WEB = exports.COUNTRY_CODE_KR = exports.COUNTRY_CODE_ETC = exports.BANNER_STATE_TO_KO_STRING = exports.BANNER_LINK_TYPE_TO_KO_STRING = exports.BANNER_LOCATION_TO_KO_STRING = exports.CLIENT_TO_KO_STRING = exports.BANNER_TYPE_TO_KO_STRING = exports.LANGUAGE_CODE_TO_KO_STRING = exports.STORY_PLAY_TYPE_TO_KO_STRING = exports.LIST_CHAPTER_FOR_WRITER_SORT_OPTION = exports.CHAPTER_CONTENT_TYPE = exports.TRANSITION_STYLE_TO_KO_STRING = exports.SCREEN_FILL_METHOD_TO_KO_STRING = exports.GQL_STORY_PROP_TYPE_TO_KO_STRING = exports.SCRIPT_FORM_MESSAGE = exports.SCRIPT_FORM_SOURCE_LINE = exports.GENDER = exports.USER_RECOMMENDATION_ONBOARDING_STATUS = exports.RECOMMENDATION_ONBOARDING_TYPE = exports.STORY_HAS_RECOMMENDATION_TYPE = exports.STORY_BUNDLE_COUNTRY_ACCESS = exports.ITEM_BASED_RECOMMENDATION_CASE = exports.USER_BASED_RECOMMENDATION_CASE = exports.BANNER_STATE = exports.BANNER_LINK_TYPE = exports.CLIENT = exports.BANNER_LOCATION = exports.BANNER_TYPE = exports.DANAL_PAYMENT_USER_AGENT = exports.DANAL_PAYMENT_RESULT = exports.DANAL_PAYMENT_CURRENCY = exports.DANAL_PAYMENT_STATUS = void 0;
exports.StorePaymentPlatform = exports.StorePaymentStatus = exports.StoreSearchConfigStatus = exports.StoreReviewRating = exports.StoreCartItemType = exports.StorePageSectionPeriod = exports.StorePageSectionAlgorithm = void 0;
const server_schema_1 = require("../@types/server.schema");
const script_1 = require("../script");
exports.USER_PRIV = {
    Normal: 1,
    Virtual: 2,
    Formal: 3,
    Author: 5,
    SuperAuthor: 6,
    Admin: 7,
    SuperAdmin: 10, // 슈퍼 어드민
};
/**
 * 타입스크립트 ordered-imports 문제로 인해 enum 은 CamelCase 로 변경을 시작합니다.
 * 당분간 두 버전을 동시에 유지하다 추후 StudioFileType 으로 모두 변경하고자 합니다.
 */
var STUDIO_FILE_TYPE;
(function (STUDIO_FILE_TYPE) {
    STUDIO_FILE_TYPE["Image"] = "Image";
    STUDIO_FILE_TYPE["SFX"] = "SFX";
})(STUDIO_FILE_TYPE = exports.STUDIO_FILE_TYPE || (exports.STUDIO_FILE_TYPE = {}));
var StudioFileType;
(function (StudioFileType) {
    StudioFileType["Image"] = "Image";
    StudioFileType["SFX"] = "SFX";
})(StudioFileType = exports.StudioFileType || (exports.StudioFileType = {}));
exports.STUDIO_SHEET_CONST = {
    MGMT_SHEET_NAME: '_관리_',
    HERO_NAME: '{{성}}{{이름}}',
};
// TODO: move to server package.
var StorySectionType;
(function (StorySectionType) {
    StorySectionType["OnStage"] = "OnStage";
    StorySectionType["Monthly"] = "Monthly";
    StorySectionType["Dropped"] = "Dropped";
    StorySectionType["Global"] = "Global";
    StorySectionType["Adult"] = "Adult";
    StorySectionType["UGC"] = "UGC";
    // deprecated
    StorySectionType["Challenge"] = "Challenge";
})(StorySectionType = exports.StorySectionType || (exports.StorySectionType = {}));
var StoryType;
(function (StoryType) {
    StoryType["Novel"] = "Novel";
    StoryType["Chat"] = "Chat";
})(StoryType = exports.StoryType || (exports.StoryType = {}));
var StoryEndingArrivalRateType;
(function (StoryEndingArrivalRateType) {
    StoryEndingArrivalRateType["Unknown"] = "Unknown";
    StoryEndingArrivalRateType["RealValue"] = "RealValue";
    StoryEndingArrivalRateType["SettingValue"] = "SettingValue";
    StoryEndingArrivalRateType["InVisible"] = "InVisible";
})(StoryEndingArrivalRateType = exports.StoryEndingArrivalRateType || (exports.StoryEndingArrivalRateType = {}));
var EndingRateRangeLevel;
(function (EndingRateRangeLevel) {
    EndingRateRangeLevel["Unknown"] = "Unknown";
    EndingRateRangeLevel["Normal"] = "Normal";
    EndingRateRangeLevel["Rarity"] = "Rarity";
    EndingRateRangeLevel["VeryRarity"] = "VeryRarity";
    EndingRateRangeLevel["Minority"] = "Minority";
})(EndingRateRangeLevel = exports.EndingRateRangeLevel || (exports.EndingRateRangeLevel = {}));
var PAUST_AFFECTION_LEVEL;
(function (PAUST_AFFECTION_LEVEL) {
    PAUST_AFFECTION_LEVEL["\uB192\uC740 \uC560\uC815 \uD45C\uD604"] = "HighAffection";
    PAUST_AFFECTION_LEVEL["\uB0AE\uC740 \uC560\uC815 \uD45C\uD604"] = "LowAffection";
})(PAUST_AFFECTION_LEVEL = exports.PAUST_AFFECTION_LEVEL || (exports.PAUST_AFFECTION_LEVEL = {}));
var PAUST_AFFECTION_LEVEL_EN;
(function (PAUST_AFFECTION_LEVEL_EN) {
    PAUST_AFFECTION_LEVEL_EN["High Love Expression"] = "HighAffection";
    PAUST_AFFECTION_LEVEL_EN["Low Love Expression"] = "LowAffection";
})(PAUST_AFFECTION_LEVEL_EN = exports.PAUST_AFFECTION_LEVEL_EN || (exports.PAUST_AFFECTION_LEVEL_EN = {}));
var CHOICE_TYPE;
(function (CHOICE_TYPE) {
    CHOICE_TYPE["Default"] = "Default";
    CHOICE_TYPE["UserInputChoice"] = "UserInputChoice";
    CHOICE_TYPE["ImageChoice"] = "ImageChoice";
})(CHOICE_TYPE = exports.CHOICE_TYPE || (exports.CHOICE_TYPE = {}));
var CHAPTER_COMMENT_TYPE;
(function (CHAPTER_COMMENT_TYPE) {
    CHAPTER_COMMENT_TYPE["Text"] = "Text";
    CHAPTER_COMMENT_TYPE["UserInputChoice"] = "UserInputChoice";
    CHAPTER_COMMENT_TYPE["Statistics"] = "Statistics";
})(CHAPTER_COMMENT_TYPE = exports.CHAPTER_COMMENT_TYPE || (exports.CHAPTER_COMMENT_TYPE = {}));
var TIME_SALE_TYPE;
(function (TIME_SALE_TYPE) {
    TIME_SALE_TYPE["OnlyOnceSinceFirstVisitStore"] = "OnlyOnceSinceFirstVisitStore";
    TIME_SALE_TYPE["UntilFixedEndDate"] = "UntilFixedEndDate";
})(TIME_SALE_TYPE = exports.TIME_SALE_TYPE || (exports.TIME_SALE_TYPE = {}));
exports.ADULT_CERTIFICATION_AGE = 18;
// TODO: 뭔가 이상?
var ScreenSize;
(function (ScreenSize) {
    ScreenSize["Mobile"] = "Mobile";
    ScreenSize["NotMobile"] = "NotMobile";
})(ScreenSize = exports.ScreenSize || (exports.ScreenSize = {}));
exports.ScreenSizeToNumber = {
    [ScreenSize.Mobile]: 0,
    [ScreenSize.NotMobile]: 640,
};
var CHALLENGE_STORY_SORTING;
(function (CHALLENGE_STORY_SORTING) {
    CHALLENGE_STORY_SORTING["RECOMMENDED"] = "RECOMMENDED";
    CHALLENGE_STORY_SORTING["LATEST_COMMENT"] = "LATEST_COMMENT";
    CHALLENGE_STORY_SORTING["NUM_LIKES"] = "NUM_LIKES";
    CHALLENGE_STORY_SORTING["NUM_VIEWS"] = "NUM_VIEWS";
    CHALLENGE_STORY_SORTING["NUM_PLAY"] = "NUM_PLAY";
    CHALLENGE_STORY_SORTING["LATEST_UPDATED"] = "LATEST_UPDATED";
    CHALLENGE_STORY_SORTING["RANDOM"] = "RANDOM";
})(CHALLENGE_STORY_SORTING = exports.CHALLENGE_STORY_SORTING || (exports.CHALLENGE_STORY_SORTING = {}));
var PUSH_CATEGORY;
(function (PUSH_CATEGORY) {
    PUSH_CATEGORY["MY_FAVORITE_STORY"] = "MY_FAVORITE_STORY";
    PUSH_CATEGORY["MY_STORY"] = "MY_STORY";
    PUSH_CATEGORY["MY_CHAPTER_COMMENT"] = "MY_CHAPTER_COMMENT";
})(PUSH_CATEGORY = exports.PUSH_CATEGORY || (exports.PUSH_CATEGORY = {}));
var CHALLENGE_STORY_GENRE;
(function (CHALLENGE_STORY_GENRE) {
    CHALLENGE_STORY_GENRE["CONTEST_WINNER"] = "CONTEST_WINNER";
    CHALLENGE_STORY_GENRE["ROMANCE"] = "ROMANCE";
    CHALLENGE_STORY_GENRE["ROMANCE_FANTASY"] = "ROMANCE_FANTASY";
    CHALLENGE_STORY_GENRE["FANTASY"] = "FANTASY";
    CHALLENGE_STORY_GENRE["MYSTERY_PLAY"] = "MYSTERY_PLAY";
    CHALLENGE_STORY_GENRE["UNKNOWN"] = "UNKNOWN";
    CHALLENGE_STORY_GENRE["SLICE_OF_LIFE"] = "SLICE_OF_LIFE";
    CHALLENGE_STORY_GENRE["PERIOD_DRAMA"] = "PERIOD_DRAMA";
    CHALLENGE_STORY_GENRE["BL_GL"] = "BL_GL";
    CHALLENGE_STORY_GENRE["FAN_FICTION"] = "FAN_FICTION";
    CHALLENGE_STORY_GENRE["ETC"] = "ETC";
    // deprecated
    CHALLENGE_STORY_GENRE["MODERN_FANTASY"] = "MODERN_FANTASY";
})(CHALLENGE_STORY_GENRE = exports.CHALLENGE_STORY_GENRE || (exports.CHALLENGE_STORY_GENRE = {}));
// STORY_GENRE_TO_KO_STRING이 스튜디오에서도 사용되고 있어 아래 텍스트 따로 분리
exports.STORY_GENRE_TITLE_TO_KO_STRING = {
    [CHALLENGE_STORY_GENRE.CONTEST_WINNER]: '공모전 수상작',
    [CHALLENGE_STORY_GENRE.ROMANCE]: '로맨스 스토리',
    [CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: '로맨스 판타지 스토리',
    [CHALLENGE_STORY_GENRE.FANTASY]: '판타지/현판 스토리',
    [CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: '추리/스릴러/공포 스토리',
    [CHALLENGE_STORY_GENRE.UNKNOWN]: '미분류 스토리',
    [CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: '시뮬레이션 스토리',
    [CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: '무협/대체역사 스토리',
    [CHALLENGE_STORY_GENRE.BL_GL]: 'BL/GL 스토리',
    [CHALLENGE_STORY_GENRE.FAN_FICTION]: '팬픽/패러디 스토리',
    [CHALLENGE_STORY_GENRE.ETC]: '기타 스토리',
    // deprecated
    [CHALLENGE_STORY_GENRE.MODERN_FANTASY]: '현대/현대 판타지 스토리',
};
exports.STORY_GENRE_TITLE_TO_EN_STRING = {
    [CHALLENGE_STORY_GENRE.CONTEST_WINNER]: '',
    [CHALLENGE_STORY_GENRE.ROMANCE]: 'Romance Stories',
    [CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: 'Romance Fantasy Stories',
    [CHALLENGE_STORY_GENRE.FANTASY]: 'Fantasy Stories',
    [CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: 'Mystery/Thriller/Horror Stories',
    [CHALLENGE_STORY_GENRE.UNKNOWN]: '미분류 스토리',
    [CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: 'Simulation Stories',
    [CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: 'Eastern Action/Alternate History Stories',
    [CHALLENGE_STORY_GENRE.BL_GL]: 'BL/GL Stories',
    [CHALLENGE_STORY_GENRE.FAN_FICTION]: 'Fan Fiction Stories',
    [CHALLENGE_STORY_GENRE.ETC]: 'Etc Stories',
    // deprecated
    [CHALLENGE_STORY_GENRE.MODERN_FANTASY]: '현대/현대 판타지 스토리',
};
exports.STORY_GENRE_TO_KO_STRING = {
    [CHALLENGE_STORY_GENRE.CONTEST_WINNER]: '웹소설수상작',
    [CHALLENGE_STORY_GENRE.ROMANCE]: '로맨스',
    [CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: '로맨스 판타지',
    [CHALLENGE_STORY_GENRE.FANTASY]: '판타지/현판',
    [CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: '추리/스릴러/공포',
    [CHALLENGE_STORY_GENRE.UNKNOWN]: '미분류',
    [CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: '시뮬레이션',
    [CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: '무협/대체역사',
    [CHALLENGE_STORY_GENRE.BL_GL]: 'BL/GL',
    [CHALLENGE_STORY_GENRE.FAN_FICTION]: '팬픽/패러디',
    [CHALLENGE_STORY_GENRE.ETC]: '기타',
    // deprecated
    [CHALLENGE_STORY_GENRE.MODERN_FANTASY]: '현대/현대 판타지',
};
// 웹의 영문버전에 필요한 텍스트만 추가하여 적용
exports.STORY_GENRE_TO_EN_STRING = {
    [CHALLENGE_STORY_GENRE.CONTEST_WINNER]: '웹소설수상작',
    [CHALLENGE_STORY_GENRE.ROMANCE]: 'Romance',
    [CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: 'Romance Fantasy',
    [CHALLENGE_STORY_GENRE.FANTASY]: 'Fantasy',
    [CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: 'Mystery/Thriller/Horror',
    [CHALLENGE_STORY_GENRE.UNKNOWN]: '미분류',
    [CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: 'Simulation',
    [CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: 'Eastern Action/Alternate History',
    [CHALLENGE_STORY_GENRE.BL_GL]: 'LGBTQ+',
    [CHALLENGE_STORY_GENRE.FAN_FICTION]: 'Fanfiction/ Parody',
    [CHALLENGE_STORY_GENRE.ETC]: 'Others',
    // deprecated
    [CHALLENGE_STORY_GENRE.MODERN_FANTASY]: 'Modern/ Modern Fantasy',
};
// 스튜디오에서 깨지는 문제를 해결하기 위해 임시로 넣은 코드.
// 향후에 naming 이 정확히 결정되면, 제거하고 제대로 넣어야 합니다.
exports.CHALLENGE_STORY_GENRE_TO_KO_STRING = exports.STORY_GENRE_TO_KO_STRING;
exports.STORY_GENRE_SUBTITLE_TO_KO_STRING = {
    [CHALLENGE_STORY_GENRE.CONTEST_WINNER]: '제 1회 인터랙티브 스토리 공모전 수상작들을 만나봐요!',
    [CHALLENGE_STORY_GENRE.ROMANCE]: '설렘 가득, 과몰입 유발 로맨스 작품들!',
    [CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: '이세계에서도 로맨스는 계속된다!',
    [CHALLENGE_STORY_GENRE.FANTASY]: '다른 세계로 모험을 떠나봐요!',
    [CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: '숨 막히는 긴장감, 진실을 파헤쳐라!',
    [CHALLENGE_STORY_GENRE.UNKNOWN]: '미분류',
    [CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: '내가 이 세계의 주인공? 내 선택이 모든 걸 결정한다!',
    [CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: '다시 쓰는 역사, 주인공은 바로 나!',
    [CHALLENGE_STORY_GENRE.BL_GL]: '사랑만 있다면 직진! 뜨거운 스토리 속으로',
    [CHALLENGE_STORY_GENRE.FAN_FICTION]: '팬픽/패러디 스토리',
    [CHALLENGE_STORY_GENRE.ETC]: '신선한 장르의 작품들을 만나보세요!',
    // deprecated
    [CHALLENGE_STORY_GENRE.MODERN_FANTASY]: '우리가 사는 세상 속 또 다른 이야기들!',
};
exports.STORY_GENRE_SUBTITLE_TO_EN_STRING = {
    [CHALLENGE_STORY_GENRE.CONTEST_WINNER]: '제 1회 인터랙티브 스토리 공모전 수상작들을 만나봐요!',
    [CHALLENGE_STORY_GENRE.ROMANCE]: 'Enjoy our collection of heart-fluttering romance stories!',
    [CHALLENGE_STORY_GENRE.ROMANCE_FANTASY]: 'Love transcends time and space! ',
    [CHALLENGE_STORY_GENRE.FANTASY]: 'Explore fantasies of the outside universe!',
    [CHALLENGE_STORY_GENRE.MYSTERY_PLAY]: 'Get to the bottom of these nail-biting series! ',
    [CHALLENGE_STORY_GENRE.UNKNOWN]: '미분류',
    [CHALLENGE_STORY_GENRE.SLICE_OF_LIFE]: 'Become the protagonist and Build Your Own Adventure!',
    [CHALLENGE_STORY_GENRE.PERIOD_DRAMA]: 'Dive into the story and rewrite the history!',
    [CHALLENGE_STORY_GENRE.BL_GL]: 'Follow your heart, love is all you need. ',
    [CHALLENGE_STORY_GENRE.FAN_FICTION]: '팬픽/패러디 스토리',
    [CHALLENGE_STORY_GENRE.ETC]: '신선한 장르의 작품들을 만나보세요!',
    // deprecated
    [CHALLENGE_STORY_GENRE.MODERN_FANTASY]: '우리가 사는 세상 속 또 다른 이야기들!',
};
var PUBLIC_RESOURCE_TYPE;
(function (PUBLIC_RESOURCE_TYPE) {
    PUBLIC_RESOURCE_TYPE["FULL_WIDTH_IMAGE"] = "FULL_WIDTH_IMAGE";
    PUBLIC_RESOURCE_TYPE["BACKGROUND_IMAGE"] = "BACKGROUND_IMAGE";
    PUBLIC_RESOURCE_TYPE["CHAR_IMAGE"] = "CHAR_IMAGE";
    PUBLIC_RESOURCE_TYPE["ITEM_IMAGE"] = "ITEM_IMAGE";
    PUBLIC_RESOURCE_TYPE["SFX"] = "SFX";
    PUBLIC_RESOURCE_TYPE["BGM"] = "BGM";
})(PUBLIC_RESOURCE_TYPE = exports.PUBLIC_RESOURCE_TYPE || (exports.PUBLIC_RESOURCE_TYPE = {}));
// Deprecated. Use 'CountryCode' from /server/src/const.ts instead.
var COUNTRY_CODE;
(function (COUNTRY_CODE) {
    COUNTRY_CODE["US"] = "US";
    COUNTRY_CODE["KR"] = "KR";
})(COUNTRY_CODE = exports.COUNTRY_CODE || (exports.COUNTRY_CODE = {}));
exports.FALLBACK_COUNTRY_CODE = COUNTRY_CODE.KR;
var CURRENCY;
(function (CURRENCY) {
    CURRENCY["KRW"] = "KRW";
    CURRENCY["USD"] = "USD";
})(CURRENCY = exports.CURRENCY || (exports.CURRENCY = {}));
exports.FALLBACK_CURRENCY = CURRENCY.KRW;
var STORY_PLAY_TYPE;
(function (STORY_PLAY_TYPE) {
    STORY_PLAY_TYPE["EPUB"] = "EPUB";
    STORY_PLAY_TYPE["Interactive"] = "Interactive";
    STORY_PLAY_TYPE["Storygame"] = "Storygame";
})(STORY_PLAY_TYPE = exports.STORY_PLAY_TYPE || (exports.STORY_PLAY_TYPE = {}));
// 스튜디오에서 작품 연재 상태 enum
var STORY_STATUS_IN_STUDIO;
(function (STORY_STATUS_IN_STUDIO) {
    STORY_STATUS_IN_STUDIO["Default"] = "Default";
    STORY_STATUS_IN_STUDIO["OnHiatus"] = "OnHiatus";
    STORY_STATUS_IN_STUDIO["Finished"] = "Finished";
    STORY_STATUS_IN_STUDIO["WillBeFinished"] = "WillBeFinished";
})(STORY_STATUS_IN_STUDIO = exports.STORY_STATUS_IN_STUDIO || (exports.STORY_STATUS_IN_STUDIO = {}));
var STORY_SORTING;
(function (STORY_SORTING) {
    STORY_SORTING["SHOW_ORDER_DESC"] = "SHOW_ORDER_DESC";
    STORY_SORTING["UPDATED_DESC"] = "UPDATED_DESC";
    STORY_SORTING["UPDATED_ASC"] = "UPDATED_ASC";
    STORY_SORTING["NUM_VIEWS_DESC"] = "NUM_VIEWS_DESC";
    STORY_SORTING["NUM_PLAY_DESC"] = "NUM_PLAY_DESC";
    STORY_SORTING["NUM_LIKES_DESC"] = "NUM_LIKES_DESC";
    STORY_SORTING["RANDOM"] = "RANDOM";
    STORY_SORTING["DAILY_RANK_ASC"] = "DAILY_RANK_ASC";
    STORY_SORTING["PUBLISHED_DESC"] = "PUBLISHED_DESC";
})(STORY_SORTING = exports.STORY_SORTING || (exports.STORY_SORTING = {}));
var IMP_STATE;
(function (IMP_STATE) {
    IMP_STATE["REQUESTED"] = "REQUESTED";
    IMP_STATE["COMPLETED"] = "COMPLETED";
    IMP_STATE["CANCELED"] = "CANCELED";
    IMP_STATE["FORGERY"] = "FORGERY";
    IMP_STATE["FAILED"] = "FAILED";
})(IMP_STATE = exports.IMP_STATE || (exports.IMP_STATE = {}));
var TOSS_PAYMENTS_STATUS;
(function (TOSS_PAYMENTS_STATUS) {
    TOSS_PAYMENTS_STATUS["READY"] = "READY";
    TOSS_PAYMENTS_STATUS["IN_PROGRESS"] = "IN_PROGRESS";
    TOSS_PAYMENTS_STATUS["WAITING_FOR_DEPOSIT"] = "WAITING_FOR_DEPOSIT";
    TOSS_PAYMENTS_STATUS["DONE"] = "DONE";
    TOSS_PAYMENTS_STATUS["PARTIAL_CANCELED"] = "PARTIAL_CANCELED";
    TOSS_PAYMENTS_STATUS["CANCELED"] = "CANCELED";
    TOSS_PAYMENTS_STATUS["ABORTED"] = "ABORTED";
    TOSS_PAYMENTS_STATUS["FAILED"] = "FAILED";
    TOSS_PAYMENTS_STATUS["FORGERY"] = "FORGERY";
    TOSS_PAYMENTS_STATUS["EXPIRED"] = "EXPIRED";
})(TOSS_PAYMENTS_STATUS = exports.TOSS_PAYMENTS_STATUS || (exports.TOSS_PAYMENTS_STATUS = {}));
var TOSS_PAYMENTS_PAY_TYPE;
(function (TOSS_PAYMENTS_PAY_TYPE) {
    TOSS_PAYMENTS_PAY_TYPE["OVERSEAS"] = "OVERSEAS";
    TOSS_PAYMENTS_PAY_TYPE["DOMESTIC"] = "DOMESTIC";
})(TOSS_PAYMENTS_PAY_TYPE = exports.TOSS_PAYMENTS_PAY_TYPE || (exports.TOSS_PAYMENTS_PAY_TYPE = {}));
exports.REMOTE_SCRIPT_TYPE_TO_KO_STRING = {
    [script_1.REMOTE_SCRIPT_TYPE.HelloBot]: '헬로우봇',
    [script_1.REMOTE_SCRIPT_TYPE.StoryPlay]: '스토리플레이',
};
// 앱에서 선택지에서의 선택을 저장하는 키 값의 prefix. (value: '1', '2', ...)
exports.UserPropUserChoicePrefix = `_$_userChoice_`;
// 앱에서 텍스트 입력지에서의 사용자 입력 텍스트를 저장하는 키 값의 prefix
exports.UserPropUserChoiceInputTextPrefix = `_$_userChoiceInputText_`;
// 원격 스크립트의 ChatGPT 타입의 응답을 저장하는 키 값의 prefix
exports.UserPropRemoteScriptChatGPTPrefix = `_$_userRemoteScript_`;
var SEX_DISTINCTION;
(function (SEX_DISTINCTION) {
    SEX_DISTINCTION["Man"] = "Man";
    SEX_DISTINCTION["Woman"] = "Woman";
    SEX_DISTINCTION["Neutrality"] = "Neutrality";
})(SEX_DISTINCTION = exports.SEX_DISTINCTION || (exports.SEX_DISTINCTION = {}));
var AGE_GROUP;
(function (AGE_GROUP) {
    AGE_GROUP[AGE_GROUP["Ten"] = 10] = "Ten";
    AGE_GROUP[AGE_GROUP["Twenty"] = 20] = "Twenty";
    AGE_GROUP[AGE_GROUP["Thirty"] = 30] = "Thirty";
    AGE_GROUP[AGE_GROUP["Forty"] = 40] = "Forty";
    AGE_GROUP[AGE_GROUP["Fifty"] = 50] = "Fifty";
    AGE_GROUP[AGE_GROUP["Sixty"] = 60] = "Sixty";
})(AGE_GROUP = exports.AGE_GROUP || (exports.AGE_GROUP = {}));
// 작품 통계 캐시 타입 (일간, 주간, 월간)
var STORY_STAT_CACHE_TYPE;
(function (STORY_STAT_CACHE_TYPE) {
    STORY_STAT_CACHE_TYPE["Day"] = "Day";
    STORY_STAT_CACHE_TYPE["Week"] = "Week";
    STORY_STAT_CACHE_TYPE["Month"] = "Month";
})(STORY_STAT_CACHE_TYPE = exports.STORY_STAT_CACHE_TYPE || (exports.STORY_STAT_CACHE_TYPE = {}));
// 작품 전환율 통계 종류 (신규 유저, 처음 작품 플레이, 전체)
var STORY_CONVERSION_STAT_TYPE;
(function (STORY_CONVERSION_STAT_TYPE) {
    STORY_CONVERSION_STAT_TYPE["NewUser"] = "NewUser";
    STORY_CONVERSION_STAT_TYPE["NewPlayer"] = "NewPlayer";
    STORY_CONVERSION_STAT_TYPE["All"] = "All";
})(STORY_CONVERSION_STAT_TYPE = exports.STORY_CONVERSION_STAT_TYPE || (exports.STORY_CONVERSION_STAT_TYPE = {}));
var STORY_RANK_TYPE;
(function (STORY_RANK_TYPE) {
    STORY_RANK_TYPE["NumPlay"] = "NumPlay";
    STORY_RANK_TYPE["Conversion"] = "Conversion";
    STORY_RANK_TYPE["NumLike"] = "NumLike";
    STORY_RANK_TYPE["NumComment"] = "NumComment";
})(STORY_RANK_TYPE = exports.STORY_RANK_TYPE || (exports.STORY_RANK_TYPE = {}));
// 회차 이탈구간 캐시 타입 (일간, 주간, 월간, 전체)
var CHAPTER_BOUNCE_STAT_TYPE;
(function (CHAPTER_BOUNCE_STAT_TYPE) {
    CHAPTER_BOUNCE_STAT_TYPE["Day"] = "Day";
    CHAPTER_BOUNCE_STAT_TYPE["Week"] = "Week";
    CHAPTER_BOUNCE_STAT_TYPE["Month"] = "Month";
    CHAPTER_BOUNCE_STAT_TYPE["All"] = "All";
})(CHAPTER_BOUNCE_STAT_TYPE = exports.CHAPTER_BOUNCE_STAT_TYPE || (exports.CHAPTER_BOUNCE_STAT_TYPE = {}));
var STORY_WEEKDAY;
(function (STORY_WEEKDAY) {
    STORY_WEEKDAY["Monday"] = "Monday";
    STORY_WEEKDAY["Tuesday"] = "Tuesday";
    STORY_WEEKDAY["Wednesday"] = "Wednesday";
    STORY_WEEKDAY["Thursday"] = "Thursday";
    STORY_WEEKDAY["Friday"] = "Friday";
    STORY_WEEKDAY["Saturday"] = "Saturday";
    STORY_WEEKDAY["Sunday"] = "Sunday";
})(STORY_WEEKDAY = exports.STORY_WEEKDAY || (exports.STORY_WEEKDAY = {}));
exports.STORY_WEEKDAY_TO_KO_STRING = {
    [server_schema_1.GQLSTORY_WEEKDAY.Monday]: '월',
    [server_schema_1.GQLSTORY_WEEKDAY.Tuesday]: '화',
    [server_schema_1.GQLSTORY_WEEKDAY.Wednesday]: '수',
    [server_schema_1.GQLSTORY_WEEKDAY.Thursday]: '목',
    [server_schema_1.GQLSTORY_WEEKDAY.Friday]: '금',
    [server_schema_1.GQLSTORY_WEEKDAY.Saturday]: '토',
    [server_schema_1.GQLSTORY_WEEKDAY.Sunday]: '일',
};
var DIRECT_ADS_TYPE;
(function (DIRECT_ADS_TYPE) {
    DIRECT_ADS_TYPE["Splash"] = "Splash";
    DIRECT_ADS_TYPE["Home"] = "Home";
    DIRECT_ADS_TYPE["Details"] = "Details";
    DIRECT_ADS_TYPE["History"] = "History";
    DIRECT_ADS_TYPE["More"] = "More";
    DIRECT_ADS_TYPE["ChapterEnding"] = "ChapterEnding";
})(DIRECT_ADS_TYPE = exports.DIRECT_ADS_TYPE || (exports.DIRECT_ADS_TYPE = {}));
// 스플 내부에서 정의한 Danal 결제 process 상태
var DANAL_PAYMENT_STATUS;
(function (DANAL_PAYMENT_STATUS) {
    DANAL_PAYMENT_STATUS["REQUEST"] = "REQUEST";
    DANAL_PAYMENT_STATUS["READY"] = "READY";
    DANAL_PAYMENT_STATUS["COMPLETE"] = "COMPLETE";
    DANAL_PAYMENT_STATUS["ERROR"] = "ERROR";
    DANAL_PAYMENT_STATUS["CANCELED"] = "CANCELED";
})(DANAL_PAYMENT_STATUS = exports.DANAL_PAYMENT_STATUS || (exports.DANAL_PAYMENT_STATUS = {}));
// 다날 결제 서버에서 제공하는 재화 단위
exports.DANAL_PAYMENT_CURRENCY = {
    WON: 'WON',
    DOLLAR: 'DOLLAR',
};
// 다날 결제 서버에서 반환해주는 결제 결과
var DANAL_PAYMENT_RESULT;
(function (DANAL_PAYMENT_RESULT) {
    DANAL_PAYMENT_RESULT["SUCCESS"] = "SUCCESS";
    DANAL_PAYMENT_RESULT["FAIL"] = "FAIL";
    DANAL_PAYMENT_RESULT["CANCEL"] = "CANCEL";
})(DANAL_PAYMENT_RESULT = exports.DANAL_PAYMENT_RESULT || (exports.DANAL_PAYMENT_RESULT = {}));
// 다날 결제 UserAgent
var DANAL_PAYMENT_USER_AGENT;
(function (DANAL_PAYMENT_USER_AGENT) {
    DANAL_PAYMENT_USER_AGENT["WP"] = "WP";
    DANAL_PAYMENT_USER_AGENT["WM"] = "WM";
})(DANAL_PAYMENT_USER_AGENT = exports.DANAL_PAYMENT_USER_AGENT || (exports.DANAL_PAYMENT_USER_AGENT = {}));
// 배너 타입
var BANNER_TYPE;
(function (BANNER_TYPE) {
    BANNER_TYPE["Story"] = "Story";
    BANNER_TYPE["Custom"] = "Custom";
})(BANNER_TYPE = exports.BANNER_TYPE || (exports.BANNER_TYPE = {}));
// 배너 위치
var BANNER_LOCATION;
(function (BANNER_LOCATION) {
    BANNER_LOCATION["Home"] = "Home";
    BANNER_LOCATION["Interactive"] = "Interactive";
    BANNER_LOCATION["EPUB"] = "EPUB";
})(BANNER_LOCATION = exports.BANNER_LOCATION || (exports.BANNER_LOCATION = {}));
// 클라이언트 타입
var CLIENT;
(function (CLIENT) {
    CLIENT["App"] = "App";
    CLIENT["Web"] = "Web";
})(CLIENT = exports.CLIENT || (exports.CLIENT = {}));
// 배너 링크 타입
var BANNER_LINK_TYPE;
(function (BANNER_LINK_TYPE) {
    BANNER_LINK_TYPE["DeepLink"] = "DeepLink";
    BANNER_LINK_TYPE["AppInappBrowserLink"] = "AppInappBrowserLink";
    BANNER_LINK_TYPE["AppExternalBrowserLink"] = "AppExternalBrowserLink";
    BANNER_LINK_TYPE["WebCurrentWindowLink"] = "WebCurrentWindowLink";
    BANNER_LINK_TYPE["WebNewWindowLink"] = "WebNewWindowLink";
})(BANNER_LINK_TYPE = exports.BANNER_LINK_TYPE || (exports.BANNER_LINK_TYPE = {}));
// 배너 노출 상태
var BANNER_STATE;
(function (BANNER_STATE) {
    BANNER_STATE["On"] = "On";
    BANNER_STATE["Off"] = "Off";
    BANNER_STATE["Reserved"] = "Reserved";
    BANNER_STATE["Error"] = "Error";
})(BANNER_STATE = exports.BANNER_STATE || (exports.BANNER_STATE = {}));
var USER_BASED_RECOMMENDATION_CASE;
(function (USER_BASED_RECOMMENDATION_CASE) {
    USER_BASED_RECOMMENDATION_CASE["Home"] = "Home";
    USER_BASED_RECOMMENDATION_CASE["LeaveChatroom"] = "LeaveChatroom";
    USER_BASED_RECOMMENDATION_CASE["FirstUser"] = "FirstUser";
})(USER_BASED_RECOMMENDATION_CASE = exports.USER_BASED_RECOMMENDATION_CASE || (exports.USER_BASED_RECOMMENDATION_CASE = {}));
var ITEM_BASED_RECOMMENDATION_CASE;
(function (ITEM_BASED_RECOMMENDATION_CASE) {
    ITEM_BASED_RECOMMENDATION_CASE["Details"] = "Details";
    ITEM_BASED_RECOMMENDATION_CASE["DetailsMore"] = "DetailsMore";
    ITEM_BASED_RECOMMENDATION_CASE["EndingModal"] = "EndingModal";
})(ITEM_BASED_RECOMMENDATION_CASE = exports.ITEM_BASED_RECOMMENDATION_CASE || (exports.ITEM_BASED_RECOMMENDATION_CASE = {}));
var STORY_BUNDLE_COUNTRY_ACCESS;
(function (STORY_BUNDLE_COUNTRY_ACCESS) {
    STORY_BUNDLE_COUNTRY_ACCESS["allow"] = "allow";
    STORY_BUNDLE_COUNTRY_ACCESS["deny"] = "deny";
})(STORY_BUNDLE_COUNTRY_ACCESS = exports.STORY_BUNDLE_COUNTRY_ACCESS || (exports.STORY_BUNDLE_COUNTRY_ACCESS = {}));
var STORY_HAS_RECOMMENDATION_TYPE;
(function (STORY_HAS_RECOMMENDATION_TYPE) {
    STORY_HAS_RECOMMENDATION_TYPE["AdminSelect"] = "AdminSelect";
})(STORY_HAS_RECOMMENDATION_TYPE = exports.STORY_HAS_RECOMMENDATION_TYPE || (exports.STORY_HAS_RECOMMENDATION_TYPE = {}));
var RECOMMENDATION_ONBOARDING_TYPE;
(function (RECOMMENDATION_ONBOARDING_TYPE) {
    RECOMMENDATION_ONBOARDING_TYPE["Gender"] = "Gender";
    RECOMMENDATION_ONBOARDING_TYPE["Generation"] = "Generation";
    RECOMMENDATION_ONBOARDING_TYPE["Genre"] = "Genre";
})(RECOMMENDATION_ONBOARDING_TYPE = exports.RECOMMENDATION_ONBOARDING_TYPE || (exports.RECOMMENDATION_ONBOARDING_TYPE = {}));
var USER_RECOMMENDATION_ONBOARDING_STATUS;
(function (USER_RECOMMENDATION_ONBOARDING_STATUS) {
    USER_RECOMMENDATION_ONBOARDING_STATUS["Skip"] = "Skip";
    USER_RECOMMENDATION_ONBOARDING_STATUS["Done"] = "Done";
})(USER_RECOMMENDATION_ONBOARDING_STATUS = exports.USER_RECOMMENDATION_ONBOARDING_STATUS || (exports.USER_RECOMMENDATION_ONBOARDING_STATUS = {}));
var GENDER;
(function (GENDER) {
    GENDER["Female"] = "Female";
    GENDER["Male"] = "Male";
    GENDER["Unknown"] = "Unknown";
})(GENDER = exports.GENDER || (exports.GENDER = {}));
exports.SCRIPT_FORM_SOURCE_LINE = '@line';
exports.SCRIPT_FORM_MESSAGE = '$$$';
exports.GQL_STORY_PROP_TYPE_TO_KO_STRING = {
    [server_schema_1.GQLSTORY_PROP_TYPE.STRING]: '텍스트',
    [server_schema_1.GQLSTORY_PROP_TYPE.NUMBER]: '숫자',
};
exports.SCREEN_FILL_METHOD_TO_KO_STRING = {
    [script_1.ScreenFillMethod.Stretch]: '비율 상관없이 전체 채우기',
    [script_1.ScreenFillMethod.AspectRatio]: '비율 유지하기',
};
exports.TRANSITION_STYLE_TO_KO_STRING = {
    [script_1.TransitionStyle.None]: '없음',
    [script_1.TransitionStyle.Fade]: script_1.TransitionStyle.Fade,
    [script_1.TransitionStyle.Zoom]: script_1.TransitionStyle.Zoom,
};
// 회차 타입
var CHAPTER_CONTENT_TYPE;
(function (CHAPTER_CONTENT_TYPE) {
    CHAPTER_CONTENT_TYPE["Interactive"] = "Interactive";
    CHAPTER_CONTENT_TYPE["Epub"] = "Epub";
    CHAPTER_CONTENT_TYPE["StudioNovel"] = "StudioNovel";
})(CHAPTER_CONTENT_TYPE = exports.CHAPTER_CONTENT_TYPE || (exports.CHAPTER_CONTENT_TYPE = {}));
// 스튜디오에서 회차 정렬 조회 옵션
var LIST_CHAPTER_FOR_WRITER_SORT_OPTION;
(function (LIST_CHAPTER_FOR_WRITER_SORT_OPTION) {
    LIST_CHAPTER_FOR_WRITER_SORT_OPTION["CHAPTER_INDEX_DESC"] = "CHAPTER_INDEX_DESC";
    LIST_CHAPTER_FOR_WRITER_SORT_OPTION["CHAPTER_INDEX_ASC"] = "CHAPTER_INDEX_ASC";
})(LIST_CHAPTER_FOR_WRITER_SORT_OPTION = exports.LIST_CHAPTER_FOR_WRITER_SORT_OPTION || (exports.LIST_CHAPTER_FOR_WRITER_SORT_OPTION = {}));
exports.STORY_PLAY_TYPE_TO_KO_STRING = {
    [server_schema_1.GQLSTORY_PLAY_TYPE.EPUB]: '웹소설',
    [server_schema_1.GQLSTORY_PLAY_TYPE.Interactive]: '인터랙티브',
    [server_schema_1.GQLSTORY_PLAY_TYPE.Storygame]: '스토리게임',
};
exports.LANGUAGE_CODE_TO_KO_STRING = {
    [server_schema_1.GQLLANGUAGE_CODE.ko]: '한국',
    [server_schema_1.GQLLANGUAGE_CODE.en]: '글로벌 (영어)',
};
exports.BANNER_TYPE_TO_KO_STRING = {
    [server_schema_1.GQLBANNER_TYPE.Story]: '작품 등록',
    [server_schema_1.GQLBANNER_TYPE.Custom]: '직접 등록',
};
exports.CLIENT_TO_KO_STRING = {
    [server_schema_1.GQLCLIENT.App]: '앱',
    [server_schema_1.GQLCLIENT.Web]: '웹',
};
exports.BANNER_LOCATION_TO_KO_STRING = {
    [server_schema_1.GQLBANNER_LOCATION.Home]: '홈',
    [server_schema_1.GQLBANNER_LOCATION.EPUB]: '웹소설',
    [server_schema_1.GQLBANNER_LOCATION.Interactive]: '인터랙티브',
};
exports.BANNER_LINK_TYPE_TO_KO_STRING = {
    [server_schema_1.GQLBANNER_LINK_TYPE.DeepLink]: '딥링크',
    [server_schema_1.GQLBANNER_LINK_TYPE.AppExternalBrowserLink]: '외부 브라우저',
    [server_schema_1.GQLBANNER_LINK_TYPE.AppInappBrowserLink]: '인앱 브라우저',
    [server_schema_1.GQLBANNER_LINK_TYPE.WebCurrentWindowLink]: '기존 창에 띄우기',
    [server_schema_1.GQLBANNER_LINK_TYPE.WebNewWindowLink]: '새 창에서 띄우기',
};
exports.BANNER_STATE_TO_KO_STRING = {
    [server_schema_1.GQLBANNER_STATE.On]: '켜짐',
    [server_schema_1.GQLBANNER_STATE.Off]: '꺼짐',
    [server_schema_1.GQLBANNER_STATE.Reserved]: '예약중',
    [server_schema_1.GQLBANNER_STATE.Error]: '비활성',
};
exports.COUNTRY_CODE_ETC = '__';
exports.COUNTRY_CODE_KR = 'KR';
var COUNTRY_CODE_BY_STUDIO_WEB;
(function (COUNTRY_CODE_BY_STUDIO_WEB) {
    COUNTRY_CODE_BY_STUDIO_WEB["US"] = "US";
    COUNTRY_CODE_BY_STUDIO_WEB["KR"] = "KR";
    COUNTRY_CODE_BY_STUDIO_WEB["JP"] = "JP";
    COUNTRY_CODE_BY_STUDIO_WEB["CN"] = "CN";
    COUNTRY_CODE_BY_STUDIO_WEB["ES"] = "ES";
    COUNTRY_CODE_BY_STUDIO_WEB["ALL"] = "ALL";
    COUNTRY_CODE_BY_STUDIO_WEB["__"] = "__";
})(COUNTRY_CODE_BY_STUDIO_WEB = exports.COUNTRY_CODE_BY_STUDIO_WEB || (exports.COUNTRY_CODE_BY_STUDIO_WEB = {}));
exports.COUNTRY_CODE_TO_KO_STRING = {
    [COUNTRY_CODE_BY_STUDIO_WEB.KR]: '한국',
    [COUNTRY_CODE_BY_STUDIO_WEB.US]: '미국',
    [COUNTRY_CODE_BY_STUDIO_WEB.JP]: '일본',
    [COUNTRY_CODE_BY_STUDIO_WEB.CN]: '중국',
    [COUNTRY_CODE_BY_STUDIO_WEB.ES]: '스페인',
    [COUNTRY_CODE_BY_STUDIO_WEB.__]: '그외',
    [COUNTRY_CODE_BY_STUDIO_WEB.ALL]: '전체',
};
// 스튜디오 스토리게임 어드민 탭 전용
exports.SECTION_SUPPORTED_COUNTRY_CODE_TO_KO_STRING = {
    [COUNTRY_CODE_BY_STUDIO_WEB.KR]: '한국',
    [COUNTRY_CODE_BY_STUDIO_WEB.__]: '그 외 국가',
};
const SECTION_SUPPORTED_LANGUAGE_CODE_TO_KO_STRING = (code) => {
    if (code.startsWith('ko')) {
        return 'KOR - 한국어';
    }
    if (code.startsWith('en')) {
        return 'ENG - 영어';
    }
    return '';
};
exports.SECTION_SUPPORTED_LANGUAGE_CODE_TO_KO_STRING = SECTION_SUPPORTED_LANGUAGE_CODE_TO_KO_STRING;
exports.ActivatedCountryCacheId = 'ActivatedCountryCacheId';
exports.AGE_LIMIT_NOTIFICATION_URL = 'https://www.notion.so/thingsflow/4702f1aab0904a1997e0bae07bed4b47?pvs=4';
var STORYGAME_IMAGE_PLATFORM_TYPE;
(function (STORYGAME_IMAGE_PLATFORM_TYPE) {
    STORYGAME_IMAGE_PLATFORM_TYPE["Mobile"] = "Mobile";
    STORYGAME_IMAGE_PLATFORM_TYPE["PC"] = "PC";
})(STORYGAME_IMAGE_PLATFORM_TYPE = exports.STORYGAME_IMAGE_PLATFORM_TYPE || (exports.STORYGAME_IMAGE_PLATFORM_TYPE = {}));
var STORYGAME_PREVIEW_RESOURCE_TYPE;
(function (STORYGAME_PREVIEW_RESOURCE_TYPE) {
    STORYGAME_PREVIEW_RESOURCE_TYPE["Image"] = "Image";
    STORYGAME_PREVIEW_RESOURCE_TYPE["Video"] = "Video";
})(STORYGAME_PREVIEW_RESOURCE_TYPE = exports.STORYGAME_PREVIEW_RESOURCE_TYPE || (exports.STORYGAME_PREVIEW_RESOURCE_TYPE = {}));
var StorygameTabType;
(function (StorygameTabType) {
    StorygameTabType["Recommended"] = "Recommended";
    StorygameTabType["Genre"] = "Genre";
    StorygameTabType["NSFW"] = "NSFW";
})(StorygameTabType = exports.StorygameTabType || (exports.StorygameTabType = {}));
var StorygameSectionUIType;
(function (StorygameSectionUIType) {
    StorygameSectionUIType["Slider"] = "Slider";
    StorygameSectionUIType["CarouselSmall"] = "CarouselSmall";
    StorygameSectionUIType["CarouselMedium"] = "CarouselMedium";
    StorygameSectionUIType["CarouselLarge"] = "CarouselLarge";
    StorygameSectionUIType["CarouselProgress"] = "CarouselProgress";
    StorygameSectionUIType["CarouselRank"] = "CarouselRank";
    StorygameSectionUIType["RankList"] = "RankList";
})(StorygameSectionUIType = exports.StorygameSectionUIType || (exports.StorygameSectionUIType = {}));
exports.StorygameSectionUITypeToString = {
    [StorygameSectionUIType.Slider]: '슬라이더형',
    [StorygameSectionUIType.CarouselSmall]: '캐러셀 기본형 S',
    [StorygameSectionUIType.CarouselMedium]: '캐러셀 기본형 M',
    [StorygameSectionUIType.CarouselLarge]: '캐러셀 기본형 L',
    [StorygameSectionUIType.CarouselProgress]: '캐러셀 진행형',
    [StorygameSectionUIType.RankList]: '리스트 순위형',
    [StorygameSectionUIType.CarouselRank]: '캐러셀 순위형',
};
var StorygameSectionAlgorithm;
(function (StorygameSectionAlgorithm) {
    StorygameSectionAlgorithm["Manual"] = "Manual";
    StorygameSectionAlgorithm["RecentPlay"] = "RecentPlay";
    StorygameSectionAlgorithm["HighAPPU"] = "HighAPPU";
    StorygameSectionAlgorithm["HighViews"] = "HighViews";
    StorygameSectionAlgorithm["FreeHighAPPU"] = "FreeHighAPPU";
    StorygameSectionAlgorithm["RecentReleaseViews"] = "RecentReleaseViews";
    StorygameSectionAlgorithm["HighSales"] = "HighSales";
    StorygameSectionAlgorithm["ManyFavorites"] = "ManyFavorites";
    // RecentDetailViews = 'RecentDetailViews', // 최근 상세 진입한 순 - 현재 유저별 상세 페이지 진입 히스토리를 저장하지 않아 사용하지 않음
})(StorygameSectionAlgorithm = exports.StorygameSectionAlgorithm || (exports.StorygameSectionAlgorithm = {}));
exports.StorygameSectionAlgorithmToString = {
    [StorygameSectionAlgorithm.Manual]: '수동 설정',
    [StorygameSectionAlgorithm.RecentPlay]: '최근 플레이순',
    [StorygameSectionAlgorithm.HighAPPU]: 'APPU 높은순',
    [StorygameSectionAlgorithm.HighViews]: '조회수 (플레이수) 높은순',
    [StorygameSectionAlgorithm.FreeHighAPPU]: '무료작품 APPU 높은순',
    [StorygameSectionAlgorithm.RecentReleaseViews]: '최근출시작 조회수 높은순',
    [StorygameSectionAlgorithm.HighSales]: '판매량 높은순',
    [StorygameSectionAlgorithm.ManyFavorites]: '찜 많은순',
    // [StorygameSectionAlgorithm.RecentDetailViews]: '최근 상세 진입한 순',
};
var StorygameSectionPeriod;
(function (StorygameSectionPeriod) {
    StorygameSectionPeriod["All"] = "All";
    StorygameSectionPeriod["Day"] = "Day";
    StorygameSectionPeriod["Day3"] = "Day3";
    StorygameSectionPeriod["Week"] = "Week";
    StorygameSectionPeriod["Week2"] = "Week2";
    StorygameSectionPeriod["Month"] = "Month";
    StorygameSectionPeriod["Month3"] = "Month3";
    StorygameSectionPeriod["Month6"] = "Month6";
    StorygameSectionPeriod["Year"] = "Year";
})(StorygameSectionPeriod = exports.StorygameSectionPeriod || (exports.StorygameSectionPeriod = {}));
exports.StorygameSectionPeriodToString = {
    [StorygameSectionPeriod.All]: '전체',
    [StorygameSectionPeriod.Day]: '1일',
    [StorygameSectionPeriod.Day3]: '3일',
    [StorygameSectionPeriod.Week]: '7일',
    [StorygameSectionPeriod.Week2]: '14일',
    [StorygameSectionPeriod.Month]: '30일',
    [StorygameSectionPeriod.Month3]: '90일',
    [StorygameSectionPeriod.Month6]: '180일',
    [StorygameSectionPeriod.Year]: '365일',
};
var StorygameSectionType;
(function (StorygameSectionType) {
    StorygameSectionType["Custom"] = "Custom";
    StorygameSectionType["SliderBanner"] = "SliderBanner";
    StorygameSectionType["GameBasedRecommendation"] = "GameBasedRecommendation";
    StorygameSectionType["TagBasedRecommendation"] = "TagBasedRecommendation";
})(StorygameSectionType = exports.StorygameSectionType || (exports.StorygameSectionType = {}));
var StorePageTabType;
(function (StorePageTabType) {
    StorePageTabType["Recommended"] = "Recommended";
    StorePageTabType["Genre"] = "Genre";
    StorePageTabType["NSFW"] = "NSFW";
})(StorePageTabType = exports.StorePageTabType || (exports.StorePageTabType = {}));
var StorePageSectionUIType;
(function (StorePageSectionUIType) {
    StorePageSectionUIType["Slider"] = "Slider";
    StorePageSectionUIType["CarouselSmall"] = "CarouselSmall";
    StorePageSectionUIType["CarouselMedium"] = "CarouselMedium";
    StorePageSectionUIType["CarouselLarge"] = "CarouselLarge";
    StorePageSectionUIType["CarouselProgress"] = "CarouselProgress";
    StorePageSectionUIType["CarouselRank"] = "CarouselRank";
    StorePageSectionUIType["CarouselBundle"] = "CarouselBundle";
    StorePageSectionUIType["RankList"] = "RankList";
})(StorePageSectionUIType = exports.StorePageSectionUIType || (exports.StorePageSectionUIType = {}));
var StorePageSectionAlgorithm;
(function (StorePageSectionAlgorithm) {
    StorePageSectionAlgorithm["Manual"] = "Manual";
    StorePageSectionAlgorithm["HighAppu"] = "HighAppu";
    StorePageSectionAlgorithm["HighArpu"] = "HighArpu";
    StorePageSectionAlgorithm["MostViewed"] = "MostViewed";
    StorePageSectionAlgorithm["MostSold"] = "MostSold";
    StorePageSectionAlgorithm["MostPreordered"] = "MostPreordered";
    StorePageSectionAlgorithm["MostWishlisted"] = "MostWishlisted";
    StorePageSectionAlgorithm["UpcomingRelease"] = "UpcomingRelease";
    StorePageSectionAlgorithm["RecentlyPlayed"] = "RecentlyPlayed";
    StorePageSectionAlgorithm["MostPositiveReviews"] = "MostPositiveReviews";
    StorePageSectionAlgorithm["HighestPositiveRatio"] = "HighestPositiveRatio";
})(StorePageSectionAlgorithm = exports.StorePageSectionAlgorithm || (exports.StorePageSectionAlgorithm = {}));
var StorePageSectionPeriod;
(function (StorePageSectionPeriod) {
    StorePageSectionPeriod["All"] = "All";
    StorePageSectionPeriod["Week"] = "Week";
    StorePageSectionPeriod["Month"] = "Month";
    StorePageSectionPeriod["Month2"] = "Month2";
    StorePageSectionPeriod["Month3"] = "Month3";
    StorePageSectionPeriod["Year"] = "Year";
})(StorePageSectionPeriod = exports.StorePageSectionPeriod || (exports.StorePageSectionPeriod = {}));
var StoreCartItemType;
(function (StoreCartItemType) {
    StoreCartItemType["StoryBundle"] = "StoryBundle";
    StoreCartItemType["StoreBundle"] = "StoreBundle";
})(StoreCartItemType = exports.StoreCartItemType || (exports.StoreCartItemType = {}));
var StoreReviewRating;
(function (StoreReviewRating) {
    StoreReviewRating["OverwhelminglyPositive"] = "OverwhelminglyPositive";
    StoreReviewRating["VeryPositive"] = "VeryPositive";
    StoreReviewRating["Positive"] = "Positive";
    StoreReviewRating["MostlyPositive"] = "MostlyPositive";
    StoreReviewRating["Mixed"] = "Mixed";
    StoreReviewRating["MostlyNegative"] = "MostlyNegative";
    StoreReviewRating["Negative"] = "Negative";
    StoreReviewRating["VeryNegative"] = "VeryNegative";
    StoreReviewRating["OverwhelminglyNegative"] = "OverwhelminglyNegative";
})(StoreReviewRating = exports.StoreReviewRating || (exports.StoreReviewRating = {}));
var StoreSearchConfigStatus;
(function (StoreSearchConfigStatus) {
    StoreSearchConfigStatus["Editing"] = "Editing";
    StoreSearchConfigStatus["Discarded"] = "Discarded";
    StoreSearchConfigStatus["Active"] = "Active";
})(StoreSearchConfigStatus = exports.StoreSearchConfigStatus || (exports.StoreSearchConfigStatus = {}));
var StorePaymentStatus;
(function (StorePaymentStatus) {
    StorePaymentStatus["READY"] = "READY";
    StorePaymentStatus["IN_PROGRESS"] = "IN_PROGRESS";
    StorePaymentStatus["WAITING_FOR_DEPOSIT"] = "WAITING_FOR_DEPOSIT";
    StorePaymentStatus["DONE"] = "DONE";
    StorePaymentStatus["PARTIAL_CANCELED"] = "PARTIAL_CANCELED";
    StorePaymentStatus["CANCELED"] = "CANCELED";
    StorePaymentStatus["ABORTED"] = "ABORTED";
    StorePaymentStatus["FAILED"] = "FAILED";
    StorePaymentStatus["FORGERY"] = "FORGERY";
    StorePaymentStatus["EXPIRED"] = "EXPIRED";
})(StorePaymentStatus = exports.StorePaymentStatus || (exports.StorePaymentStatus = {}));
var StorePaymentPlatform;
(function (StorePaymentPlatform) {
    StorePaymentPlatform["Toss"] = "Toss";
    StorePaymentPlatform["Xsolla"] = "Xsolla";
})(StorePaymentPlatform = exports.StorePaymentPlatform || (exports.StorePaymentPlatform = {}));
