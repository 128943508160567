import { StudioLoginState, StudioUrls } from '@storyplay/common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { useStudioStore } from '../containers/studio/di/configureRootStore'
import { SPScreenSizeLoading } from './loading/SPScreenSizeLoading'

type RequireLoginProps = {
  doNotCheckIsRequiredAuthor?: boolean
}

export const RequireLogin: React.FC<RequireLoginProps> = observer((props) => {
  const store = useStudioStore()
  const me = store.loginStore.user
  const loginState = store.loginStore.loginState

  // 로그인 시 권한이 작가 권한 보다 낮다면 추가 작가 정보 입력 페이지를 표시한다.
  if (!props.doNotCheckIsRequiredAuthor && !!me?.isNotAuthor) {
    return <Redirect to={StudioUrls.EditAuthorInfo} />
  }

  if (!!me?.isBanned) {
    return <Redirect to={StudioUrls.BannedAccount} />
  }

  if (loginState === StudioLoginState.LoggedOut) {
    return <Redirect to={StudioUrls.Login} />
  }

  if (loginState === StudioLoginState.LoggedIn) {
    return <div>{props.children}</div>
  }

  return <SPScreenSizeLoading />
})
