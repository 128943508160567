import classNames from 'classnames'
import React from 'react'

interface IVSpaceProps {
  h: number | string
  mobileHidden?: boolean
  smHidden?: boolean
}

export const VSpace: React.FC<IVSpaceProps> = (props) => {
  const { h, mobileHidden, smHidden } = props

  return (
    <div
      style={{ height: h }}
      className={classNames('flex-shrink-0', {
        'hidden sm:block': mobileHidden,
        'sm:hidden': smHidden,
      })}
    />
  )
}
