"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GQLITEM_BASED_RECOMMENDATION_CASE = exports.GQLUSER_BASED_RECOMMENDATION_CASE = exports.GQLSTORY_HAS_RECOMMENDATION_TYPE = exports.GQLPUSH_CATEGORY = exports.GQLPUBLIC_RESOURCE_TYPE = exports.GQLTIME_SALE_TYPE = exports.GQLSTORY_SORTING = exports.GQLHOME_SECTION_TYPE = exports.GQLStorages = exports.GQLDIRECT_ADS_TYPE = exports.GQLDANAL_PAYMENT_STATUS = exports.GQLORDER_BY_DIRECTION = exports.GQLCHAPTER_COMMENT_ORDER_FIELD = exports.GQLSTORY_STAT_CACHE_TYPE = exports.GQLLIST_CHAPTER_FOR_WRITER_SORT_OPTION = exports.GQLLANGUAGE_CODE = exports.GQLBANNER_STATE = exports.GQLBANNER_LINK_TYPE = exports.GQLCLIENT = exports.GQLBANNER_LOCATION = exports.GQLBANNER_TYPE = exports.GQLAPP_EVENT_TYPE = exports.GQLADMIN_ACTION_TYPE = exports.GQLCHAPTER_CURRENT_PLAYING_STATUS = exports.GQLCHAPTER_CONTENT_TYPE = exports.GQLCHAPTER_DRAFT_STATUS = exports.GQLOS = exports.GQLStorygameRankingType = exports.GQLSTORYGAME_PREVIEW_RESOURCE_TYPE = exports.GQLStoreReviewRating = exports.GQLSTORY_WEEKDAY = exports.GQLCHALLENGE_STORY_GENRE = exports.GQLSTORY_TYPE = exports.GQLSTORY_ENDING_ARRIVAL_RATE_TYPE = exports.GQLSTUDIO_FILE_TYPE = exports.GQLSTORY_SECTION_TYPE = exports.GQLENDING_CARD_TYPE = exports.GQLENDING_RATE_RANGE_LEVEL = exports.GQLSTORY_PROP_TYPE = exports.GQLCHAPTER_COMMENT_TYPE = exports.GQLCHOICE_TYPE = exports.GQLSTATUS = exports.GQLONBOARDING_POPUP_TYPE = exports.GQLSTORYGAME_IMAGE_PLATFORM_TYPE = exports.GQLAIRequestStatus = exports.GQLTermsType = exports.GQLSTORY_PLAY_TYPE = exports.GQLSSO_PROVIDER = exports.GQLFILE_TYPE = exports.GQLGENDER = void 0;
exports.GQLSTORY_BUNDLE_COUNTRY_ACCESS = exports.GQLDORMANT_USER_LOG_TYPE = exports.GQLTTS_DPE_ID = exports.GQLTTS_EMOTION = exports.GQLTOSS_PAYMENTS_STATUS = exports.GQLTOSS_PAYMENTS_PAY_TYPE = exports.GQLSTUDIO_STORY_PRICE_SETTING_TYPE = exports.GQLONBOARDING_POPUP_INEQUALITY = exports.GQLCONTACT_MAIL_TYPE = exports.GQLIMP_STATE = exports.GQLDANAL_PAYMENT_USER_AGENT = exports.GQLStorePaymentStatus = exports.GQLStorePaymentPlatform = exports.GQLStoreSearchConfigStatus = exports.GQLStoreCartItemType = exports.GQLStorePageSectionPeriod = exports.GQLStorePageSectionAlgorithm = exports.GQLStorePageSectionUIType = exports.GQLStorePageTabType = exports.GQLUSER_RECOMMENDATION_ONBOARDING_STATUS = exports.GQLTTS_SPEAKER_STATUS = exports.GQLSEX_DISTINCTION = exports.GQLStorygameSectionType = exports.GQLStorygameSectionPeriod = exports.GQLStorygameSectionAlgorithm = exports.GQLStorygameSectionUIType = exports.GQLStorygameTabType = exports.GQLSTORY_REACTION_TYPE = exports.GQLSTORY_RANK_TYPE = exports.GQLSTORY_CONVERSION_STAT_TYPE = exports.GQLCHALLENGE_STORY_SORTING = exports.GQLLIST_STORY_FOR_WRITER_SORT_OPTION = exports.GQLUI_STORY_SECTION_TYPE = exports.GQLREMOTE_SCRIPT_TYPE = exports.GQLRECOMMENDATION_ONBOARDING_TYPE = void 0;
/**
 * 사용자 성별
 */
var GQLGENDER;
(function (GQLGENDER) {
    GQLGENDER["Female"] = "Female";
    GQLGENDER["Male"] = "Male";
    GQLGENDER["Unknown"] = "Unknown";
})(GQLGENDER = exports.GQLGENDER || (exports.GQLGENDER = {}));
/**
 * 이 파일의 용도
 */
var GQLFILE_TYPE;
(function (GQLFILE_TYPE) {
    GQLFILE_TYPE["Unspecified"] = "Unspecified";
    GQLFILE_TYPE["Image"] = "Image";
    GQLFILE_TYPE["Video"] = "Video";
})(GQLFILE_TYPE = exports.GQLFILE_TYPE || (exports.GQLFILE_TYPE = {}));
/**
 * SSO Provider
 */
var GQLSSO_PROVIDER;
(function (GQLSSO_PROVIDER) {
    GQLSSO_PROVIDER["GOOGLE"] = "GOOGLE";
    GQLSSO_PROVIDER["APPLE"] = "APPLE";
    GQLSSO_PROVIDER["FACEBOOK"] = "FACEBOOK";
    GQLSSO_PROVIDER["KAKAO"] = "KAKAO";
})(GQLSSO_PROVIDER = exports.GQLSSO_PROVIDER || (exports.GQLSSO_PROVIDER = {}));
/**
 * 작품 플레이 타입 앱용
 */
var GQLSTORY_PLAY_TYPE;
(function (GQLSTORY_PLAY_TYPE) {
    GQLSTORY_PLAY_TYPE["EPUB"] = "EPUB";
    GQLSTORY_PLAY_TYPE["Interactive"] = "Interactive";
    GQLSTORY_PLAY_TYPE["Storygame"] = "Storygame";
})(GQLSTORY_PLAY_TYPE = exports.GQLSTORY_PLAY_TYPE || (exports.GQLSTORY_PLAY_TYPE = {}));
/**
 * 스플 약관 타입
 */
var GQLTermsType;
(function (GQLTermsType) {
    GQLTermsType["TOS"] = "TOS";
    GQLTermsType["PP"] = "PP";
    GQLTermsType["CollectionAndUseOfPrivacy"] = "CollectionAndUseOfPrivacy";
})(GQLTermsType = exports.GQLTermsType || (exports.GQLTermsType = {}));
/**
 * AI 요청 상태
 */
var GQLAIRequestStatus;
(function (GQLAIRequestStatus) {
    GQLAIRequestStatus["Error"] = "Error";
    GQLAIRequestStatus["Progress"] = "Progress";
    GQLAIRequestStatus["Completed"] = "Completed";
    GQLAIRequestStatus["Failed"] = "Failed";
})(GQLAIRequestStatus = exports.GQLAIRequestStatus || (exports.GQLAIRequestStatus = {}));
/**
 * 스토리게임에 필요한 추가 이미지 타입 정의
 */
var GQLSTORYGAME_IMAGE_PLATFORM_TYPE;
(function (GQLSTORYGAME_IMAGE_PLATFORM_TYPE) {
    GQLSTORYGAME_IMAGE_PLATFORM_TYPE["Mobile"] = "Mobile";
    GQLSTORYGAME_IMAGE_PLATFORM_TYPE["PC"] = "PC";
})(GQLSTORYGAME_IMAGE_PLATFORM_TYPE = exports.GQLSTORYGAME_IMAGE_PLATFORM_TYPE || (exports.GQLSTORYGAME_IMAGE_PLATFORM_TYPE = {}));
/**
 * 온보딩 팝업 이벤트 타입
 */
var GQLONBOARDING_POPUP_TYPE;
(function (GQLONBOARDING_POPUP_TYPE) {
    GQLONBOARDING_POPUP_TYPE["Text"] = "Text";
    GQLONBOARDING_POPUP_TYPE["Choice"] = "Choice";
    GQLONBOARDING_POPUP_TYPE["Profile"] = "Profile";
    GQLONBOARDING_POPUP_TYPE["Name"] = "Name";
    GQLONBOARDING_POPUP_TYPE["OtherName"] = "OtherName";
    GQLONBOARDING_POPUP_TYPE["OtherProfile"] = "OtherProfile";
    GQLONBOARDING_POPUP_TYPE["StorygameProfile"] = "StorygameProfile";
})(GQLONBOARDING_POPUP_TYPE = exports.GQLONBOARDING_POPUP_TYPE || (exports.GQLONBOARDING_POPUP_TYPE = {}));
/**
 * 컬럼 상태값
 */
var GQLSTATUS;
(function (GQLSTATUS) {
    GQLSTATUS["Active"] = "Active";
    GQLSTATUS["Inactive"] = "Inactive";
    GQLSTATUS["Deleted"] = "Deleted";
    GQLSTATUS["Disabled"] = "Disabled";
})(GQLSTATUS = exports.GQLSTATUS || (exports.GQLSTATUS = {}));
/**
 * 선택지 타입
 */
var GQLCHOICE_TYPE;
(function (GQLCHOICE_TYPE) {
    GQLCHOICE_TYPE["Default"] = "Default";
    GQLCHOICE_TYPE["UserInputChoice"] = "UserInputChoice";
    GQLCHOICE_TYPE["ImageChoice"] = "ImageChoice";
})(GQLCHOICE_TYPE = exports.GQLCHOICE_TYPE || (exports.GQLCHOICE_TYPE = {}));
/**
 * 챕터 댓글 타입
 */
var GQLCHAPTER_COMMENT_TYPE;
(function (GQLCHAPTER_COMMENT_TYPE) {
    GQLCHAPTER_COMMENT_TYPE["Text"] = "Text";
    GQLCHAPTER_COMMENT_TYPE["UserInputChoice"] = "UserInputChoice";
    GQLCHAPTER_COMMENT_TYPE["Statistics"] = "Statistics";
})(GQLCHAPTER_COMMENT_TYPE = exports.GQLCHAPTER_COMMENT_TYPE || (exports.GQLCHAPTER_COMMENT_TYPE = {}));
/**
 * 스토리 속성의 데이터 타입
 */
var GQLSTORY_PROP_TYPE;
(function (GQLSTORY_PROP_TYPE) {
    GQLSTORY_PROP_TYPE["NUMBER"] = "NUMBER";
    GQLSTORY_PROP_TYPE["STRING"] = "STRING";
})(GQLSTORY_PROP_TYPE = exports.GQLSTORY_PROP_TYPE || (exports.GQLSTORY_PROP_TYPE = {}));
/**
 * 범위 level
 */
var GQLENDING_RATE_RANGE_LEVEL;
(function (GQLENDING_RATE_RANGE_LEVEL) {
    GQLENDING_RATE_RANGE_LEVEL["Unknown"] = "Unknown";
    GQLENDING_RATE_RANGE_LEVEL["Normal"] = "Normal";
    GQLENDING_RATE_RANGE_LEVEL["Rarity"] = "Rarity";
    GQLENDING_RATE_RANGE_LEVEL["VeryRarity"] = "VeryRarity";
    GQLENDING_RATE_RANGE_LEVEL["Minority"] = "Minority";
})(GQLENDING_RATE_RANGE_LEVEL = exports.GQLENDING_RATE_RANGE_LEVEL || (exports.GQLENDING_RATE_RANGE_LEVEL = {}));
/**
 * 엔딩 카드 타입
 */
var GQLENDING_CARD_TYPE;
(function (GQLENDING_CARD_TYPE) {
    GQLENDING_CARD_TYPE["ArrivalRate"] = "ArrivalRate";
    GQLENDING_CARD_TYPE["ChoiceCount"] = "ChoiceCount";
    GQLENDING_CARD_TYPE["EndingCollectRate"] = "EndingCollectRate";
})(GQLENDING_CARD_TYPE = exports.GQLENDING_CARD_TYPE || (exports.GQLENDING_CARD_TYPE = {}));
/**
 * 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌)
 */
var GQLSTORY_SECTION_TYPE;
(function (GQLSTORY_SECTION_TYPE) {
    GQLSTORY_SECTION_TYPE["OnStage"] = "OnStage";
    GQLSTORY_SECTION_TYPE["Monthly"] = "Monthly";
    GQLSTORY_SECTION_TYPE["Dropped"] = "Dropped";
    GQLSTORY_SECTION_TYPE["Global"] = "Global";
    GQLSTORY_SECTION_TYPE["Adult"] = "Adult";
    GQLSTORY_SECTION_TYPE["UGC"] = "UGC";
    GQLSTORY_SECTION_TYPE["Challenge"] = "Challenge";
})(GQLSTORY_SECTION_TYPE = exports.GQLSTORY_SECTION_TYPE || (exports.GQLSTORY_SECTION_TYPE = {}));
/**
 * 스튜디오를 통해서 업로드 가능한 파일의 타입
 */
var GQLSTUDIO_FILE_TYPE;
(function (GQLSTUDIO_FILE_TYPE) {
    GQLSTUDIO_FILE_TYPE["Image"] = "Image";
    GQLSTUDIO_FILE_TYPE["SFX"] = "SFX";
})(GQLSTUDIO_FILE_TYPE = exports.GQLSTUDIO_FILE_TYPE || (exports.GQLSTUDIO_FILE_TYPE = {}));
/**
 * 스토리 엔딩 도착 확률 표시 타입 설계값, 실제값, 미표시
 */
var GQLSTORY_ENDING_ARRIVAL_RATE_TYPE;
(function (GQLSTORY_ENDING_ARRIVAL_RATE_TYPE) {
    GQLSTORY_ENDING_ARRIVAL_RATE_TYPE["Unknown"] = "Unknown";
    GQLSTORY_ENDING_ARRIVAL_RATE_TYPE["RealValue"] = "RealValue";
    GQLSTORY_ENDING_ARRIVAL_RATE_TYPE["SettingValue"] = "SettingValue";
    GQLSTORY_ENDING_ARRIVAL_RATE_TYPE["InVisible"] = "InVisible";
})(GQLSTORY_ENDING_ARRIVAL_RATE_TYPE = exports.GQLSTORY_ENDING_ARRIVAL_RATE_TYPE || (exports.GQLSTORY_ENDING_ARRIVAL_RATE_TYPE = {}));
/**
 * 작품 타입
 */
var GQLSTORY_TYPE;
(function (GQLSTORY_TYPE) {
    GQLSTORY_TYPE["Novel"] = "Novel";
    GQLSTORY_TYPE["Chat"] = "Chat";
})(GQLSTORY_TYPE = exports.GQLSTORY_TYPE || (exports.GQLSTORY_TYPE = {}));
/**
 * 스토리 장르
 */
var GQLCHALLENGE_STORY_GENRE;
(function (GQLCHALLENGE_STORY_GENRE) {
    GQLCHALLENGE_STORY_GENRE["CONTEST_WINNER"] = "CONTEST_WINNER";
    GQLCHALLENGE_STORY_GENRE["ROMANCE"] = "ROMANCE";
    GQLCHALLENGE_STORY_GENRE["ROMANCE_FANTASY"] = "ROMANCE_FANTASY";
    GQLCHALLENGE_STORY_GENRE["FANTASY"] = "FANTASY";
    GQLCHALLENGE_STORY_GENRE["MYSTERY_PLAY"] = "MYSTERY_PLAY";
    GQLCHALLENGE_STORY_GENRE["UNKNOWN"] = "UNKNOWN";
    GQLCHALLENGE_STORY_GENRE["SLICE_OF_LIFE"] = "SLICE_OF_LIFE";
    GQLCHALLENGE_STORY_GENRE["PERIOD_DRAMA"] = "PERIOD_DRAMA";
    GQLCHALLENGE_STORY_GENRE["BL_GL"] = "BL_GL";
    GQLCHALLENGE_STORY_GENRE["FAN_FICTION"] = "FAN_FICTION";
    GQLCHALLENGE_STORY_GENRE["ETC"] = "ETC";
    GQLCHALLENGE_STORY_GENRE["MODERN_FANTASY"] = "MODERN_FANTASY";
})(GQLCHALLENGE_STORY_GENRE = exports.GQLCHALLENGE_STORY_GENRE || (exports.GQLCHALLENGE_STORY_GENRE = {}));
/**
 * 정식작품 연재요일
 */
var GQLSTORY_WEEKDAY;
(function (GQLSTORY_WEEKDAY) {
    GQLSTORY_WEEKDAY["Monday"] = "Monday";
    GQLSTORY_WEEKDAY["Tuesday"] = "Tuesday";
    GQLSTORY_WEEKDAY["Wednesday"] = "Wednesday";
    GQLSTORY_WEEKDAY["Thursday"] = "Thursday";
    GQLSTORY_WEEKDAY["Friday"] = "Friday";
    GQLSTORY_WEEKDAY["Saturday"] = "Saturday";
    GQLSTORY_WEEKDAY["Sunday"] = "Sunday";
})(GQLSTORY_WEEKDAY = exports.GQLSTORY_WEEKDAY || (exports.GQLSTORY_WEEKDAY = {}));
/**
 * 스토어 리뷰 등급
 */
var GQLStoreReviewRating;
(function (GQLStoreReviewRating) {
    GQLStoreReviewRating["OverwhelminglyPositive"] = "OverwhelminglyPositive";
    GQLStoreReviewRating["VeryPositive"] = "VeryPositive";
    GQLStoreReviewRating["Positive"] = "Positive";
    GQLStoreReviewRating["MostlyPositive"] = "MostlyPositive";
    GQLStoreReviewRating["Mixed"] = "Mixed";
    GQLStoreReviewRating["MostlyNegative"] = "MostlyNegative";
    GQLStoreReviewRating["Negative"] = "Negative";
    GQLStoreReviewRating["VeryNegative"] = "VeryNegative";
    GQLStoreReviewRating["OverwhelminglyNegative"] = "OverwhelminglyNegative";
})(GQLStoreReviewRating = exports.GQLStoreReviewRating || (exports.GQLStoreReviewRating = {}));
/**
 * 스토리게임의 상세 페이지에서 보이는 리소스 타입 (image or video)
 */
var GQLSTORYGAME_PREVIEW_RESOURCE_TYPE;
(function (GQLSTORYGAME_PREVIEW_RESOURCE_TYPE) {
    GQLSTORYGAME_PREVIEW_RESOURCE_TYPE["Image"] = "Image";
    GQLSTORYGAME_PREVIEW_RESOURCE_TYPE["Video"] = "Video";
})(GQLSTORYGAME_PREVIEW_RESOURCE_TYPE = exports.GQLSTORYGAME_PREVIEW_RESOURCE_TYPE || (exports.GQLSTORYGAME_PREVIEW_RESOURCE_TYPE = {}));
/**
 * 스토리게임 랭킹 타입
 */
var GQLStorygameRankingType;
(function (GQLStorygameRankingType) {
    GQLStorygameRankingType["TopSeller"] = "TopSeller";
    GQLStorygameRankingType["MostPlayed"] = "MostPlayed";
})(GQLStorygameRankingType = exports.GQLStorygameRankingType || (exports.GQLStorygameRankingType = {}));
/**
 * OS name
 */
var GQLOS;
(function (GQLOS) {
    GQLOS["ANDROID"] = "ANDROID";
    GQLOS["IOS"] = "IOS";
    GQLOS["WEB"] = "WEB";
})(GQLOS = exports.GQLOS || (exports.GQLOS = {}));
/**
 * 드래프트의 현재 상태 (CHAPTER_DRAFT_STATUS)
 */
var GQLCHAPTER_DRAFT_STATUS;
(function (GQLCHAPTER_DRAFT_STATUS) {
    GQLCHAPTER_DRAFT_STATUS["Editing"] = "Editing";
    GQLCHAPTER_DRAFT_STATUS["Applied"] = "Applied";
    GQLCHAPTER_DRAFT_STATUS["Discarded"] = "Discarded";
})(GQLCHAPTER_DRAFT_STATUS = exports.GQLCHAPTER_DRAFT_STATUS || (exports.GQLCHAPTER_DRAFT_STATUS = {}));
/**
 * 회차의 컨텐츠 타입
 */
var GQLCHAPTER_CONTENT_TYPE;
(function (GQLCHAPTER_CONTENT_TYPE) {
    GQLCHAPTER_CONTENT_TYPE["Interactive"] = "Interactive";
    GQLCHAPTER_CONTENT_TYPE["Epub"] = "Epub";
    GQLCHAPTER_CONTENT_TYPE["StudioNovel"] = "StudioNovel";
})(GQLCHAPTER_CONTENT_TYPE = exports.GQLCHAPTER_CONTENT_TYPE || (exports.GQLCHAPTER_CONTENT_TYPE = {}));
/**
 * 챕터 읽음 상태
 */
var GQLCHAPTER_CURRENT_PLAYING_STATUS;
(function (GQLCHAPTER_CURRENT_PLAYING_STATUS) {
    GQLCHAPTER_CURRENT_PLAYING_STATUS["NOT_PLAYED"] = "NOT_PLAYED";
    GQLCHAPTER_CURRENT_PLAYING_STATUS["PLAYING"] = "PLAYING";
    GQLCHAPTER_CURRENT_PLAYING_STATUS["FINISHED"] = "FINISHED";
})(GQLCHAPTER_CURRENT_PLAYING_STATUS = exports.GQLCHAPTER_CURRENT_PLAYING_STATUS || (exports.GQLCHAPTER_CURRENT_PLAYING_STATUS = {}));
/**
 * 어드민이 수행하는 액션 중 기록할 것들
 */
var GQLADMIN_ACTION_TYPE;
(function (GQLADMIN_ACTION_TYPE) {
    GQLADMIN_ACTION_TYPE["ViewUser"] = "ViewUser";
    GQLADMIN_ACTION_TYPE["DeactivateUser"] = "DeactivateUser";
    GQLADMIN_ACTION_TYPE["CreateUser"] = "CreateUser";
    GQLADMIN_ACTION_TYPE["UpdateUser"] = "UpdateUser";
    GQLADMIN_ACTION_TYPE["AddCoin"] = "AddCoin";
})(GQLADMIN_ACTION_TYPE = exports.GQLADMIN_ACTION_TYPE || (exports.GQLADMIN_ACTION_TYPE = {}));
/**
 * 앱 이벤트 타입
 */
var GQLAPP_EVENT_TYPE;
(function (GQLAPP_EVENT_TYPE) {
    GQLAPP_EVENT_TYPE["LeaveChatroom"] = "LeaveChatroom";
    GQLAPP_EVENT_TYPE["Invalid"] = "Invalid";
})(GQLAPP_EVENT_TYPE = exports.GQLAPP_EVENT_TYPE || (exports.GQLAPP_EVENT_TYPE = {}));
/**
 * 배너 타입(앱)
 */
var GQLBANNER_TYPE;
(function (GQLBANNER_TYPE) {
    GQLBANNER_TYPE["Story"] = "Story";
    GQLBANNER_TYPE["Custom"] = "Custom";
})(GQLBANNER_TYPE = exports.GQLBANNER_TYPE || (exports.GQLBANNER_TYPE = {}));
/**
 * 배너가 노출될 위치
 */
var GQLBANNER_LOCATION;
(function (GQLBANNER_LOCATION) {
    GQLBANNER_LOCATION["Home"] = "Home";
    GQLBANNER_LOCATION["Interactive"] = "Interactive";
    GQLBANNER_LOCATION["EPUB"] = "EPUB";
})(GQLBANNER_LOCATION = exports.GQLBANNER_LOCATION || (exports.GQLBANNER_LOCATION = {}));
/**
 * 클라이언트 종류
 */
var GQLCLIENT;
(function (GQLCLIENT) {
    GQLCLIENT["App"] = "App";
    GQLCLIENT["Web"] = "Web";
})(GQLCLIENT = exports.GQLCLIENT || (exports.GQLCLIENT = {}));
/**
 * 배너 링크타입
 */
var GQLBANNER_LINK_TYPE;
(function (GQLBANNER_LINK_TYPE) {
    GQLBANNER_LINK_TYPE["DeepLink"] = "DeepLink";
    GQLBANNER_LINK_TYPE["AppInappBrowserLink"] = "AppInappBrowserLink";
    GQLBANNER_LINK_TYPE["AppExternalBrowserLink"] = "AppExternalBrowserLink";
    GQLBANNER_LINK_TYPE["WebCurrentWindowLink"] = "WebCurrentWindowLink";
    GQLBANNER_LINK_TYPE["WebNewWindowLink"] = "WebNewWindowLink";
})(GQLBANNER_LINK_TYPE = exports.GQLBANNER_LINK_TYPE || (exports.GQLBANNER_LINK_TYPE = {}));
/**
 * 배너 노출 상태
 */
var GQLBANNER_STATE;
(function (GQLBANNER_STATE) {
    GQLBANNER_STATE["On"] = "On";
    GQLBANNER_STATE["Off"] = "Off";
    GQLBANNER_STATE["Reserved"] = "Reserved";
    GQLBANNER_STATE["Error"] = "Error";
})(GQLBANNER_STATE = exports.GQLBANNER_STATE || (exports.GQLBANNER_STATE = {}));
/**
 * 언어 코드
 */
var GQLLANGUAGE_CODE;
(function (GQLLANGUAGE_CODE) {
    GQLLANGUAGE_CODE["ko"] = "ko";
    GQLLANGUAGE_CODE["en"] = "en";
})(GQLLANGUAGE_CODE = exports.GQLLANGUAGE_CODE || (exports.GQLLANGUAGE_CODE = {}));
/**
 * 스튜디오 회차 정렬 옵션
 */
var GQLLIST_CHAPTER_FOR_WRITER_SORT_OPTION;
(function (GQLLIST_CHAPTER_FOR_WRITER_SORT_OPTION) {
    GQLLIST_CHAPTER_FOR_WRITER_SORT_OPTION["CHAPTER_INDEX_DESC"] = "CHAPTER_INDEX_DESC";
    GQLLIST_CHAPTER_FOR_WRITER_SORT_OPTION["CHAPTER_INDEX_ASC"] = "CHAPTER_INDEX_ASC";
})(GQLLIST_CHAPTER_FOR_WRITER_SORT_OPTION = exports.GQLLIST_CHAPTER_FOR_WRITER_SORT_OPTION || (exports.GQLLIST_CHAPTER_FOR_WRITER_SORT_OPTION = {}));
/**
 * 작품 통계 캐시 타입(일간, 주간, 월간)
 */
var GQLSTORY_STAT_CACHE_TYPE;
(function (GQLSTORY_STAT_CACHE_TYPE) {
    GQLSTORY_STAT_CACHE_TYPE["Day"] = "Day";
    GQLSTORY_STAT_CACHE_TYPE["Week"] = "Week";
    GQLSTORY_STAT_CACHE_TYPE["Month"] = "Month";
})(GQLSTORY_STAT_CACHE_TYPE = exports.GQLSTORY_STAT_CACHE_TYPE || (exports.GQLSTORY_STAT_CACHE_TYPE = {}));
/**
 * 회차 댓글의 정렬 기준 컬럼
 */
var GQLCHAPTER_COMMENT_ORDER_FIELD;
(function (GQLCHAPTER_COMMENT_ORDER_FIELD) {
    GQLCHAPTER_COMMENT_ORDER_FIELD["UserLikes"] = "UserLikes";
    GQLCHAPTER_COMMENT_ORDER_FIELD["UserLikesByUsers"] = "UserLikesByUsers";
    GQLCHAPTER_COMMENT_ORDER_FIELD["CreatedDate"] = "CreatedDate";
    GQLCHAPTER_COMMENT_ORDER_FIELD["NestedComment"] = "NestedComment";
    GQLCHAPTER_COMMENT_ORDER_FIELD["Reports"] = "Reports";
    GQLCHAPTER_COMMENT_ORDER_FIELD["UserSystemLanguage"] = "UserSystemLanguage";
})(GQLCHAPTER_COMMENT_ORDER_FIELD = exports.GQLCHAPTER_COMMENT_ORDER_FIELD || (exports.GQLCHAPTER_COMMENT_ORDER_FIELD = {}));
/**
 * 정렬 기준 방향. DESC/ASC
 */
var GQLORDER_BY_DIRECTION;
(function (GQLORDER_BY_DIRECTION) {
    GQLORDER_BY_DIRECTION["DESC"] = "DESC";
    GQLORDER_BY_DIRECTION["ASC"] = "ASC";
})(GQLORDER_BY_DIRECTION = exports.GQLORDER_BY_DIRECTION || (exports.GQLORDER_BY_DIRECTION = {}));
/**
 * 다날 결제 진행 상태
 */
var GQLDANAL_PAYMENT_STATUS;
(function (GQLDANAL_PAYMENT_STATUS) {
    GQLDANAL_PAYMENT_STATUS["REQUEST"] = "REQUEST";
    GQLDANAL_PAYMENT_STATUS["READY"] = "READY";
    GQLDANAL_PAYMENT_STATUS["COMPLETE"] = "COMPLETE";
    GQLDANAL_PAYMENT_STATUS["ERROR"] = "ERROR";
    GQLDANAL_PAYMENT_STATUS["CANCELED"] = "CANCELED";
})(GQLDANAL_PAYMENT_STATUS = exports.GQLDANAL_PAYMENT_STATUS || (exports.GQLDANAL_PAYMENT_STATUS = {}));
/**
 * 직영광고 타입
 */
var GQLDIRECT_ADS_TYPE;
(function (GQLDIRECT_ADS_TYPE) {
    GQLDIRECT_ADS_TYPE["Splash"] = "Splash";
    GQLDIRECT_ADS_TYPE["Home"] = "Home";
    GQLDIRECT_ADS_TYPE["Details"] = "Details";
    GQLDIRECT_ADS_TYPE["History"] = "History";
    GQLDIRECT_ADS_TYPE["More"] = "More";
    GQLDIRECT_ADS_TYPE["ChapterEnding"] = "ChapterEnding";
})(GQLDIRECT_ADS_TYPE = exports.GQLDIRECT_ADS_TYPE || (exports.GQLDIRECT_ADS_TYPE = {}));
/**
 * 스토리지 타입
 */
var GQLStorages;
(function (GQLStorages) {
    GQLStorages["StoryFiles"] = "StoryFiles";
    GQLStorages["ImageFile"] = "ImageFile";
    GQLStorages["SFXFile"] = "SFXFile";
    GQLStorages["StudioFile"] = "StudioFile";
    GQLStorages["UserProfileFile"] = "UserProfileFile";
    GQLStorages["ChapterScriptFile"] = "ChapterScriptFile";
    GQLStorages["EPUBFile"] = "EPUBFile";
    GQLStorages["PublicResourceFile"] = "PublicResourceFile";
    GQLStorages["BannerImageFile"] = "BannerImageFile";
    GQLStorages["ChapterCommentImageFile"] = "ChapterCommentImageFile";
    GQLStorages["StoryZipFile"] = "StoryZipFile";
    GQLStorages["AiUserUploadFile"] = "AiUserUploadFile";
})(GQLStorages = exports.GQLStorages || (exports.GQLStorages = {}));
/**
 * 홈 화면의 섹션 타입
 */
var GQLHOME_SECTION_TYPE;
(function (GQLHOME_SECTION_TYPE) {
    GQLHOME_SECTION_TYPE["Favorite"] = "Favorite";
    GQLHOME_SECTION_TYPE["UserGeneration"] = "UserGeneration";
    GQLHOME_SECTION_TYPE["Genre"] = "Genre";
})(GQLHOME_SECTION_TYPE = exports.GQLHOME_SECTION_TYPE || (exports.GQLHOME_SECTION_TYPE = {}));
/**
 * 작품 정렬 타입. 도전스토리는 CHALLENGE_STORY_SORTING 참조.
 */
var GQLSTORY_SORTING;
(function (GQLSTORY_SORTING) {
    GQLSTORY_SORTING["SHOW_ORDER_DESC"] = "SHOW_ORDER_DESC";
    GQLSTORY_SORTING["UPDATED_DESC"] = "UPDATED_DESC";
    GQLSTORY_SORTING["UPDATED_ASC"] = "UPDATED_ASC";
    GQLSTORY_SORTING["NUM_VIEWS_DESC"] = "NUM_VIEWS_DESC";
    GQLSTORY_SORTING["NUM_PLAY_DESC"] = "NUM_PLAY_DESC";
    GQLSTORY_SORTING["NUM_LIKES_DESC"] = "NUM_LIKES_DESC";
    GQLSTORY_SORTING["RANDOM"] = "RANDOM";
    GQLSTORY_SORTING["DAILY_RANK_ASC"] = "DAILY_RANK_ASC";
    GQLSTORY_SORTING["PUBLISHED_DESC"] = "PUBLISHED_DESC";
})(GQLSTORY_SORTING = exports.GQLSTORY_SORTING || (exports.GQLSTORY_SORTING = {}));
/**
 * 패키지 상품의 타임 세일 타입
 */
var GQLTIME_SALE_TYPE;
(function (GQLTIME_SALE_TYPE) {
    GQLTIME_SALE_TYPE["OnlyOnceSinceFirstVisitStore"] = "OnlyOnceSinceFirstVisitStore";
    GQLTIME_SALE_TYPE["UntilFixedEndDate"] = "UntilFixedEndDate";
})(GQLTIME_SALE_TYPE = exports.GQLTIME_SALE_TYPE || (exports.GQLTIME_SALE_TYPE = {}));
/**
 * 공용리소스 타입
 */
var GQLPUBLIC_RESOURCE_TYPE;
(function (GQLPUBLIC_RESOURCE_TYPE) {
    GQLPUBLIC_RESOURCE_TYPE["FULL_WIDTH_IMAGE"] = "FULL_WIDTH_IMAGE";
    GQLPUBLIC_RESOURCE_TYPE["BACKGROUND_IMAGE"] = "BACKGROUND_IMAGE";
    GQLPUBLIC_RESOURCE_TYPE["CHAR_IMAGE"] = "CHAR_IMAGE";
    GQLPUBLIC_RESOURCE_TYPE["ITEM_IMAGE"] = "ITEM_IMAGE";
    GQLPUBLIC_RESOURCE_TYPE["SFX"] = "SFX";
    GQLPUBLIC_RESOURCE_TYPE["BGM"] = "BGM";
})(GQLPUBLIC_RESOURCE_TYPE = exports.GQLPUBLIC_RESOURCE_TYPE || (exports.GQLPUBLIC_RESOURCE_TYPE = {}));
/**
 * 수신한 푸쉬 메시지의 카테고리
 */
var GQLPUSH_CATEGORY;
(function (GQLPUSH_CATEGORY) {
    GQLPUSH_CATEGORY["MY_FAVORITE_STORY"] = "MY_FAVORITE_STORY";
    GQLPUSH_CATEGORY["MY_STORY"] = "MY_STORY";
    GQLPUSH_CATEGORY["MY_CHAPTER_COMMENT"] = "MY_CHAPTER_COMMENT";
})(GQLPUSH_CATEGORY = exports.GQLPUSH_CATEGORY || (exports.GQLPUSH_CATEGORY = {}));
/**
 * 스토리 추천 타입
 */
var GQLSTORY_HAS_RECOMMENDATION_TYPE;
(function (GQLSTORY_HAS_RECOMMENDATION_TYPE) {
    GQLSTORY_HAS_RECOMMENDATION_TYPE["AdminSelect"] = "AdminSelect";
})(GQLSTORY_HAS_RECOMMENDATION_TYPE = exports.GQLSTORY_HAS_RECOMMENDATION_TYPE || (exports.GQLSTORY_HAS_RECOMMENDATION_TYPE = {}));
/**
 * 유저기반 추천 케이스
 */
var GQLUSER_BASED_RECOMMENDATION_CASE;
(function (GQLUSER_BASED_RECOMMENDATION_CASE) {
    GQLUSER_BASED_RECOMMENDATION_CASE["Home"] = "Home";
    GQLUSER_BASED_RECOMMENDATION_CASE["LeaveChatroom"] = "LeaveChatroom";
    GQLUSER_BASED_RECOMMENDATION_CASE["FirstUser"] = "FirstUser";
})(GQLUSER_BASED_RECOMMENDATION_CASE = exports.GQLUSER_BASED_RECOMMENDATION_CASE || (exports.GQLUSER_BASED_RECOMMENDATION_CASE = {}));
/**
 * 작품기반 추천 케이스
 */
var GQLITEM_BASED_RECOMMENDATION_CASE;
(function (GQLITEM_BASED_RECOMMENDATION_CASE) {
    GQLITEM_BASED_RECOMMENDATION_CASE["Details"] = "Details";
    GQLITEM_BASED_RECOMMENDATION_CASE["DetailsMore"] = "DetailsMore";
    GQLITEM_BASED_RECOMMENDATION_CASE["EndingModal"] = "EndingModal";
})(GQLITEM_BASED_RECOMMENDATION_CASE = exports.GQLITEM_BASED_RECOMMENDATION_CASE || (exports.GQLITEM_BASED_RECOMMENDATION_CASE = {}));
/**
 * 스플 추천 온보딩 타입
 */
var GQLRECOMMENDATION_ONBOARDING_TYPE;
(function (GQLRECOMMENDATION_ONBOARDING_TYPE) {
    GQLRECOMMENDATION_ONBOARDING_TYPE["Gender"] = "Gender";
    GQLRECOMMENDATION_ONBOARDING_TYPE["Generation"] = "Generation";
    GQLRECOMMENDATION_ONBOARDING_TYPE["Genre"] = "Genre";
})(GQLRECOMMENDATION_ONBOARDING_TYPE = exports.GQLRECOMMENDATION_ONBOARDING_TYPE || (exports.GQLRECOMMENDATION_ONBOARDING_TYPE = {}));
/**
 * 원격 스크립트 타입
 */
var GQLREMOTE_SCRIPT_TYPE;
(function (GQLREMOTE_SCRIPT_TYPE) {
    GQLREMOTE_SCRIPT_TYPE["StoryPlay"] = "StoryPlay";
    GQLREMOTE_SCRIPT_TYPE["HelloBot"] = "HelloBot";
    GQLREMOTE_SCRIPT_TYPE["ChatGPT"] = "ChatGPT";
})(GQLREMOTE_SCRIPT_TYPE = exports.GQLREMOTE_SCRIPT_TYPE || (exports.GQLREMOTE_SCRIPT_TYPE = {}));
/**
 * 작품의 종류 (정식연재, 도전스토리, 월간스토리, 보물상자, 글로벌)
 */
var GQLUI_STORY_SECTION_TYPE;
(function (GQLUI_STORY_SECTION_TYPE) {
    GQLUI_STORY_SECTION_TYPE["MWS"] = "MWS";
    GQLUI_STORY_SECTION_TYPE["OnStage"] = "OnStage";
    GQLUI_STORY_SECTION_TYPE["Monthly"] = "Monthly";
    GQLUI_STORY_SECTION_TYPE["Dropped"] = "Dropped";
    GQLUI_STORY_SECTION_TYPE["Banner"] = "Banner";
    GQLUI_STORY_SECTION_TYPE["Adult"] = "Adult";
    GQLUI_STORY_SECTION_TYPE["Finished"] = "Finished";
    GQLUI_STORY_SECTION_TYPE["New"] = "New";
    GQLUI_STORY_SECTION_TYPE["Original"] = "Original";
})(GQLUI_STORY_SECTION_TYPE = exports.GQLUI_STORY_SECTION_TYPE || (exports.GQLUI_STORY_SECTION_TYPE = {}));
/**
 * 스튜디오 작품 목록 정렬 옵션
 */
var GQLLIST_STORY_FOR_WRITER_SORT_OPTION;
(function (GQLLIST_STORY_FOR_WRITER_SORT_OPTION) {
    GQLLIST_STORY_FOR_WRITER_SORT_OPTION["PUBLISHED_AT_DESC"] = "PUBLISHED_AT_DESC";
    GQLLIST_STORY_FOR_WRITER_SORT_OPTION["PUBLISHED_AT_ASC"] = "PUBLISHED_AT_ASC";
    GQLLIST_STORY_FOR_WRITER_SORT_OPTION["STORY_ID_DESC"] = "STORY_ID_DESC";
    GQLLIST_STORY_FOR_WRITER_SORT_OPTION["STORY_ID_ASC"] = "STORY_ID_ASC";
})(GQLLIST_STORY_FOR_WRITER_SORT_OPTION = exports.GQLLIST_STORY_FOR_WRITER_SORT_OPTION || (exports.GQLLIST_STORY_FOR_WRITER_SORT_OPTION = {}));
/**
 * 도전 스토리 탭의 정렬 타입
 */
var GQLCHALLENGE_STORY_SORTING;
(function (GQLCHALLENGE_STORY_SORTING) {
    GQLCHALLENGE_STORY_SORTING["RECOMMENDED"] = "RECOMMENDED";
    GQLCHALLENGE_STORY_SORTING["LATEST_COMMENT"] = "LATEST_COMMENT";
    GQLCHALLENGE_STORY_SORTING["NUM_LIKES"] = "NUM_LIKES";
    GQLCHALLENGE_STORY_SORTING["NUM_VIEWS"] = "NUM_VIEWS";
    GQLCHALLENGE_STORY_SORTING["NUM_PLAY"] = "NUM_PLAY";
    GQLCHALLENGE_STORY_SORTING["LATEST_UPDATED"] = "LATEST_UPDATED";
    GQLCHALLENGE_STORY_SORTING["RANDOM"] = "RANDOM";
})(GQLCHALLENGE_STORY_SORTING = exports.GQLCHALLENGE_STORY_SORTING || (exports.GQLCHALLENGE_STORY_SORTING = {}));
/**
 * 전환율 타입 (신규 유저, 처음 플레이한 유저, 전체)
 */
var GQLSTORY_CONVERSION_STAT_TYPE;
(function (GQLSTORY_CONVERSION_STAT_TYPE) {
    GQLSTORY_CONVERSION_STAT_TYPE["NewUser"] = "NewUser";
    GQLSTORY_CONVERSION_STAT_TYPE["NewPlayer"] = "NewPlayer";
    GQLSTORY_CONVERSION_STAT_TYPE["All"] = "All";
})(GQLSTORY_CONVERSION_STAT_TYPE = exports.GQLSTORY_CONVERSION_STAT_TYPE || (exports.GQLSTORY_CONVERSION_STAT_TYPE = {}));
/**
 * 작품 랭킹 타입 (플레이수, 정주행률, 좋아요, 댓글)
 */
var GQLSTORY_RANK_TYPE;
(function (GQLSTORY_RANK_TYPE) {
    GQLSTORY_RANK_TYPE["NumPlay"] = "NumPlay";
    GQLSTORY_RANK_TYPE["Conversion"] = "Conversion";
    GQLSTORY_RANK_TYPE["NumLike"] = "NumLike";
    GQLSTORY_RANK_TYPE["NumComment"] = "NumComment";
})(GQLSTORY_RANK_TYPE = exports.GQLSTORY_RANK_TYPE || (exports.GQLSTORY_RANK_TYPE = {}));
/**
 * 스토리 리액션
 */
var GQLSTORY_REACTION_TYPE;
(function (GQLSTORY_REACTION_TYPE) {
    GQLSTORY_REACTION_TYPE["Like"] = "Like";
})(GQLSTORY_REACTION_TYPE = exports.GQLSTORY_REACTION_TYPE || (exports.GQLSTORY_REACTION_TYPE = {}));
/**
 * 스토리게임 탭 종류
 */
var GQLStorygameTabType;
(function (GQLStorygameTabType) {
    GQLStorygameTabType["Recommended"] = "Recommended";
    GQLStorygameTabType["Genre"] = "Genre";
    GQLStorygameTabType["NSFW"] = "NSFW";
})(GQLStorygameTabType = exports.GQLStorygameTabType || (exports.GQLStorygameTabType = {}));
/**
 * 스토리게임 섹션 UI 타입
 */
var GQLStorygameSectionUIType;
(function (GQLStorygameSectionUIType) {
    GQLStorygameSectionUIType["Slider"] = "Slider";
    GQLStorygameSectionUIType["CarouselSmall"] = "CarouselSmall";
    GQLStorygameSectionUIType["CarouselMedium"] = "CarouselMedium";
    GQLStorygameSectionUIType["CarouselLarge"] = "CarouselLarge";
    GQLStorygameSectionUIType["CarouselProgress"] = "CarouselProgress";
    GQLStorygameSectionUIType["CarouselRank"] = "CarouselRank";
    GQLStorygameSectionUIType["RankList"] = "RankList";
})(GQLStorygameSectionUIType = exports.GQLStorygameSectionUIType || (exports.GQLStorygameSectionUIType = {}));
/**
 * 스토리게임 섹션 알고리즘
 */
var GQLStorygameSectionAlgorithm;
(function (GQLStorygameSectionAlgorithm) {
    GQLStorygameSectionAlgorithm["Manual"] = "Manual";
    GQLStorygameSectionAlgorithm["RecentPlay"] = "RecentPlay";
    GQLStorygameSectionAlgorithm["HighAPPU"] = "HighAPPU";
    GQLStorygameSectionAlgorithm["HighViews"] = "HighViews";
    GQLStorygameSectionAlgorithm["FreeHighAPPU"] = "FreeHighAPPU";
    GQLStorygameSectionAlgorithm["RecentReleaseViews"] = "RecentReleaseViews";
    GQLStorygameSectionAlgorithm["HighSales"] = "HighSales";
    GQLStorygameSectionAlgorithm["ManyFavorites"] = "ManyFavorites";
})(GQLStorygameSectionAlgorithm = exports.GQLStorygameSectionAlgorithm || (exports.GQLStorygameSectionAlgorithm = {}));
/**
 * 스토리게임 섹션 기간 기준
 */
var GQLStorygameSectionPeriod;
(function (GQLStorygameSectionPeriod) {
    GQLStorygameSectionPeriod["All"] = "All";
    GQLStorygameSectionPeriod["Day"] = "Day";
    GQLStorygameSectionPeriod["Day3"] = "Day3";
    GQLStorygameSectionPeriod["Week"] = "Week";
    GQLStorygameSectionPeriod["Week2"] = "Week2";
    GQLStorygameSectionPeriod["Month"] = "Month";
    GQLStorygameSectionPeriod["Month3"] = "Month3";
    GQLStorygameSectionPeriod["Month6"] = "Month6";
    GQLStorygameSectionPeriod["Year"] = "Year";
})(GQLStorygameSectionPeriod = exports.GQLStorygameSectionPeriod || (exports.GQLStorygameSectionPeriod = {}));
/**
 * 스토리게임 섹션 타입
 */
var GQLStorygameSectionType;
(function (GQLStorygameSectionType) {
    GQLStorygameSectionType["Custom"] = "Custom";
    GQLStorygameSectionType["SliderBanner"] = "SliderBanner";
    GQLStorygameSectionType["GameBasedRecommendation"] = "GameBasedRecommendation";
    GQLStorygameSectionType["TagBasedRecommendation"] = "TagBasedRecommendation";
})(GQLStorygameSectionType = exports.GQLStorygameSectionType || (exports.GQLStorygameSectionType = {}));
/**
 * 성별
 */
var GQLSEX_DISTINCTION;
(function (GQLSEX_DISTINCTION) {
    GQLSEX_DISTINCTION["Man"] = "Man";
    GQLSEX_DISTINCTION["Woman"] = "Woman";
    GQLSEX_DISTINCTION["Neutrality"] = "Neutrality";
})(GQLSEX_DISTINCTION = exports.GQLSEX_DISTINCTION || (exports.GQLSEX_DISTINCTION = {}));
/**
 * tts 발화자 상태값
 */
var GQLTTS_SPEAKER_STATUS;
(function (GQLTTS_SPEAKER_STATUS) {
    GQLTTS_SPEAKER_STATUS["Active"] = "Active";
    GQLTTS_SPEAKER_STATUS["Inactive"] = "Inactive";
})(GQLTTS_SPEAKER_STATUS = exports.GQLTTS_SPEAKER_STATUS || (exports.GQLTTS_SPEAKER_STATUS = {}));
/**
 * 사용자 추천 온보딩 상택값
 */
var GQLUSER_RECOMMENDATION_ONBOARDING_STATUS;
(function (GQLUSER_RECOMMENDATION_ONBOARDING_STATUS) {
    GQLUSER_RECOMMENDATION_ONBOARDING_STATUS["Skip"] = "Skip";
    GQLUSER_RECOMMENDATION_ONBOARDING_STATUS["Done"] = "Done";
})(GQLUSER_RECOMMENDATION_ONBOARDING_STATUS = exports.GQLUSER_RECOMMENDATION_ONBOARDING_STATUS || (exports.GQLUSER_RECOMMENDATION_ONBOARDING_STATUS = {}));
/**
 * 스토어 탭 종류
 */
var GQLStorePageTabType;
(function (GQLStorePageTabType) {
    GQLStorePageTabType["Recommended"] = "Recommended";
    GQLStorePageTabType["Genre"] = "Genre";
    GQLStorePageTabType["NSFW"] = "NSFW";
})(GQLStorePageTabType = exports.GQLStorePageTabType || (exports.GQLStorePageTabType = {}));
/**
 * 스토어 섹션 UI 타입
 */
var GQLStorePageSectionUIType;
(function (GQLStorePageSectionUIType) {
    GQLStorePageSectionUIType["Slider"] = "Slider";
    GQLStorePageSectionUIType["CarouselSmall"] = "CarouselSmall";
    GQLStorePageSectionUIType["CarouselMedium"] = "CarouselMedium";
    GQLStorePageSectionUIType["CarouselLarge"] = "CarouselLarge";
    GQLStorePageSectionUIType["CarouselProgress"] = "CarouselProgress";
    GQLStorePageSectionUIType["CarouselRank"] = "CarouselRank";
    GQLStorePageSectionUIType["CarouselBundle"] = "CarouselBundle";
    GQLStorePageSectionUIType["RankList"] = "RankList";
})(GQLStorePageSectionUIType = exports.GQLStorePageSectionUIType || (exports.GQLStorePageSectionUIType = {}));
/**
 * 스토어 섹션 알고리즘
 */
var GQLStorePageSectionAlgorithm;
(function (GQLStorePageSectionAlgorithm) {
    GQLStorePageSectionAlgorithm["Manual"] = "Manual";
    GQLStorePageSectionAlgorithm["HighAppu"] = "HighAppu";
    GQLStorePageSectionAlgorithm["HighArpu"] = "HighArpu";
    GQLStorePageSectionAlgorithm["MostViewed"] = "MostViewed";
    GQLStorePageSectionAlgorithm["MostSold"] = "MostSold";
    GQLStorePageSectionAlgorithm["MostPreordered"] = "MostPreordered";
    GQLStorePageSectionAlgorithm["MostWishlisted"] = "MostWishlisted";
    GQLStorePageSectionAlgorithm["UpcomingRelease"] = "UpcomingRelease";
    GQLStorePageSectionAlgorithm["RecentlyPlayed"] = "RecentlyPlayed";
    GQLStorePageSectionAlgorithm["MostPositiveReviews"] = "MostPositiveReviews";
    GQLStorePageSectionAlgorithm["HighestPositiveRatio"] = "HighestPositiveRatio";
})(GQLStorePageSectionAlgorithm = exports.GQLStorePageSectionAlgorithm || (exports.GQLStorePageSectionAlgorithm = {}));
/**
 * 스토어 섹션 기간 기준
 */
var GQLStorePageSectionPeriod;
(function (GQLStorePageSectionPeriod) {
    GQLStorePageSectionPeriod["All"] = "All";
    GQLStorePageSectionPeriod["Week"] = "Week";
    GQLStorePageSectionPeriod["Month"] = "Month";
    GQLStorePageSectionPeriod["Month2"] = "Month2";
    GQLStorePageSectionPeriod["Month3"] = "Month3";
    GQLStorePageSectionPeriod["Year"] = "Year";
})(GQLStorePageSectionPeriod = exports.GQLStorePageSectionPeriod || (exports.GQLStorePageSectionPeriod = {}));
/**
 * 스토어 장바구니 아이템 타입
 */
var GQLStoreCartItemType;
(function (GQLStoreCartItemType) {
    GQLStoreCartItemType["StoryBundle"] = "StoryBundle";
    GQLStoreCartItemType["StoreBundle"] = "StoreBundle";
})(GQLStoreCartItemType = exports.GQLStoreCartItemType || (exports.GQLStoreCartItemType = {}));
/**
 * 스토어 검색 설정 상태
 */
var GQLStoreSearchConfigStatus;
(function (GQLStoreSearchConfigStatus) {
    GQLStoreSearchConfigStatus["Editing"] = "Editing";
    GQLStoreSearchConfigStatus["Discarded"] = "Discarded";
    GQLStoreSearchConfigStatus["Active"] = "Active";
})(GQLStoreSearchConfigStatus = exports.GQLStoreSearchConfigStatus || (exports.GQLStoreSearchConfigStatus = {}));
/**
 * 스토어 결제 플랫폼(Toss or Xsolla)
 */
var GQLStorePaymentPlatform;
(function (GQLStorePaymentPlatform) {
    GQLStorePaymentPlatform["Toss"] = "Toss";
    GQLStorePaymentPlatform["Xsolla"] = "Xsolla";
})(GQLStorePaymentPlatform = exports.GQLStorePaymentPlatform || (exports.GQLStorePaymentPlatform = {}));
/**
 * 스토어 결제 상태
 */
var GQLStorePaymentStatus;
(function (GQLStorePaymentStatus) {
    GQLStorePaymentStatus["READY"] = "READY";
    GQLStorePaymentStatus["IN_PROGRESS"] = "IN_PROGRESS";
    GQLStorePaymentStatus["WAITING_FOR_DEPOSIT"] = "WAITING_FOR_DEPOSIT";
    GQLStorePaymentStatus["DONE"] = "DONE";
    GQLStorePaymentStatus["PARTIAL_CANCELED"] = "PARTIAL_CANCELED";
    GQLStorePaymentStatus["CANCELED"] = "CANCELED";
    GQLStorePaymentStatus["ABORTED"] = "ABORTED";
    GQLStorePaymentStatus["FAILED"] = "FAILED";
    GQLStorePaymentStatus["FORGERY"] = "FORGERY";
    GQLStorePaymentStatus["EXPIRED"] = "EXPIRED";
})(GQLStorePaymentStatus = exports.GQLStorePaymentStatus || (exports.GQLStorePaymentStatus = {}));
/**
 * 다날 사용자 접속 환경
 */
var GQLDANAL_PAYMENT_USER_AGENT;
(function (GQLDANAL_PAYMENT_USER_AGENT) {
    GQLDANAL_PAYMENT_USER_AGENT["WP"] = "WP";
    GQLDANAL_PAYMENT_USER_AGENT["WM"] = "WM";
})(GQLDANAL_PAYMENT_USER_AGENT = exports.GQLDANAL_PAYMENT_USER_AGENT || (exports.GQLDANAL_PAYMENT_USER_AGENT = {}));
/**
 * 아임포트 결제 상태
 */
var GQLIMP_STATE;
(function (GQLIMP_STATE) {
    GQLIMP_STATE["REQUESTED"] = "REQUESTED";
    GQLIMP_STATE["COMPLETED"] = "COMPLETED";
    GQLIMP_STATE["CANCELED"] = "CANCELED";
    GQLIMP_STATE["FORGERY"] = "FORGERY";
    GQLIMP_STATE["FAILED"] = "FAILED";
})(GQLIMP_STATE = exports.GQLIMP_STATE || (exports.GQLIMP_STATE = {}));
/**
 * 문의 메일 타입
 */
var GQLCONTACT_MAIL_TYPE;
(function (GQLCONTACT_MAIL_TYPE) {
    GQLCONTACT_MAIL_TYPE["app"] = "app";
    GQLCONTACT_MAIL_TYPE["partner"] = "partner";
    GQLCONTACT_MAIL_TYPE["advertisement"] = "advertisement";
    GQLCONTACT_MAIL_TYPE["job"] = "job";
})(GQLCONTACT_MAIL_TYPE = exports.GQLCONTACT_MAIL_TYPE || (exports.GQLCONTACT_MAIL_TYPE = {}));
/**
 * 팝업 조건 부등호
 */
var GQLONBOARDING_POPUP_INEQUALITY;
(function (GQLONBOARDING_POPUP_INEQUALITY) {
    GQLONBOARDING_POPUP_INEQUALITY["MoreThan"] = "MoreThan";
    GQLONBOARDING_POPUP_INEQUALITY["LessThan"] = "LessThan";
    GQLONBOARDING_POPUP_INEQUALITY["EqualOrMoreThan"] = "EqualOrMoreThan";
    GQLONBOARDING_POPUP_INEQUALITY["EqualOrLessThan"] = "EqualOrLessThan";
    GQLONBOARDING_POPUP_INEQUALITY["Equal"] = "Equal";
    GQLONBOARDING_POPUP_INEQUALITY["NotEqual"] = "NotEqual";
})(GQLONBOARDING_POPUP_INEQUALITY = exports.GQLONBOARDING_POPUP_INEQUALITY || (exports.GQLONBOARDING_POPUP_INEQUALITY = {}));
/**
 * 스튜디오에서 작품 가격정보 설정 타입
 */
var GQLSTUDIO_STORY_PRICE_SETTING_TYPE;
(function (GQLSTUDIO_STORY_PRICE_SETTING_TYPE) {
    GQLSTUDIO_STORY_PRICE_SETTING_TYPE["FREE"] = "FREE";
    GQLSTUDIO_STORY_PRICE_SETTING_TYPE["FREE_AD"] = "FREE_AD";
    GQLSTUDIO_STORY_PRICE_SETTING_TYPE["PAID"] = "PAID";
})(GQLSTUDIO_STORY_PRICE_SETTING_TYPE = exports.GQLSTUDIO_STORY_PRICE_SETTING_TYPE || (exports.GQLSTUDIO_STORY_PRICE_SETTING_TYPE = {}));
/**
 * 토스 페이먼츠 결제 타입
 */
var GQLTOSS_PAYMENTS_PAY_TYPE;
(function (GQLTOSS_PAYMENTS_PAY_TYPE) {
    GQLTOSS_PAYMENTS_PAY_TYPE["OVERSEAS"] = "OVERSEAS";
    GQLTOSS_PAYMENTS_PAY_TYPE["DOMESTIC"] = "DOMESTIC";
})(GQLTOSS_PAYMENTS_PAY_TYPE = exports.GQLTOSS_PAYMENTS_PAY_TYPE || (exports.GQLTOSS_PAYMENTS_PAY_TYPE = {}));
/**
 * 토스 페이먼츠 결제 상태
 */
var GQLTOSS_PAYMENTS_STATUS;
(function (GQLTOSS_PAYMENTS_STATUS) {
    GQLTOSS_PAYMENTS_STATUS["READY"] = "READY";
    GQLTOSS_PAYMENTS_STATUS["IN_PROGRESS"] = "IN_PROGRESS";
    GQLTOSS_PAYMENTS_STATUS["WAITING_FOR_DEPOSIT"] = "WAITING_FOR_DEPOSIT";
    GQLTOSS_PAYMENTS_STATUS["DONE"] = "DONE";
    GQLTOSS_PAYMENTS_STATUS["PARTIAL_CANCELED"] = "PARTIAL_CANCELED";
    GQLTOSS_PAYMENTS_STATUS["CANCELED"] = "CANCELED";
    GQLTOSS_PAYMENTS_STATUS["ABORTED"] = "ABORTED";
    GQLTOSS_PAYMENTS_STATUS["FAILED"] = "FAILED";
    GQLTOSS_PAYMENTS_STATUS["FORGERY"] = "FORGERY";
    GQLTOSS_PAYMENTS_STATUS["EXPIRED"] = "EXPIRED";
})(GQLTOSS_PAYMENTS_STATUS = exports.GQLTOSS_PAYMENTS_STATUS || (exports.GQLTOSS_PAYMENTS_STATUS = {}));
/**
 * TTS 화자 감정
 */
var GQLTTS_EMOTION;
(function (GQLTTS_EMOTION) {
    GQLTTS_EMOTION["Default"] = "Default";
    GQLTTS_EMOTION["Happy"] = "Happy";
    GQLTTS_EMOTION["Kind"] = "Kind";
    GQLTTS_EMOTION["Angry"] = "Angry";
    GQLTTS_EMOTION["Annoyance"] = "Annoyance";
    GQLTTS_EMOTION["WeekAngry"] = "WeekAngry";
    GQLTTS_EMOTION["Howl"] = "Howl";
    GQLTTS_EMOTION["Cry"] = "Cry";
    GQLTTS_EMOTION["Surprised"] = "Surprised";
    GQLTTS_EMOTION["Fear"] = "Fear";
    GQLTTS_EMOTION["Whisper"] = "Whisper";
    GQLTTS_EMOTION["Powerless"] = "Powerless";
    GQLTTS_EMOTION["Drunken"] = "Drunken";
    GQLTTS_EMOTION["Insipid"] = "Insipid";
})(GQLTTS_EMOTION = exports.GQLTTS_EMOTION || (exports.GQLTTS_EMOTION = {}));
/**
 * TTS 음의 속도/높낮이
 */
var GQLTTS_DPE_ID;
(function (GQLTTS_DPE_ID) {
    GQLTTS_DPE_ID["Default_Default"] = "Default_Default";
    GQLTTS_DPE_ID["Slow_Default"] = "Slow_Default";
    GQLTTS_DPE_ID["Quick_Default"] = "Quick_Default";
    GQLTTS_DPE_ID["Default_Low"] = "Default_Low";
    GQLTTS_DPE_ID["Default_High"] = "Default_High";
    GQLTTS_DPE_ID["Quick_Low"] = "Quick_Low";
    GQLTTS_DPE_ID["Quick_High"] = "Quick_High";
    GQLTTS_DPE_ID["Slow_Low"] = "Slow_Low";
    GQLTTS_DPE_ID["Slow_High"] = "Slow_High";
})(GQLTTS_DPE_ID = exports.GQLTTS_DPE_ID || (exports.GQLTTS_DPE_ID = {}));
/**
 * 휴면 계정 처리 로그 타입
 */
var GQLDORMANT_USER_LOG_TYPE;
(function (GQLDORMANT_USER_LOG_TYPE) {
    GQLDORMANT_USER_LOG_TYPE["Warning"] = "Warning";
    GQLDORMANT_USER_LOG_TYPE["Transition"] = "Transition";
    GQLDORMANT_USER_LOG_TYPE["Terminate"] = "Terminate";
})(GQLDORMANT_USER_LOG_TYPE = exports.GQLDORMANT_USER_LOG_TYPE || (exports.GQLDORMANT_USER_LOG_TYPE = {}));
/**
 * 스토리 번들의 접속 국가 제한
 */
var GQLSTORY_BUNDLE_COUNTRY_ACCESS;
(function (GQLSTORY_BUNDLE_COUNTRY_ACCESS) {
    GQLSTORY_BUNDLE_COUNTRY_ACCESS["allow"] = "allow";
    GQLSTORY_BUNDLE_COUNTRY_ACCESS["deny"] = "deny";
})(GQLSTORY_BUNDLE_COUNTRY_ACCESS = exports.GQLSTORY_BUNDLE_COUNTRY_ACCESS || (exports.GQLSTORY_BUNDLE_COUNTRY_ACCESS = {}));
