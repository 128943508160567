import {
  EndBlockType,
  IDOBlock,
  StudioValidationSeverity,
} from '@storyplay/common'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { STUDIO_COLORS } from '../../../../../styles/colors'
import { ErrorMessage } from '../../common/ErrorMessage'
import {
  IStudioDropDownRoute,
  StudioDropDownForRoute,
} from '../../dropdown/StudioDropDownForRoute'
import { HbAlgorithmBlockBadge } from '../HbAlgorithmBlockBadge'
import { ReactComponent as IcnDefaultEndingBadge } from '../../../../../assets/img/studio/svgs/icn_ending_default_badge.svg'

interface IProps {
  block: IDOBlock
  isReadOnly: boolean
  containerRef: React.MutableRefObject<any>
  hasError: boolean
  severity: StudioValidationSeverity
  openDropDown: boolean
  setOpenDropDown: React.Dispatch<React.SetStateAction<boolean>>
  dropdownOptions: IStudioDropDownRoute[]
}

export const FlowChartDefaultBlock = observer((props: IProps) => {
  const {
    block,
    isReadOnly,
    containerRef,
    hasError,
    severity,
    openDropDown,
    setOpenDropDown,
    dropdownOptions,
  } = props
  const isDefaultEnding = block.isDefaultEndingBlock
  // const defaultEndingPrefix = isDefaultEnding ? '* ' : ''
  const renderBadge = () => {
    if (block.isStartingBlock) {
      return (
        <Badge className="flex justify-center items-center text-primary-500 border-1 border-primary-500">
          시작
        </Badge>
      )
    } else if (block.isChapterEndingBlock) {
      return (
        <Badge className="flex justify-center items-center text-primary-500 border-1 border-primary-500">
          끝
        </Badge>
      )
    } else if (block.isStoryEndingBlock) {
      return (
        <Badge
          className="flex justify-center items-center text-primary-500 border-1 border-primary-500"
          style={{ width: 68, minWidth: 68, maxWidth: 68 }}
        >
          최종화 엔딩
        </Badge>
      )
    }
    return null
  }

  const hidePlusButton =
    block.isChapterEndingBlock || block.isStoryEndingBlock || isReadOnly
  const containsAlgorithmBlock = block.hasAlgorithmMessage

  const blockText = block.id
  return (
    <Container
      ref={containerRef}
      className={classNames(
        'relative w-full flex flex-col flex-wrap w-full border-1 space-y-1 justify-center items-center',
        {
          'border-primary-300 bg-primary-50': containsAlgorithmBlock,
          'border-gray-300 bg-gray-200':
            !isDefaultEnding && !containsAlgorithmBlock,
          'border-primary-500 bg-primary-hover':
            isDefaultEnding && !containsAlgorithmBlock,
        }
      )}
      style={{ padding: 8 }}
    >
      <div className="flex">
        {isDefaultEnding && (
          <IcnDefaultEndingBadgeWrapper>
            <IcnDefaultEndingBadge />
          </IcnDefaultEndingBadgeWrapper>
        )}
        {renderBadge()}
        <Text
          className={classNames('text-gray-900 font-medium', {
            'font-bold':
              block.isChapterEndingBlock ||
              block.isStoryEndingBlock ||
              block.isStartingBlock,
          })}
        >
          {blockText}
        </Text>
        {hasError && (
          <ErrorMessage
            errorMessage={''}
            severity={severity}
            isShowOnlyErrorIcon
            iconStyle={{ marginLeft: 4, top: -1 }}
          />
        )}
      </div>
      {containsAlgorithmBlock && <HbAlgorithmBlockBadge />}
      {openDropDown && !hidePlusButton && (
        <StudioDropDownForRoute
          options={dropdownOptions}
          onClick={(endingBlockType: EndBlockType) => {
            if (endingBlockType === block.lastStatementInBlock?.endBlockType) {
              return
            }
            block.upsertNewEndBlock(endingBlockType, true)
          }}
          width={180}
          open={openDropDown}
          onClose={() => setOpenDropDown(false)}
        />
      )}
      {!hidePlusButton && (
        <AddButton
          className=""
          style={
            openDropDown
              ? {
                  transform: 'translateX(-50%) translateY(50%) rotate(45deg)',
                }
              : {}
          }
          onMouseDown={() => setOpenDropDown((p) => !p)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask id="path-1-inside-1_820_10013" fill="white">
              <path d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z" />
            </mask>
            <path
              d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10Z"
              fill={STUDIO_COLORS.Primary500}
            />
            <path
              d="M19 10C19 14.9706 14.9706 19 10 19V21C16.0751 21 21 16.0751 21 10H19ZM10 19C5.02944 19 1 14.9706 1 10H-1C-1 16.0751 3.92487 21 10 21V19ZM1 10C1 5.02944 5.02944 1 10 1V-1C3.92487 -1 -1 3.92487 -1 10H1ZM10 1C14.9706 1 19 5.02944 19 10H21C21 3.92487 16.0751 -1 10 -1V1Z"
              fill={STUDIO_COLORS.Primary600}
              mask="url(#path-1-inside-1_820_10013)"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.25 10.5V13.75C9.25 14.1642 9.58579 14.5 10 14.5C10.4142 14.5 10.75 14.1642 10.75 13.75V10.5L13.75 10.5C14.1642 10.5 14.5 10.1642 14.5 9.75C14.5 9.33579 14.1642 9 13.75 9L10.75 9V6C10.75 5.58579 10.4142 5.25 10 5.25C9.58579 5.25 9.25 5.58579 9.25 6V9L6 9C5.58579 9 5.25 9.33579 5.25 9.75C5.25 10.1642 5.58579 10.5 6 10.5L9.25 10.5Z"
              fill="white"
            />
          </svg>
        </AddButton>
      )}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  min-width: 180px;
  max-width: 180px;
  padding: 8px;
  min-height: 56px;
  border-radius: 8px;
`

const Badge = styled.div`
  position: relative;
  top: 1px;
  width: 36px;
  height: 18px;
  border-radius: 12px;
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  margin-right: 8px;
`

const Text = styled.span`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;
`

const AddButton = styled.button`
  z-index: 5;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);

  outline: none;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }
`

const IcnDefaultEndingBadgeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 8px;
`
