"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GNBModal = void 0;
var GNBModal;
(function (GNBModal) {
    GNBModal["ChapterInfo"] = "ChapterInfo";
    GNBModal["ShortcutInfo"] = "ShortcutInfo";
    GNBModal["EventGuide"] = "EventGuide";
    GNBModal["StoryListForParticipatingInEvent"] = "StoryListForParticipatingInEvent";
    GNBModal["ParticipatingInEvent"] = "ParticipatingInEvent";
    GNBModal["PublishForChapter"] = "PublishForChapter";
    GNBModal["ExportToSheet"] = "ExportToSheet";
    GNBModal["ChapterFreeSetting"] = "ChapterFreeSetting";
    GNBModal["ReplaceCharactersInChapter"] = "ReplaceCharactersInChapter";
})(GNBModal = exports.GNBModal || (exports.GNBModal = {}));
