import type { DOBlock, DOSTToBlock } from '@storyplay/common'
import { jointPointSymbols } from '@storyplay/common/lib/studio/aop'
import {
  HbExtBlock,
  HbExtBlockWithSheetId,
} from '@storyplay/common/src/hellobot/types/block'
import { HB_STATEMENT } from '@storyplay/common/src/hellobot/types/statement'
import { hbApiClient } from './advisorHbApiClient'
import { hbAdvisor } from './hbAdvisorContainer'

class DOSTToBlockAdvisor {
  @hbAdvisor(jointPointSymbols.DOSTToBlock.changeToBlockInternal, 'pre')
  async preChangeToBlockInternal(target: DOSTToBlock, toBlockNames: string[]) {}

  @hbAdvisor(jointPointSymbols.DOSTToBlock.changeToBlockInternal, 'post')
  async postChangeToBlockInternal(
    target: DOSTToBlock,
    ret: any,
    toBlockNames: string[]
  ) {
    const hbStatement = target.data as HB_STATEMENT
    const doBlock = toBlockNames.map(
      (block) => target.block.store.getById(block) as unknown as HbExtBlock
    )
    console.log('doBlock post', hbStatement, doBlock)
    console.log('doBlock post target', target)

    if (hbStatement.hbExtensionData?.id) {
      hbApiClient.message.update(hbStatement.hbExtensionData.id, {
        linkBlocks: [
          {
            blockIds: doBlock?.map((d) => d.hbExtensionData.id),
          },
        ],
        type: hbStatement.hbExtensionData.data?.type,
      })
    } else {
      // 장면연결이 생성될떄 여기로 들어옴
      const targetDoBlock = target.block as DOBlock
      const hbExtensionData = targetDoBlock.hbExtensionData

      const res = await hbApiClient.message.create({
        data: {
          type: 'next',
          linkBlocks: [
            {
              blockIds: doBlock?.map((d) => d.hbExtensionData.id),
            },
          ],
        },
        kind: 'chatbot',
        sheetId: hbExtensionData?.sheetId,
      })
      targetDoBlock.updateHbExtensionData({
        ...res,
        id: res.blockId,
      } as HbExtBlockWithSheetId)
      target.addDataHbExtensionData(res)
    }
  }
}

const dosttoblockadvisor = new DOSTToBlockAdvisor()

export { dosttoblockadvisor }
