import React from 'react'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { useStudioStore } from '../../containers/studio/di/configureRootStore'

import { AppOptions } from '../../utils/AppOptions'

const GA_ID = AppOptions.GOOGLE_ANALYTICS_ID

export function useRouteChangeTrackerForGA() {
  const store = useStudioStore()
  const location = useLocation()
  const [initialized, setInitialized] = React.useState(false)

  React.useEffect(() => {
    if (!!GA_ID) {
      ReactGA.initialize(GA_ID, {
        testMode: false, //AppOptions.GOOGLE_ANALYTICS_TEST_MODE,
        gtagOptions: {
          debug_mode: AppOptions.GOOGLE_ANALYTICS_TEST_MODE,
        },
      })
      setInitialized(true)
    }
  }, [])

  React.useEffect(() => {
    if (initialized) {
      ReactGA.send('pageview')
    }
    store.gnbStore.setPathname(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialized, location])
}
