import styled, { css } from 'styled-components'
import { Utils } from '../utils/utils'

const IS_PROD = Utils.shouldShowProdIcon()

export const Fonts = styled.div`
  ${IS_PROD &&
  css`
    @font-face {
      font-family: 'Ghanachocolate';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/ghanachoco.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/ghanachoco.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum GarMaesGeur';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumGarMaesGeur.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumGarMaesGeur.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum GoDigANiGoGoDing';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumGoDigANiGoGoDing.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumGoDigANiGoGoDing.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum DaeGwangYuRi';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumDaeGwangYuRi.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumDaeGwangYuRi.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum NaMuJeongWeon';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumNaMuJeongWeon.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumNaMuJeongWeon.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum MongDor';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumMongDor.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumMongDor.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum MiRaeNaMu';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumMiRaeNaMu.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumMiRaeNaMu.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum BaReunJeongSin';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumBaReunJeongSin.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumBaReunJeongSin.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum BeomSomCe';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumBeomSomCe.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumBeomSomCe.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum SaRangHaeADeur';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumSaRangHaeADeur.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumSaRangHaeADeur.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum SeongSirCe';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumSeongSirCe.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/NanumSeongSirCe.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'EF_Diary';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/EF_Diary.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/EF_Diary.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type Special';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-SpecialItalic.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-SpecialItalic.woff')
          format('woff');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Base Type';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiBaseType-Regular.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiBaseType-Regular.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type Black Oblique';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-BlackOblique.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-BlackOblique.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type Special';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-Special.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-Special.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type White';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-White.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-White.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type White Oblique';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-WhiteOblique.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-WhiteOblique.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Base Type';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiBaseType-Bold.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiBaseType-Bold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-Black.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-Black.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MYYeongnamnu';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/MYYeongnamnu.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/MYYeongnamnu.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MYArirang';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/MYArirang-Medium.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/MYArirang-Medium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MYYeongnamnu';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/MYYeongnamnu.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/MYYeongnamnu.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MYHaemalgeunSangsang';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/MYHaemalgeunSangsang.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/MYHaemalgeunSangsang.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MuseumClassic';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicLight.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicLight.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MuseumClassic';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicMedium.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicMedium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MuseumClassic';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicBold.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicBold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'VITRO CORE OTF';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/VITRO-CORE-OTF.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/VITRO-CORE-OTF.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'TTF';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/VITRO-CORE-TTF.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/VITRO-CORE-TTF.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'VITRO PRIDE OTF';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/VITRO-PRIDE-OTF.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/VITRO-PRIDE-OTF.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'VITRO PRIDE TTF';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/VITRO-PRIDE-TTF.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/VITRO-PRIDE-TTF.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Heir of Light';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/HeirofLightBold.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/HeirofLightBold.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Heir of Light';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/HeirofLightRegular.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/HeirofLightRegular.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SANGJU Gyeongcheon Island';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/SANGJUGyeongcheonIsland.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/SANGJUGyeongcheonIsland.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SangSangRock';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/SangSangRock.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/SangSangRock.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SANGJU Gyeongcheon Island';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/SANGJUGyeongcheonIsland.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/SANGJUGyeongcheonIsland.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SANGJU Dajungdagam';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/SANGJUDajungdagam.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/SANGJUDajungdagam.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SANGJU Gotgam';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/SANGJUGotgam.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/SANGJUGotgam.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SEBANG Gothic';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/SEBANGGothic.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/SEBANGGothic.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SEBANG Gothic';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/SEBANGGothicBold.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/SEBANGGothicBold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Shilla_Culture';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/Shilla_CultureB-Bold.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/Shilla_CultureB-Bold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Shilla_Culture';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/Shilla_CultureM-Medium.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/Shilla_CultureM-Medium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'OK GUNG';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/OKGUNG.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/OKGUNG.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'OK CHAN';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/OKCHAN.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/OKCHAN.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Chosun Centennial';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/ChosunCentennial.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/ChosunCentennial.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'HappyGoheungB';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungB.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungB.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'HappyGoheungL';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungL.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungL.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'HappyGoheungM';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungM.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungM.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldDotum';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumMedium.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumMedium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldDotum';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumBold.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumBold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldBatang';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangLight.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangLight.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldDotum';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumLight.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumLight.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldBatang';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangMedium.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangMedium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldBatang';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangBold.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangBold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SF_HambakSnow';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/SF_HambakSnow.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/SF_HambakSnow.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SF_HambakSnow';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/SF_HambakSnow.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/SF_HambakSnow.woff')
          format('woff');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'ChosunilboNM';
      src: url('https://media.storyplay.com/StudioAssetsFile/fonts/ChosunilboNM.woff2')
          format('woff2'),
        url('https://media.storyplay.com/StudioAssetsFile/fonts/ChosunilboNM.woff')
          format('woff'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/ChosunilboNM.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/ChosunilboNM.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  `}

  ${!IS_PROD &&
  css`
    @font-face {
      font-family: 'Ghanachocolate';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/ghanachoco.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/ghanachoco.woff2')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum GarMaesGeur';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumGarMaesGeur.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumGarMaesGeur.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum GoDigANiGoGoDing';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumGoDigANiGoGoDing.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumGoDigANiGoGoDing.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum DaeGwangYuRi';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumDaeGwangYuRi.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumDaeGwangYuRi.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum NaMuJeongWeon';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumNaMuJeongWeon.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumNaMuJeongWeon.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum MongDor';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumMongDor.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumMongDor.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum MiRaeNaMu';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumMiRaeNaMu.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumMiRaeNaMu.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum BaReunJeongSin';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumBaReunJeongSin.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumBaReunJeongSin.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum BeomSomCe';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumBeomSomCe.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumBeomSomCe.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum SaRangHaeADeur';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumSaRangHaeADeur.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumSaRangHaeADeur.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Nanum SeongSirCe';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumSeongSirCe.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/NanumSeongSirCe.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'EF_Diary';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/EF_Diary.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/EF_Diary.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type Special';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-SpecialItalic.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-SpecialItalic.woff')
          format('woff');
      font-weight: normal;
      font-style: italic;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Base Type';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiBaseType-Regular.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiBaseType-Regular.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type Black Oblique';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-BlackOblique.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-BlackOblique.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type Special';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-Special.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-Special.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type White';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-White.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-White.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type White Oblique';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-WhiteOblique.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-WhiteOblique.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Base Type';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiBaseType-Bold.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiBaseType-Bold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Leferi Point Type';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-Black.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/LeferiPoint-Black.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MYYeongnamnu';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MYYeongnamnu.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MYYeongnamnu.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MYArirang';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MYArirang-Medium.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MYArirang-Medium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MYYeongnamnu';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MYYeongnamnu.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MYYeongnamnu.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MYHaemalgeunSangsang';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MYHaemalgeunSangsang.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MYHaemalgeunSangsang.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MuseumClassic';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicLight.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicLight.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MuseumClassic';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicMedium.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicMedium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'MuseumClassic';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicBold.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/MuseumClassicBold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'VITRO CORE OTF';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/VITRO-CORE-OTF.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/VITRO-CORE-OTF.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'TTF';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/VITRO-CORE-TTF.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/VITRO-CORE-TTF.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'VITRO PRIDE OTF';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/VITRO-PRIDE-OTF.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/VITRO-PRIDE-OTF.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'VITRO PRIDE TTF';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/VITRO-PRIDE-TTF.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/VITRO-PRIDE-TTF.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Heir of Light';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HeirofLightBold.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HeirofLightBold.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Heir of Light';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HeirofLightRegular.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HeirofLightRegular.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SANGJU Gyeongcheon Island';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SANGJUGyeongcheonIsland.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SANGJUGyeongcheonIsland.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SangSangRock';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SangSangRock.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SangSangRock.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SANGJU Gyeongcheon Island';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SANGJUGyeongcheonIsland.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SANGJUGyeongcheonIsland.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SANGJU Dajungdagam';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SANGJUDajungdagam.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SANGJUDajungdagam.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SANGJU Gotgam';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SANGJUGotgam.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SANGJUGotgam.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SEBANG Gothic';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SEBANGGothic.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SEBANGGothic.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SEBANG Gothic';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SEBANGGothicBold.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SEBANGGothicBold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Shilla_Culture';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/Shilla_CultureB-Bold.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/Shilla_CultureB-Bold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Shilla_Culture';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/Shilla_CultureM-Medium.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/Shilla_CultureM-Medium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'OK GUNG';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/OKGUNG.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/OKGUNG.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'OK CHAN';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/OKCHAN.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/OKCHAN.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'Chosun Centennial';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/ChosunCentennial.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/ChosunCentennial.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'HappyGoheungB';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungB.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungB.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'HappyGoheungL';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungL.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungL.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'HappyGoheungM';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungM.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/HappyGoheungM.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldDotum';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumMedium.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumMedium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldDotum';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumBold.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumBold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldBatang';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangLight.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangLight.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldDotum';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumLight.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldDotumLight.woff')
          format('woff');
      font-weight: 300;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldBatang';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangMedium.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangMedium.woff')
          format('woff');
      font-weight: 500;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'KoPubWorldBatang';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangBold.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/KoPubWorldBatangBold.woff')
          format('woff');
      font-weight: bold;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SF_HambakSnow';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SF_HambakSnow.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SF_HambakSnow.woff')
          format('woff');
      font-weight: 900;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'SF_HambakSnow';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SF_HambakSnow.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/SF_HambakSnow.woff')
          format('woff');
      font-weight: 800;
      font-style: normal;
      font-display: swap;
    }

    @font-face {
      font-family: 'ChosunilboNM';
      src: url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/ChosunilboNM.woff2')
          format('woff2'),
        url('https://dev-media.storyplay.com/StudioAssetsFile/fonts/ChosunilboNM.woff')
          format('woff');
      font-weight: normal;
      font-style: normal;
      font-display: swap;
    }
  `}
`
