import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline'
import { XIcon } from '@heroicons/react/solid'

import React from 'react'

interface IStudioCommonToastProps {
  closeToast?: () => void
  icon: 'SUCCESS' | 'WARNING'
  title?: string
  message: string
}

export const StudioCommonToast: React.FC<IStudioCommonToastProps> = (props) => {
  const { closeToast, icon, title, message } = props

  return (
    <div className="flex w-full flex-col items-center space-y-4 sm:items-end inset-0">
      {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
      <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              {icon === 'SUCCESS' && (
                <CheckCircleIcon
                  className="h-6 w-6 text-green-400"
                  aria-hidden="true"
                />
              )}
              {icon === 'WARNING' && (
                <ExclamationIcon
                  className="h-6 w-6"
                  style={{ color: '#DB2B00' }}
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {title && (
                <p className="text-sm font-medium text-gray-900">{title}</p>
              )}
              <p className="mt-1 text-sm text-gray-500">{message}</p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                onClick={() => {
                  closeToast?.()
                }}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
