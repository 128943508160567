import type { DOSTAlgorithm } from '@storyplay/common'
import { jointPointSymbols } from '@storyplay/common/lib/studio/aop'
import { hbAdvisor } from './hbAdvisorContainer'

class DOSTAlgorithmAdvisor {
  // @hbAdvisor(jointPointSymbols.DOSTAlgorithm.updateAlgorithmBlockGroup, 'pre')
  // async preUpdateAlgorithmBlockGroup(target: DOSTToBlock, toBlockNames: string[]) {}

  @hbAdvisor(jointPointSymbols.DOSTAlgorithm.updateAlgorithmBlockGroup, 'post')
  async postUpdateAlgorithmBlockGroup(
    target: DOSTAlgorithm,
    ret: any, // return
    botId: number,
    blockGroupId: number
  ) {
    // const hbStatement = target.st as HB_ALGORITHM_STATEMENT
    // const block = target.block as unknown as HbExtBlock
    // const hbExtensionData = hbStatement.hbExtensionData
    // if (hbStatement.id === null) {
    //   const data = {
    //     type: 'algorithm',
    //     groupId: blockGroupId,
    //     isShowAd: false,
    //   } as HbMessageObject
    //   const payload = {
    //     data,
    //     kind: 'chatbot',
    //     sheetId: block.hbExtensionData.sheetId,
    //   } as HbMessage<HbMessageObject>
    //   const res = await hbApiClient.message.create(payload)
    //   // return엔 Id가 무조건 존재함
    //   if (res.id) {
    //     target.settingIdWithHbExtensionData(res.id, res)
    //   }
    //   return
    // }
    // if (hbStatement.id) {
    //   await hbApiClient.message.update(hbStatement.id, {
    //     isShowAd: hbExtensionData?.data?.isShowAd ?? false,
    //     type: 'algorithm',
    //     groupId: blockGroupId,
    //   })
    // }
    // TODO? 받은 리턴값으로 업데이트를 해 주어야 하는데 아직까진 따로 할 필요가 없임. isShowAd의 변경은 고려가 안되어있기 때문에
  }
}

const dosttoalgorithmadvisor = new DOSTAlgorithmAdvisor()

export { dosttoalgorithmadvisor }
