import { registerServerErrorMessageMap } from '@odc/od-react-belt'
import { ErrorCode, i18nTextTranslationByClass } from '@storyplay/common'

export function registerServerErrorMessages() {
  const { trans } = i18nTextTranslationByClass()
  registerServerErrorMessageMap(
    ErrorCode.OTPTokenIsNotCorrect,
    trans('legacy_registerServerErrorMessages_incorrect_auth_number')
  )
}
