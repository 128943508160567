"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GNBMenuManager = void 0;
const core_1 = require("@storyplay/core");
const mobx_1 = require("mobx");
const _1 = require(".");
const consts_1 = require("../../../consts");
const contextmenu_1 = require("../contextmenu");
const entity_1 = require("../entity");
const finder_1 = require("../finder");
const interface_1 = require("../interface");
const studioUrls_1 = require("../studioUrls/studioUrls");
const IGNBModalManager_1 = require("./IGNBModalManager");
class GNBMenuManager {
    constructor(gnbStore) {
        // pc 사이즈의 GNB Menu 의 경우 headless UI 를 통해 상태관리가 되기 때문에
        // isOpen property 는 모바일 사이즈일 경우에만 사용합니다.
        this.isOpen = false;
        this.gnbStore = gnbStore;
        (0, mobx_1.makeObservable)(this, {
            isOpen: mobx_1.observable,
            setIsOpen: mobx_1.action,
            studioMenuTheme: mobx_1.computed,
            currentMenuItems: mobx_1.computed,
            languageMenuItems: mobx_1.computed,
            me: mobx_1.computed,
            currentNavMenus: mobx_1.computed,
        });
    }
    get me() {
        return this.gnbStore.rootStore.loginStore.user;
    }
    get di() {
        return this.gnbStore.rootStore.di;
    }
    get rootStore() {
        return this.gnbStore.rootStore;
    }
    get gnbModalManager() {
        return this.gnbStore.modalManager;
    }
    setIsOpen(isOpen) {
        this.isOpen = isOpen;
    }
    get studioMenuTheme() {
        if (this.gnbStore.currentLayout !== _1.GNBStyleLayout.EditChapter &&
            this.rootStore.isMobileSize) {
            return 'gray900';
        }
        return undefined;
    }
    get currentMenuItems() {
        const storyMenus = this.gnbStore.currentLayout === _1.GNBStyleLayout.EditChapter
            ? [this.storyMenus]
            : [];
        const storyNavMenus = (!!this.rootStore.storyEditing ||
            this.gnbStore.currentLayout === _1.GNBStyleLayout.Admin) &&
            this.gnbStore.currentLayout !== _1.GNBStyleLayout.EditChapter &&
            this.rootStore.isMobileSize
            ? [this.currentNavMenus]
            : [];
        const divider = storyNavMenus.length > 0
            ? [
                {
                    type: contextmenu_1.StudioMenuType.Separator,
                    backgroundColor: 'black',
                },
            ]
            : [];
        return {
            menus: [
                ...storyNavMenus,
                ...storyMenus,
                ...divider,
                this.helpMenus,
                this.profileMenus,
            ],
            theme: this.studioMenuTheme,
        };
    }
    get languageMenuItems() {
        var _a;
        const story = this.rootStore.storyEditing;
        return {
            menus: ((_a = story === null || story === void 0 ? void 0 : story.bundle) === null || _a === void 0 ? void 0 : _a.storyLanguages.map(language => {
                return {
                    type: contextmenu_1.StudioMenuType.TextWithSelectIcon,
                    text: language.display,
                    onClick: () => {
                        const currentStory = story.bundleStories.find(bundleStory => {
                            var _a;
                            return ((_a = bundleStory.language) === null || _a === void 0 ? void 0 : _a.code) === language.code;
                        });
                        if (currentStory) {
                            const currentURL = window.location.pathname;
                            // 정규식을 사용하여 스토리 아이디 부분 추출 및 변경
                            const newStoryId = currentStory.storyId;
                            const modifiedURL = currentURL.replace(/\/story\/\d+/, `/story/${newStoryId}`);
                            this.di.redirectToUrl(modifiedURL);
                        }
                    },
                    isSelected: story.languageCode === language.code,
                };
            })) || [],
            theme: this.studioMenuTheme,
        };
    }
    get currentNavMenus() {
        const storyEditing = this.rootStore.storyEditing;
        if (!!storyEditing) {
            return this.getStoryNavMenus(storyEditing.storyId);
        }
        return this.getAdminNavMenus();
    }
    getAdminNavMenus() {
        const { trans } = (0, core_1.i18nTextTranslationByClass)();
        const showGlobalFeature = this.gnbStore.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.GLOBAL);
        const subMenus = [
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: consts_1.SPImageName.IcnMenuStory,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_work_management'),
                href: studioUrls_1.StudioUrls.Admin.Story.Main,
            },
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: consts_1.SPImageName.IcnMenuAuthor,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_author_management'),
                href: studioUrls_1.StudioUrls.Admin.Author.Main,
            },
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: consts_1.SPImageName.IcnMenuStats,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_statistics_management'),
                href: studioUrls_1.StudioUrls.Admin.Stats.Main,
            },
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: consts_1.SPImageName.IcnReleaseVersion,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_version_management'),
                href: studioUrls_1.StudioUrls.Admin.Version.Main,
            },
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: consts_1.SPImageName.IcnChatbot,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_chatbot_management'),
                href: studioUrls_1.StudioUrls.Admin.Chatbot.Main,
            },
            // 아이콘 설정값만 참고해주세요
            // {
            //   type: StudioMenuType.LinkWithIcon,
            //   as: 'NavLink',
            //   icon: SPImageName.IcnMenuRecommend,
            //   iconTheme: StudioMenuElementTheme.IcnW6H6Gray700,
            //   text: '추천 섹션 관리',
            //   href: StudioUrls.Admin.Banner.Main,
            // },
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: consts_1.SPImageName.IcnMenuBanner,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_banner_setting'),
                href: studioUrls_1.StudioUrls.Admin.Banner.Main,
            },
            !!showGlobalFeature
                ? {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnMenuBanner,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '신배너 설정',
                    href: studioUrls_1.StudioUrls.Admin.BundleBanner.Main,
                }
                : null,
            !!showGlobalFeature
                ? {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnMenuMonthly,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: `월간인기작 설정`,
                    href: studioUrls_1.StudioUrls.Admin.MonthlyService.Main,
                }
                : null,
            !!showGlobalFeature
                ? {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnMenuBanner,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: `직광고 노출 설정`,
                    href: studioUrls_1.StudioUrls.Admin.DirectAd.Main,
                }
                : null,
            this.gnbStore.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.MANAGE_STORY_GAME_TABS)
                ? {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: consts_1.SPImageName.IcnMenuBanner,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: `스토리 게임 \n 지면 관리`,
                    href: studioUrls_1.StudioUrls.Admin.ManageStoryGameTabs.Main,
                }
                : null,
        ];
        const filteredSubMenus = subMenus.filter(sm => !!sm);
        return {
            type: contextmenu_1.StudioMenuType.Category,
            subMenus: filteredSubMenus,
        };
    }
    getStoryNavMenus(storyId) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
        const storyEditing = this.rootStore.storyEditing;
        const { trans } = (0, core_1.i18nTextTranslationByClass)();
        const showGlobalFeature = this.gnbStore.rootStore.di.isFeatureEnabled(interface_1.FEATURE_FLAG.GLOBAL);
        const isSameOrGreaterThanAdmin = (_a = this.me) === null || _a === void 0 ? void 0 : _a.isSameOrGreaterThanAdmin;
        const isOnStage = ((_b = this.rootStore.storyEditing) === null || _b === void 0 ? void 0 : _b.sectionType) !== core_1.StorySectionType.UGC;
        const subMenus = [
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: this.rootStore.isMobileSize
                    ? consts_1.SPImageName.IcnMobileNavStoryManage
                    : consts_1.SPImageName.IcnNavManageStory,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_work_management'),
                href: studioUrls_1.StudioUrls.Story.Detail.ManageStory.Main((_c = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _c !== void 0 ? _c : storyId),
                // Nav Link 는 href 를 통해 active 가 판단되는데,
                // 스토리게임일때 href 가 interactiveId 를 받아서 pathName 이 만들어지므로 활성화가 되지 않아 강제로 active 상태를 추가할 수 있도록 한다.
                forceActive: studioUrls_1.StudioUrls.Story.Detail.StoryGame.Edit(storyId) ===
                    this.gnbStore.pathname,
            },
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: this.rootStore.isMobileSize
                    ? consts_1.SPImageName.IcnMobileNavChapterList
                    : consts_1.SPImageName.IcnNavListChapter,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_episode_list'),
                href: studioUrls_1.StudioUrls.Story.Detail.Chapter.List((_d = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _d !== void 0 ? _d : storyId),
            },
            !!(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isWebNovel)
                ? null
                : {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnMobileNavChr
                        : consts_1.SPImageName.IcnNavChar,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: trans('legacy_ODSCharacterModal_characters'),
                    href: studioUrls_1.StudioUrls.Story.Detail.Character.List((_e = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _e !== void 0 ? _e : storyId),
                },
            !!(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isWebNovel)
                ? null
                : {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnMobileNavBackgroundManage
                        : consts_1.SPImageName.IcnNavBackground,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: trans('legacy_GNBMenuManager.ts_background_management'),
                    href: studioUrls_1.StudioUrls.Story.Detail.ManageBackground.List((_f = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _f !== void 0 ? _f : storyId),
                },
            !!(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isWebNovel)
                ? null
                : {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnMobileNavEndingManage
                        : consts_1.SPImageName.IcnNavManageEnding,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: trans('legacy_GNBMenuManager.ts_ending_management'),
                    href: studioUrls_1.StudioUrls.Story.Detail.ManageEnding.List((_g = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _g !== void 0 ? _g : storyId),
                },
            !!(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isWebNovel)
                ? null
                : {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnMobileNavProp
                        : consts_1.SPImageName.IcnNavProp,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: trans('legacy_PropList_property'),
                    href: studioUrls_1.StudioUrls.Story.Detail.Prop.List((_h = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _h !== void 0 ? _h : storyId),
                },
            !!(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isWebNovel)
                ? null
                : {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnMobileNavItemManage
                        : consts_1.SPImageName.IcnMenuItem,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: trans('legacy_GNBMenuManager.ts_item_management'),
                    href: studioUrls_1.StudioUrls.Story.Detail.ManageItem.List((_j = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _j !== void 0 ? _j : storyId),
                },
            !!(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isWebNovel)
                ? null
                : {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnMobileNavPlayerReport
                        : consts_1.SPImageName.IcnPlayerReport,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: trans('legacy_GNBMenuManager.ts_player_report'),
                    href: studioUrls_1.StudioUrls.Story.Detail.PlayerReport.List((_k = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _k !== void 0 ? _k : storyId),
                },
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: this.rootStore.isMobileSize
                    ? consts_1.SPImageName.IcnMobileNavMemberManage
                    : consts_1.SPImageName.IcnProfile2User,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_member_management'),
                href: studioUrls_1.StudioUrls.Story.Detail.ManageMember.List((_l = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _l !== void 0 ? _l : storyId),
            },
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: consts_1.SPImageName.IcnDashboardNav,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_dashboard'),
                href: studioUrls_1.StudioUrls.Story.Detail.Dashboard.Root((_m = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _m !== void 0 ? _m : storyId),
            },
            {
                type: contextmenu_1.StudioMenuType.LinkWithIcon,
                as: 'NavLink',
                icon: this.rootStore.isMobileSize
                    ? consts_1.SPImageName.IcnMobileNavEtc
                    : consts_1.SPImageName.IcnNavManageEtc,
                iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                text: trans('legacy_GNBMenuManager.ts_other_management'),
                href: studioUrls_1.StudioUrls.Story.Detail.Etc.Main((_o = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _o !== void 0 ? _o : storyId),
            },
            !!showGlobalFeature && isSameOrGreaterThanAdmin && isOnStage
                ? {
                    type: contextmenu_1.StudioMenuType.LinkWithIcon,
                    as: 'NavLink',
                    // TODO: 모바일 아이콘 전달받으면 아이콘 교체
                    icon: this.rootStore.isMobileSize
                        ? consts_1.SPImageName.IcnMobileNavPrice
                        : consts_1.SPImageName.IcnNavManagePrice,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.IcnW6H6Gray700,
                    text: '가격 설정',
                    href: studioUrls_1.StudioUrls.Story.Detail.ManagePrice.Edit((_p = storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.interactiveId) !== null && _p !== void 0 ? _p : storyId),
                }
                : null,
        ];
        const filteredSubMenus = subMenus.filter(sm => !!sm);
        return {
            type: contextmenu_1.StudioMenuType.Category,
            subMenus: filteredSubMenus,
        };
    }
    // 채팅방 에디터 페이지에서 표시되는 메뉴들
    get storyMenus() {
        var _a;
        const storyEditing = this.rootStore.storyEditing;
        const chapterEditing = this.rootStore.chapterEditing;
        const { trans } = (0, core_1.i18nTextTranslationByClass)();
        const menusByPathname = (() => {
            var _a;
            const result = [];
            if (!!((_a = this.me) === null || _a === void 0 ? void 0 : _a.canExportStory) ||
                this.rootStore.di.allowStudioExportExcelToAnyOne) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_export_excel'),
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnExportInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        if (!!chapterEditing && !(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isExporting)) {
                            if (!!(storyEditing === null || storyEditing === void 0 ? void 0 : storyEditing.isAutoExportEnabled)) {
                                storyEditing.resetAutoExport();
                                this.rootStore.showMessage(trans('legacy_GNBMenuManager.ts_autosave_off')); // this.rootStore.showMessage('자동저장을 껐습니다.')
                            }
                        }
                        this.gnbModalManager.openModal({
                            type: IGNBModalManager_1.GNBModal.ExportToSheet,
                        });
                    },
                });
            }
            if (this.gnbStore.currentLayout === _1.GNBStyleLayout.EditChapter &&
                this.rootStore.isMobileSize) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_publish'),
                    textTheme: contextmenu_1.StudioMenuElementTheme.TextPrimary500,
                    icon: consts_1.SPImageName.IcnPublishInGNB,
                    iconTheme: contextmenu_1.StudioMenuElementTheme.TextPrimary500,
                    onClick: async () => this.gnbStore.applyChapter(),
                });
            }
            return result;
        })();
        const adminMenus = [
            {
                type: contextmenu_1.StudioMenuType.TextWithIcon,
                text: '등장인물 교체하기',
                onClick: () => {
                    this.gnbModalManager.openModal({
                        type: IGNBModalManager_1.GNBModal.ReplaceCharactersInChapter,
                    });
                },
            },
        ];
        return {
            type: contextmenu_1.StudioMenuType.Category,
            text: this.rootStore.isMobileSize
                ? undefined
                : trans('legacy_GNBMenuManager.ts_work'),
            subMenus: [
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_episode_info'),
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnChapterInfoInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        this.gnbModalManager.openModal({
                            type: IGNBModalManager_1.GNBModal.ChapterInfo,
                        });
                    },
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_test_play_parameter'),
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnTestPlayInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        this.gnbStore.onStartingBlockForTestOfChapterEditing();
                    },
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    // Todo: 추후 바꾸기 기능 구현되면 "찾기/바꾸기"로 변경되어야함
                    text: trans('legacy_GNBMenuManager.ts_search'),
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnSearchInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        var _a;
                        const chapterEditingBlockEditor = (_a = this.rootStore.chapterEditing) === null || _a === void 0 ? void 0 : _a.blockEditor;
                        // menu 의 close event 보다 onClick 이벤트가 늦게 호출되도록 하여도,
                        // 가끔 focus, select 메서드보다 더 뒤에 늦게 실행되는 이벤트가 있기 때문에
                        // 검색 인풋의 focus 가 되지 않는 문제 해결을 위해
                        // 실행 시간을 늦추었습니다.
                        setTimeout(() => {
                            var _a, _b, _c;
                            if ((chapterEditingBlockEditor === null || chapterEditingBlockEditor === void 0 ? void 0 : chapterEditingBlockEditor.finder.mode) === finder_1.FinderMode.Find) {
                                (_a = chapterEditingBlockEditor.finderInputRef) === null || _a === void 0 ? void 0 : _a.focus();
                                (_c = (_b = chapterEditingBlockEditor.finderInputRef) === null || _b === void 0 ? void 0 : _b.select) === null || _c === void 0 ? void 0 : _c.call(_b);
                            }
                            else {
                                chapterEditingBlockEditor === null || chapterEditingBlockEditor === void 0 ? void 0 : chapterEditingBlockEditor.finder.setMode(finder_1.FinderMode.Find);
                            }
                        }, 150);
                    },
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_inspection'),
                    icon: createIconOfTextWIthIcon(consts_1.SPImageName.IcnInspectInGNB, this.rootStore.isMobileSize),
                    onClick: () => {
                        chapterEditing === null || chapterEditing === void 0 ? void 0 : chapterEditing.onSubmit(hasIssue => {
                            if (!hasIssue) {
                                this.rootStore.showMessage(trans('legacy_GNBMenuManager.ts_no_inspection_errors_found')); // this.rootStore.showMessage('발견된 검수 오류가 없습니다.')
                            }
                        });
                    },
                },
                ...menusByPathname,
                ...(((_a = this.me) === null || _a === void 0 ? void 0 : _a.isSameOrGreaterThanAdmin) ? adminMenus : []),
                {
                    type: contextmenu_1.StudioMenuType.Separator,
                },
            ],
        };
    }
    get helpMenus() {
        var _a;
        const { trans } = (0, core_1.i18nTextTranslationByClass)();
        const menusByPathname = (() => {
            const result = [];
            if (this.gnbStore.currentLayout === _1.GNBStyleLayout.EditChapter) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_keyboard_shortcut'),
                    onClick: () => {
                        this.gnbModalManager.openModal({
                            type: IGNBModalManager_1.GNBModal.ShortcutInfo,
                        });
                        this.rootStore.studioTutorialStore
                            .markUserStudioTutorialProgress(entity_1.GA4_EVENT_NAME.SHOW_KEYBOARD_SHORTCUT)
                            .then();
                    },
                });
            }
            return result;
        })();
        const isMobileSizeAndNotLayoutEditChapter = this.gnbStore.currentLayout !== _1.GNBStyleLayout.EditChapter &&
            this.rootStore.isMobileSize;
        return {
            type: contextmenu_1.StudioMenuType.Category,
            text: trans('legacy_GNBMenuManager.ts_help'),
            subMenus: [
                ...menusByPathname,
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_move_to_help_center'),
                    rightElement: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : consts_1.SPImageName.IcnPublishInGNB,
                    rightElementTheme: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : contextmenu_1.StudioMenuElementTheme.TextGray500,
                    onClick: () => window.open('https://thingsflow.gitbook.io/studio-guide', '_blank', 'noopener'),
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: 'Q&A',
                    rightElement: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : consts_1.SPImageName.IcnPublishInGNB,
                    rightElementTheme: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : contextmenu_1.StudioMenuElementTheme.TextGray500,
                    onClick: () => window.open('https://game.naver.com/lounge/storyplay/board/13', '_blank', 'noopener'),
                },
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_new_feature'),
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.ReleaseVersion),
                    rightElement: (_a = this.di.studioVersion) !== null && _a !== void 0 ? _a : '-',
                    rightElementTheme: isMobileSizeAndNotLayoutEditChapter
                        ? undefined
                        : contextmenu_1.StudioMenuElementTheme.TextGray500,
                },
                {
                    type: contextmenu_1.StudioMenuType.Separator,
                    backgroundColor: isMobileSizeAndNotLayoutEditChapter
                        ? 'black'
                        : undefined,
                },
            ],
        };
    }
    get profileMenus() {
        const { trans } = (0, core_1.i18nTextTranslationByClass)();
        const menusByPathname = (() => {
            var _a, _b, _c;
            const result = [];
            const isAdminPage = this.gnbStore.currentLayout === _1.GNBStyleLayout.Admin;
            if (!!((_a = this.me) === null || _a === void 0 ? void 0 : _a.isSameOrGreaterThanAdmin) && !isAdminPage) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_move_to_admin_page'),
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.Admin.Root),
                });
            }
            if (!!((_b = this.me) === null || _b === void 0 ? void 0 : _b.isSameOrGreaterThanAdmin) && isAdminPage) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_move_to_studio_page'),
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.Story.List),
                });
            }
            if (!((_c = this.me) === null || _c === void 0 ? void 0 : _c.isSSOLogin)) {
                result.push({
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_password_change'),
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.ChangePassword),
                });
            }
            return result;
        })();
        return {
            type: contextmenu_1.StudioMenuType.Category,
            text: trans('legacy_GNBMenuManager.ts_profile'),
            subMenus: [
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_edit_author_info'),
                    onClick: () => this.di.redirectToUrl(studioUrls_1.StudioUrls.EditAuthorInfo),
                },
                ...menusByPathname,
                {
                    type: contextmenu_1.StudioMenuType.TextWithIcon,
                    text: trans('legacy_GNBMenuManager.ts_logout'),
                    onClick: () => this.rootStore.loginStore.logout(),
                },
            ],
        };
    }
}
exports.GNBMenuManager = GNBMenuManager;
// TextWithIcon 의 경우 모바일 사이즈가 아닐 경우
// 왼쪽 아이콘이 표시되면 안 됩니다.
function createIconOfTextWIthIcon(icon, isMobile) {
    return isMobile ? icon : undefined;
}
