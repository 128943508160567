import React, {
  createContext,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'

export const OverlayContext = createContext<{
  mount: (id: string, element: ReactNode) => void
  unmount: (id: string) => void
} | null>(null)

export function OverlayProvider({ children }: PropsWithChildren<{}>) {
  const [overlayById, setOverlayById] = useState<Map<string, ReactNode>>(
    new Map()
  )

  const mount = useCallback((id: string, element: ReactNode) => {
    setOverlayById((prevOverlayById) => {
      const cloned = new Map(prevOverlayById)

      cloned.set(id, element)
      return cloned
    })
  }, [])

  const unmount = useCallback((id: string) => {
    setOverlayById((prevOverlayById) => {
      const cloned = new Map(prevOverlayById)
      cloned.delete(id)
      return cloned
    })
  }, [])

  const context = useMemo(
    () => ({
      mount,
      unmount,
    }),
    [mount, unmount]
  )

  return (
    <OverlayContext.Provider value={context}>
      {children}
      {[...overlayById.entries()].map(([id, element]) => (
        <Fragment key={id}>{element}</Fragment>
      ))}
    </OverlayContext.Provider>
  )
}
