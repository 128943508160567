import { ButtonPaddingSize, SPImageName } from '@storyplay/common'
import React from 'react'
import { toast } from 'react-toastify'
import { SPImage } from '../../../../assets/SPImages'
import { BEButton, BEButtonTheme } from '../blockEditor/button/BEButton'

// import lottieComplete from '../../../../assets/img/studio/lottie/tut-complete.json'

interface ITutorialCompleteToastProps {
  closeToast?: () => void
  title: string
  bodyMessage: string
  nextButtonName: string
  onClick: () => void
}

export const TutorialCompleteToast: React.FC<ITutorialCompleteToastProps> = (
  props
) => {
  const { title, bodyMessage, nextButtonName, onClick } = props

  const handleClick = () => {
    onClick()
    toast.dismiss('tutorial')
  }

  return (
    <div className="rounded-2xl flex flex-col space-y-6 as:py-6 as:px-10 w-full">
      <div className={'flex space-x-4'}>
        <SPImage imageName={SPImageName.IcnCheckInTutorialCheckModal} />
        <div className={'flex flex-col space-y-2'}>
          <h1 className={'text-xl text-gray-900 font-bold'}>{title}</h1>
          <p className={'text-sm text-gray-600 font-bold'}>{bodyMessage}</p>
        </div>
      </div>
      <div className={'w-full flex justify-end'}>
        <BEButton
          text={nextButtonName}
          onClick={handleClick}
          theme={BEButtonTheme.Filled}
          isSizingByPadding
          buttonPaddingSize={ButtonPaddingSize.PX12PY2}
        />
      </div>
    </div>
  )
}
