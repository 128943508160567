"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StudioValidationType = void 0;
/**
 * StudioValidationType 과 ErrorCode 가 혼용되는 경우를 대비하여,
 * 절대적으로 아래의 규칙을 지키도록 합니다.
 *
 * - StudioValidationType 은 300_000 이상의 숫자를 사용합니다.
 */
var StudioValidationType;
(function (StudioValidationType) {
    StudioValidationType[StudioValidationType["BlockHasNoFinish"] = 310000] = "BlockHasNoFinish";
    StudioValidationType[StudioValidationType["ChoiceHasNoToBlock"] = 310001] = "ChoiceHasNoToBlock";
    StudioValidationType[StudioValidationType["ChoiceHasInvalidBlockId"] = 310002] = "ChoiceHasInvalidBlockId";
    StudioValidationType[StudioValidationType["ToBlockHasNoBlock"] = 310003] = "ToBlockHasNoBlock";
    StudioValidationType[StudioValidationType["ToBlockHasInvalidBlockId"] = 310004] = "ToBlockHasInvalidBlockId";
    StudioValidationType[StudioValidationType["ConditionBlockHasNoToBlock"] = 310100] = "ConditionBlockHasNoToBlock";
    StudioValidationType[StudioValidationType["ConditionBlockHasInvalidBlockId"] = 310101] = "ConditionBlockHasInvalidBlockId";
    StudioValidationType[StudioValidationType["FinalEndingHasNoImage"] = 310110] = "FinalEndingHasNoImage";
    StudioValidationType[StudioValidationType["NoBackgroundImage"] = 310111] = "NoBackgroundImage";
    StudioValidationType[StudioValidationType["DefaultEndingDoesNotExist"] = 310112] = "DefaultEndingDoesNotExist";
    StudioValidationType[StudioValidationType["BGMOffIsRequired"] = 310113] = "BGMOffIsRequired";
    StudioValidationType[StudioValidationType["UnhandledScripterError"] = 310114] = "UnhandledScripterError";
    StudioValidationType[StudioValidationType["FinalEndingHasNoDesc"] = 310115] = "FinalEndingHasNoDesc";
    StudioValidationType[StudioValidationType["EndingIdDuplicate"] = 310116] = "EndingIdDuplicate";
    StudioValidationType[StudioValidationType["EndingNameDuplicate"] = 310117] = "EndingNameDuplicate";
    StudioValidationType[StudioValidationType["ChoiceNameDuplicate"] = 310118] = "ChoiceNameDuplicate";
    StudioValidationType[StudioValidationType["BlockNameShouldBeTrimmed"] = 310119] = "BlockNameShouldBeTrimmed";
    StudioValidationType[StudioValidationType["UserInputChoiceNoImage"] = 310120] = "UserInputChoiceNoImage";
    StudioValidationType[StudioValidationType["UserInputChoiceNoLeftText"] = 310121] = "UserInputChoiceNoLeftText";
    // PropNameShouldNotIncludeColon = 310_122, // 속성명은 콜론(:)이 포함되지 않아야 한다.
    // ItemNameShouldNotIncludeColon = 310_123, // 아이템명은 콜론(:)이 포함되지 않아야 한다.
    StudioValidationType[StudioValidationType["ChrNameMaxLenNotMet"] = 310124] = "ChrNameMaxLenNotMet";
    // ItemNameMaxLenNotMet = 310_125,
    StudioValidationType[StudioValidationType["ImageChoiceNoImage"] = 310126] = "ImageChoiceNoImage";
    StudioValidationType[StudioValidationType["RemovedEndingHasPlayDataSoCannotApply"] = 310127] = "RemovedEndingHasPlayDataSoCannotApply";
    StudioValidationType[StudioValidationType["UnhandledServerTransQueryError"] = 310128] = "UnhandledServerTransQueryError";
    StudioValidationType[StudioValidationType["CallRemoteScriptHasNoProvider"] = 310129] = "CallRemoteScriptHasNoProvider";
    StudioValidationType[StudioValidationType["FinishRemoteScriptHasNoToBlock"] = 310130] = "FinishRemoteScriptHasNoToBlock";
    StudioValidationType[StudioValidationType["CallRemoteScriptHasNoParam"] = 310131] = "CallRemoteScriptHasNoParam";
    StudioValidationType[StudioValidationType["StatsChoiceHasNoDisplayName"] = 310132] = "StatsChoiceHasNoDisplayName";
    // 조건으로 분기(Condition)안에서 속성명, 아이템명에 Comma(,)가 들어가 있으면 scripter 에서 오류가 발생합니다.
    StudioValidationType[StudioValidationType["PropNameShouldNotIncludeCommaInCondition"] = 310133] = "PropNameShouldNotIncludeCommaInCondition";
    StudioValidationType[StudioValidationType["ItemNameShouldNotIncludeCommaInCondition"] = 310134] = "ItemNameShouldNotIncludeCommaInCondition";
    StudioValidationType[StudioValidationType["PropNameShouldNotBeEmptyValue"] = 310135] = "PropNameShouldNotBeEmptyValue";
    StudioValidationType[StudioValidationType["ItemNameShouldNotBeEmptyValue"] = 310136] = "ItemNameShouldNotBeEmptyValue";
    StudioValidationType[StudioValidationType["AchievementNameShouldNotBeEmptyValue"] = 310137] = "AchievementNameShouldNotBeEmptyValue";
    StudioValidationType[StudioValidationType["RemoteScriptHasNoFallbackScript"] = 310138] = "RemoteScriptHasNoFallbackScript";
    StudioValidationType[StudioValidationType["ChatGPTCallRemoteScriptHasNoPrompt"] = 310139] = "ChatGPTCallRemoteScriptHasNoPrompt";
    StudioValidationType[StudioValidationType["ChatGPTCallRemoteScriptHasNoScriptForm"] = 310140] = "ChatGPTCallRemoteScriptHasNoScriptForm";
    StudioValidationType[StudioValidationType["ChatGPTCallRemoteScriptHasNoBackground"] = 310141] = "ChatGPTCallRemoteScriptHasNoBackground";
    StudioValidationType[StudioValidationType["ChatGPTCallRemoteScriptScriptFormInvalidSourceLine"] = 310142] = "ChatGPTCallRemoteScriptScriptFormInvalidSourceLine";
    StudioValidationType[StudioValidationType["ChatGPTCallRemoteScriptScriptFormInvalidMessage"] = 310143] = "ChatGPTCallRemoteScriptScriptFormInvalidMessage";
    StudioValidationType[StudioValidationType["ChatGPTCallRemoteScriptScriptFormHasNoStatementType"] = 310144] = "ChatGPTCallRemoteScriptScriptFormHasNoStatementType";
    StudioValidationType[StudioValidationType["ChatGPTCallRemoteScriptScriptFormTalkAndThinkHasNoChr"] = 310145] = "ChatGPTCallRemoteScriptScriptFormTalkAndThinkHasNoChr";
    StudioValidationType[StudioValidationType["StringPropHasNoValueInCondition"] = 310146] = "StringPropHasNoValueInCondition";
    StudioValidationType[StudioValidationType["StringSavePropHasNoValue"] = 310147] = "StringSavePropHasNoValue";
    StudioValidationType[StudioValidationType["StringPropHasNoValueInChoice"] = 310148] = "StringPropHasNoValueInChoice";
    StudioValidationType[StudioValidationType["StringPropInvalidConditionType"] = 310149] = "StringPropInvalidConditionType";
    StudioValidationType[StudioValidationType["FullScreenEffectOnHasInvalidFileLink"] = 310150] = "FullScreenEffectOnHasInvalidFileLink";
    StudioValidationType[StudioValidationType["FullScreenEffectOnHasInvalidImageWidth"] = 310151] = "FullScreenEffectOnHasInvalidImageWidth";
    StudioValidationType[StudioValidationType["FullScreenEffectOnHasInvalidImageHeight"] = 310152] = "FullScreenEffectOnHasInvalidImageHeight";
    StudioValidationType[StudioValidationType["FullScreenEffectOnHasInvalidFileType"] = 310153] = "FullScreenEffectOnHasInvalidFileType";
    StudioValidationType[StudioValidationType["FullScreenEffectOnHasInvalidScreenFillMethod"] = 310154] = "FullScreenEffectOnHasInvalidScreenFillMethod";
    StudioValidationType[StudioValidationType["FullScreenEffectOnHasInvalidTransitionStyle"] = 310155] = "FullScreenEffectOnHasInvalidTransitionStyle";
    StudioValidationType[StudioValidationType["FullScreenEffectOnHasInvalidAnimationDuration"] = 310156] = "FullScreenEffectOnHasInvalidAnimationDuration";
    StudioValidationType[StudioValidationType["FullScreenEffectOffHasInvalidTransitionStyle"] = 310157] = "FullScreenEffectOffHasInvalidTransitionStyle";
    StudioValidationType[StudioValidationType["FullScreenEffectOffHasInvalidTransitionDuration"] = 310158] = "FullScreenEffectOffHasInvalidTransitionDuration";
    StudioValidationType[StudioValidationType["FullWidthImageFullScreenOptionHasInvalidImageLink"] = 310159] = "FullWidthImageFullScreenOptionHasInvalidImageLink";
    StudioValidationType[StudioValidationType["FullWidthImageFullScreenOptionHasInvalidWidth"] = 310160] = "FullWidthImageFullScreenOptionHasInvalidWidth";
    StudioValidationType[StudioValidationType["FullWidthImageFullScreenOptionHasInvalidHeight"] = 310161] = "FullWidthImageFullScreenOptionHasInvalidHeight";
    StudioValidationType[StudioValidationType["FullWidthImageFullScreenOptionHasInvalidDuration"] = 310162] = "FullWidthImageFullScreenOptionHasInvalidDuration";
    StudioValidationType[StudioValidationType["FullWidthImageFullScreenOptionHasInvalidShowTransition"] = 310163] = "FullWidthImageFullScreenOptionHasInvalidShowTransition";
    StudioValidationType[StudioValidationType["FullWidthImageFullScreenOptionHasInvalidHideTransition"] = 310164] = "FullWidthImageFullScreenOptionHasInvalidHideTransition";
    StudioValidationType[StudioValidationType["FullWidthImageFullScreenOptionHasInvalidFillMethod"] = 310165] = "FullWidthImageFullScreenOptionHasInvalidFillMethod";
    StudioValidationType[StudioValidationType["ItemNameShouldNotIncludeComma"] = 310166] = "ItemNameShouldNotIncludeComma";
    StudioValidationType[StudioValidationType["PropNameShouldNotIncludeComma"] = 310167] = "PropNameShouldNotIncludeComma";
    // Server-Side Issues, > 330_000,
    StudioValidationType[StudioValidationType["EndingExistsButOtherChapter"] = 330000] = "EndingExistsButOtherChapter";
    StudioValidationType[StudioValidationType["ExceptionDuringPreQuery"] = 330001] = "ExceptionDuringPreQuery";
    StudioValidationType[StudioValidationType["ExceptionDuringTransQuery"] = 330002] = "ExceptionDuringTransQuery";
    StudioValidationType[StudioValidationType["UnknownExceptionDuringApply"] = 330003] = "UnknownExceptionDuringApply";
    StudioValidationType[StudioValidationType["ExceptionDuringGenerateEpub"] = 330004] = "ExceptionDuringGenerateEpub";
})(StudioValidationType = exports.StudioValidationType || (exports.StudioValidationType = {}));
