import { EndBlockType } from '@storyplay/common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { SPImage } from '../../../../assets/SPImages'
import { useDetectOutsideClick } from '../../../../components/base/useDetectOutsideClick'
import { IStudioDropDownOption } from './StudioDropDown'

export interface IStudioDropDownRoute {
  routeName: string
  options: IStudioDropDownOption[]
}

interface IStudioDropDownForRoute {
  options: IStudioDropDownRoute[]
  onClick: (value: EndBlockType) => void
  width: number
  open: boolean
  onClose: () => void
}

export const StudioDropDownForRoute: React.FC<IStudioDropDownForRoute> =
  observer((props) => {
    const { options, onClick, width, open, onClose } = props

    const dropdownRef = React.useRef<HTMLDivElement | null>(null)

    useDetectOutsideClick(onClose, dropdownRef, open)

    const handleClick = (endingBlockType: EndBlockType) => {
      onClick(endingBlockType)
      onClose()
    }

    return (
      <Container
        className="absolute flex flex-col bg-white shadow-dropdown top-full left-0"
        style={{ width: '100%', maxWidth: width, zIndex: 3 }}
        ref={dropdownRef}
      >
        {options.map((item) => {
          return (
            <RouteContainer
              key={item.routeName}
              className="flex flex-col w-full"
            >
              <RouteName className="w-full text-left">
                {item.routeName}
              </RouteName>
              {item.options.map((option) => {
                return (
                  <Option
                    key={option.value}
                    className="flex w-full items-center hover:bg-gray-100"
                    onClick={(e) => {
                      handleClick(option.value as EndBlockType)
                      // e.preventDefault()
                      // e.stopPropagation()
                      // return false
                    }}
                  >
                    {!!option.imgSrc && (
                      <img
                        src={option.imgSrc}
                        alt={option.label}
                        className={'relative -top-px'}
                        style={{ width: 16, height: 16, marginRight: 8 }}
                      />
                    )}
                    {!!option.imageName && (
                      <SPImage
                        imageName={option.imageName}
                        className={'relative -top-px as:mr-2 w-4 h-4'}
                      />
                    )}
                    <Text className="text-black">{option.label}</Text>
                  </Option>
                )
              })}
            </RouteContainer>
          )
        })}
        {/*{options.map((item) => {*/}
        {/*  return (*/}
        {/*  )*/}
        {/*})}*/}
      </Container>
    )
  })

const Container = styled.div`
  padding: 16px;
  border-radius: 12px;
`

const RouteContainer = styled.div`
  & + & {
    margin-top: 12px;
  }
`

const RouteName = styled.span`
  font-size: 11px;
  line-height: 14px;
  color: #9b9b9b;
`

const Option = styled.div`
  height: 36px;
  padding-left: 12px;
  border-radius: 6px;
`

const Text = styled.span`
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.3px;
`
