import React from 'react'
import { ReactStudioDI } from './ReactStudioDI'
import { AppOptions } from '../../../utils/AppOptions'
import { HbStudioRootStore, StudioRootStore } from '@storyplay/common'

const di = new ReactStudioDI()
export const rootStore = AppOptions.USE_HELLOBOT
  ? new HbStudioRootStore(di)
  : new StudioRootStore(di)
export const RootStoreContext = React.createContext(rootStore)
let gHistory: any = null

export function setHistory(history: any) {
  gHistory = history
}

export const useStudioStore = <STORE extends StudioRootStore>(): STORE => {
  di.redirectToUrl = (url: string) => {
    gHistory?.push(url)
  }
  return React.useContext(RootStoreContext) as STORE
}
