import { StudioValidationSeverity } from '@storyplay/common'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface IErrorMessageProps {
  errorMessage: string
  severity?: StudioValidationSeverity | 'gray'
  iconStyle?: React.CSSProperties
  isNoWrap?: boolean // noWrap 이면 ... 처리하기 위함
  className?: string
  isShowOnlyErrorIcon?: boolean
  renderTextFollowingErrorMessage?: () => React.ReactNode
  textSize?: 'sm' | 'xs'
}

export const ErrorMessage: React.FC<IErrorMessageProps> = observer((props) => {
  const {
    errorMessage,
    severity = StudioValidationSeverity.Error,
    iconStyle,
    isNoWrap = false,
    className = '',
    isShowOnlyErrorIcon = false,
    renderTextFollowingErrorMessage,
    textSize = 'sm',
  } = props

  const fillColor = {
    [StudioValidationSeverity.Error]: '#DB2B00',
    [StudioValidationSeverity.Warning]: '#F1C40F',
    [StudioValidationSeverity.Recommend]: '#3498DB',
    gray: '#A4A7AD',
  }[severity]

  const textClass = {
    [StudioValidationSeverity.Error]: 'text-danger-primary500',
    [StudioValidationSeverity.Warning]: 'text-yellow-500',
    [StudioValidationSeverity.Recommend]: 'text-blue-500',
    gray: 'text-gray-500',
  }[severity]

  const errorIcon = React.useMemo(() => {
    return (
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={'relative -top-px'}
        style={{
          marginRight: 8,
          minWidth: 12,
          minHeight: 12,
          maxWidth: 12,
          maxHeight: 12,
          fontSize: 12,
          ...iconStyle,
        }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM5 6V3H7V6H5ZM5 9V7H7V9H5Z"
          fill={fillColor}
        />
      </svg>
    )
  }, [iconStyle, fillColor])

  if (isShowOnlyErrorIcon) {
    return errorIcon
  }

  return (
    // 해당 컴포넌트를 사용하는 곳에서 아래 tag 들을 감싸도록 하자.
    // 해당 컴포넌트에서 div 로 감싸면 의도하지 않은 것들이 보이는 듯하다...
    <>
      {errorIcon}
      <span
        className={classNames(textClass, className, {
          'overflow-hidden overflow-ellipsis whitespace-nowrap': !!isNoWrap,
          'break-all': !isNoWrap,
          'text-sm': textSize === 'sm',
          'text-xs': textSize === 'xs',
        })}
      >
        {errorMessage}
        {renderTextFollowingErrorMessage?.()}
      </span>
    </>
  )
})
