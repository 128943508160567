import React from "react"
import { Helmet } from "react-helmet-async"
import { useServiceType } from "../hooks/useServiceType"


export const StudioTitleHelmet = () => {

  const serviceType = useServiceType()

  const title = serviceType === 'hb' ? '헬로우봇 스튜디오' : '스토리플레이 스튜디오'
  const description = serviceType === 'hb' ? 'Create Your Own Chatbot in Hellobot.' : 'Create Your Own Path in StoryPlay.'

  return (
    <Helmet>
      <meta></meta>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  )

}