import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useServiceType } from '../hooks/useServiceType'
import { Utils } from '../utils/utils'

interface IMetaTagsProps {}

const getFaviconUrl = (isProd: boolean, serviceType: string) => {
  if (serviceType === 'hb') {
    return `${process.env.PUBLIC_URL}/favicon_hellobot.ico`
  }
  if (isProd) {
    return `${process.env.PUBLIC_URL}/favicon.ico`
  }
  return `${process.env.PUBLIC_URL}/favicon_storyplay_dev.ico`
}

export const MetaTags: React.FC<IMetaTagsProps> = (props) => {
  const isProd = Utils.shouldShowProdIcon()
  const serviceType = useServiceType()
  const faviconUrl = getFaviconUrl(isProd, serviceType)

  return (
    <Helmet>
      <link rel="icon" href={faviconUrl} />
      <link rel="apple-touch-icon" href={faviconUrl} />
    </Helmet>
  )
}
