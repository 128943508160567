import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

export const TutorialAllFinishToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }

  .Toastify__toast {
    min-width: 330px;
    min-height: 194px;
    background-color: white;
    border-radius: 16px;
  }

  .Toastify__toast-body {
  }

  .Toastify__progress-bar {
  }
`
