import { SPImageName } from '@storyplay/common'
import React from 'react'
import AppIconBeta from './img/studio/appicon_beta.png'
import AppIconDev from './img/studio/appicon_dev.png'
import ImgBook from './img/studio/book.png'
import IcnEditFile from './img/studio/icn_edit_file.png'
import ImgMenuNovel from './img/studio/icn_menu_novel.png'
import IcnStarS from './img/studio/icn_star_s.png'
import ImgUserProfileDefault from './img/studio/img_userprofile_default.png'
import ImgUserProfileDefault2 from './img/studio/img_userprofile_default2.png'
import ImgLogoStudioBeta from './img/studio/Logo_beta.png'
import ImgLogoStudio from './img/studio/Logo_spstudio2.png'
import ImgRedWarning from './img/studio/RedWarning.png'
import IcnBlocklink from './img/studio/icn_hb_blocklink.png'
import IcnHeart from './img/studio/IconHeart.png'
import IcnGrayHeart from './img/studio/IconGrayHeart.png'
import ImagePreviewStoryGameMo from './img/studio/image-preview-storygame-mo.png'
import ImagePreviewStoryGamePc from './img/studio/image-preview-storygame-pc.png'
import ImagePreviewChatMo from './img/studio/image-preview-chat-mo.png'
import ImagePreviewChatMoHero from './img/studio/image-preview-chat-mo-hero.png'
import ImagePreviewChatProfile from './img/studio/image-preview-chat-profile.png'
import ImageBgPreviewChatMo from './img/studio/image-bg-preview-chat-mo.png'
import ImageBgPreviewStorygameMo from './img/studio/image-bg-preview-storygame-mo.png'
import ImageBgPreviewStorygamePc from './img/studio/image-bg-preview-storygame-pc.png'
import ImageEffectPreviewChat from './img/studio/image-effect-preview-chat.png'
import ImageEffectPreviewMobile from './img/studio/image-effect-preview-mobile.png'
import ImageEffectPreviewPC from './img/studio/image-effect-preview-pc.png'

import { ReactComponent as IcnAlignCenter } from './img/studio/svgs/icn_alignCenter.svg'
import { ReactComponent as IcnAlignLeft } from './img/studio/svgs/icn_alignLeft.svg'
import { ReactComponent as IcnAlignRight } from './img/studio/svgs/icn_alignRight.svg'
import { ReactComponent as IcnArrowRight } from './img/studio/svgs/icn_arrow_right.svg'
import { ReactComponent as IcnAttack } from './img/studio/svgs/icn_attack.svg'
import { ReactComponent as IcnBack } from './img/studio/svgs/icn_back.svg'
import { ReactComponent as IcnBackground } from './img/studio/svgs/icn_background.svg'
import { ReactComponent as IcnBlockAdd } from './img/studio/svgs/icn_block_add.svg'
import { ReactComponent as IcnBold } from './img/studio/svgs/icn_bold.svg'
import { ReactComponent as IcnBook } from './img/studio/svgs/icn_book.svg'
import { ReactComponent as IcnBookSquare } from './img/studio/svgs/icn_book_square.svg'
import { ReactComponent as IcnBtnClose } from './img/studio/svgs/icn_btn_close.svg'
import {
  ReactComponent as IcnBtnEditLeftSide,
  ReactComponent as IcnBtnEdit,
} from './img/studio/svgs/icn_btn_edit.svg'
import { ReactComponent as IcnBtnExpandImageRightSide } from './img/studio/svgs/icn_btn_expand_image_right_side.svg'
import { ReactComponent as IcnBtnHistory } from './img/studio/svgs/icn_btn_history.svg'
import { ReactComponent as IcnKeyboard } from './img/studio/svgs/icn_btn_keyboard.svg'
import { ReactComponent as IcnBtnMessage } from './img/studio/svgs/icn_btn_message.svg'
import { ReactComponent as IcnBtnNavClose } from './img/studio/svgs/icn_btn_nav_close.svg'
import { ReactComponent as IcnBtnNavInfo } from './img/studio/svgs/icn_btn_nav_info.svg'
import { ReactComponent as IcnBtnNavInfo2 } from './img/studio/svgs/icn_btn_nav_info2.svg'
import { ReactComponent as IcnBtnNavMore } from './img/studio/svgs/icn_btn_nav_more.svg'
import { ReactComponent as IcnBtnNavPrev } from './img/studio/svgs/icn_btn_nav_prev.svg'
import { ReactComponent as IcnBtnNewCoverImgMiddleOf } from './img/studio/svgs/icn_btn_new_cover_middle_of.svg'
import { ReactComponent as IcnBtnNewImgAtStart } from './img/studio/svgs/icn_btn_new_img_start.svg'
import { ReactComponent as IcnBtnRemoveAtEnd } from './img/studio/svgs/icn_btn_remove_end.svg'
import { ReactComponent as IcnBtnSaveChapter } from './img/studio/svgs/icn_btn_save_chapter.svg'
import { ReactComponent as IcnSmallEdit } from './img/studio/svgs/icn_btn_small_edit.svg'
import { ReactComponent as IcnBtnTtsHasLeftBorder } from './img/studio/svgs/icn_btn_tts_has_left_border.svg'
import { ReactComponent as IcnChangeArea } from './img/studio/svgs/icn_change_area.svg'
import { ReactComponent as IcnChangeImage } from './img/studio/svgs/icn_change_image.svg'
import { ReactComponent as IcnChapterEndingBadge } from './img/studio/svgs/icn_chapter_ending_badge.svg'
import { ReactComponent as IcnChapterInfoInGNB } from './img/studio/svgs/icn_chapter_info_in_gnb.svg'
import { ReactComponent as IcnCharDefault } from './img/studio/svgs/icn_char_default.svg'
import { ReactComponent as IcnBtnCheck } from './img/studio/svgs/icn_check.svg'
import { ReactComponent as IcnCheckBoxWithTitle } from './img/studio/svgs/icn_check_box_with_title.svg'
import { ReactComponent as IcnCheckInCircle } from './img/studio/svgs/icn_check_in_circle.svg'
import { ReactComponent as IcnCheckInTutorialCheckModal } from './img/studio/svgs/icn_check_in_tutorial_check_modal.svg'
import { ReactComponent as IcnChrMenu } from './img/studio/svgs/icn_chr_menu.svg'
import { ReactComponent as IcnChrome } from './img/studio/svgs/icn_chrome.svg'
import { ReactComponent as IcnCircleWarning } from './img/studio/svgs/icn_circle_warning.svg'
import { ReactComponent as IcnCloseInDarkCircle } from './img/studio/svgs/icn_close_in_dark_circle.svg'
import { ReactComponent as IcnComposeIfBlock } from './img/studio/svgs/icn_compose_ifblock.svg'
import { ReactComponent as IcnContestCrown } from './img/studio/svgs/icn_contest_crown.svg'
import { ReactComponent as IcnCopy } from './img/studio/svgs/icn_copy.svg'
import { ReactComponent as IcnCopyWithTitle } from './img/studio/svgs/icn_copy_with_title.svg'
import { ReactComponent as IcnCrop } from './img/studio/svgs/icn_crop.svg'
import { ReactComponent as IcnCropHasLeftBorder } from './img/studio/svgs/icn_crop_has_left_border.svg'
import { ReactComponent as IcnCrown } from './img/studio/svgs/icn_crown.svg'
import { ReactComponent as IcnDB } from './img/studio/svgs/icn_db.svg'
import { ReactComponent as IcnDel } from './img/studio/svgs/icn_del.svg'
import { ReactComponent as IcnDelS2 } from './img/studio/svgs/icn_del_s2.svg'
import { ReactComponent as IcnDeleteWarning } from './img/studio/svgs/icn_delete_warning.svg'
import { ReactComponent as IcnEditS } from './img/studio/svgs/icn_edit_s.svg'
import { ReactComponent as IcnEmptyCode } from './img/studio/svgs/icn_empty_code.svg'
import { ReactComponent as IcnEventGrape2 } from './img/studio/svgs/icn_event-grape2.svg'
import { ReactComponent as IcnEventStar } from './img/studio/svgs/icn_event_star.svg'
import { ReactComponent as IcnExportInGNB } from './img/studio/svgs/icn_export_in_gnb.svg'
import { ReactComponent as IcnEye } from './img/studio/svgs/icn_eye.svg'
import { ReactComponent as IcnFinalEndingBadge } from './img/studio/svgs/icn_final_ending_badge.svg'
import { ReactComponent as IcnFlashMessageWarning } from './img/studio/svgs/icn_flash_message_warning.svg'
import { ReactComponent as IcnInfoShare } from './img/studio/svgs/icn_info_share.svg'
import { ReactComponent as IcnInformationCircle } from './img/studio/svgs/icn_information_circle.svg'
import { ReactComponent as IcnInspect } from './img/studio/svgs/icn_inspect.svg'
import { ReactComponent as IcnInspectInGNB } from './img/studio/svgs/icn_inspect_in_gnb.svg'
import { ReactComponent as IcnItalic } from './img/studio/svgs/icn_italic.svg'
import { ReactComponent as IcnLightWhite } from './img/studio/svgs/icn_light-white.svg'
import { ReactComponent as IcnLinearBox } from './img/studio/svgs/icn_linear_box.svg'
import { ReactComponent as IcnLink } from './img/studio/svgs/icn_link.svg'
import { ReactComponent as IcnMenuAuthor } from './img/studio/svgs/icn_menu_author.svg'
import { ReactComponent as IcnMenuButton } from './img/studio/svgs/icn_menu_button.svg'
import { ReactComponent as IcnMenuGallery } from './img/studio/svgs/icn_menu_gallery.svg'
import { ReactComponent as IcnMenuGalleryEnding } from './img/studio/svgs/icn_menu_gallery_ending.svg'
import { ReactComponent as IcnMenuGalleryInfo } from './img/studio/svgs/icn_menu_gallery_Info.svg'
import { ReactComponent as IcnMenuGalleryItem } from './img/studio/svgs/icn_menu_gallery_item.svg'
import { ReactComponent as IcnMenuGalleryProp } from './img/studio/svgs/icn_menu_gallery_prop.svg'
import { ReactComponent as IcnMenuItem } from './img/studio/svgs/icn_menu_item.svg'
import { ReactComponent as IcnMenuMore } from './img/studio/svgs/icn_menu_more.svg'
import { ReactComponent as IcnMenuStats } from './img/studio/svgs/icn_menu_stats.svg'
import { ReactComponent as IcnMenuStory } from './img/studio/svgs/icn_menu_story.svg'
import { ReactComponent as IcnMicrophone } from './img/studio/svgs/icn_microphone.svg'
import { ReactComponent as IcnNavClose } from './img/studio/svgs/icn_nav_close.svg'
import { ReactComponent as IcnNavPlay } from './img/studio/svgs/icn_nav_play.svg'
import { ReactComponent as IcnNoImg } from './img/studio/svgs/icn_noImg.svg'
import { ReactComponent as IcnPlayerReport2 } from './img/studio/svgs/icn_play_report2.svg'
import { ReactComponent as IcnPlayerReport } from './img/studio/svgs/icn_player_report.svg'
import { ReactComponent as IcnPlayerReportWithTitle } from './img/studio/svgs/icn_player_report_with_title.svg'
import { ReactComponent as IcnPlusS } from './img/studio/svgs/icn_plus.svg'
import { ReactComponent as IcnProfile2User } from './img/studio/svgs/icn_profile_2user.svg'
import { ReactComponent as IcnProp } from './img/studio/svgs/icn_prop.svg'
import { ReactComponent as IcnPropSquare } from './img/studio/svgs/icn_prop_square.svg'
import { ReactComponent as IcnPublish } from './img/studio/svgs/icn_publish.svg'
import { ReactComponent as IcnPublishInGNB } from './img/studio/svgs/icn_publish_in_gnb.svg'
import { ReactComponent as IcnPublishUndo } from './img/studio/svgs/icn_publish_undo.svg'
import { ReactComponent as IcnQna } from './img/studio/svgs/icn_qna.svg'
import { ReactComponent as IcnRedoWithBorder } from './img/studio/svgs/icn_redo_with_border.svg'
import { ReactComponent as IcnRefresh } from './img/studio/svgs/icn_refresh.svg'
import { ReactComponent as IcnReleaseVersion } from './img/studio/svgs/icn_release_version.svg'
import { ReactComponent as IcnReleaseNote } from './img/studio/svgs/icn_releasenote.svg'
import { ReactComponent as IcnReleaseNoteNew } from './img/studio/svgs/icn_releasenote_new.svg'
import { ReactComponent as IcnSave } from './img/studio/svgs/icn_save.svg'
import { ReactComponent as IcnSearchInGNB } from './img/studio/svgs/icn_search_in_gnb.svg'
import { ReactComponent as IcnSheets } from './img/studio/svgs/icn_sheet.svg'
import { ReactComponent as IcnStatsInPaper } from './img/studio/svgs/icn_stats_in_paper.svg'
import { ReactComponent as IcnStoryCoverMini } from './img/studio/svgs/icn_story_cover_mini.svg'
import { ReactComponent as IcnStoryCoverWithLeftDim } from './img/studio/svgs/icn_story_cover_with_left_dim.svg'
import { ReactComponent as IcnStudioError } from './img/studio/svgs/icn_studio_error.svg'
import { ReactComponent as IcnStudioLogoInTutorial } from './img/studio/svgs/icn_studio_logo_in_tutorial.svg'
import { ReactComponent as IcnTestPlayInGNB } from './img/studio/svgs/icn_test_play_in_gnb.svg'
import { ReactComponent as IcnTts } from './img/studio/svgs/icn_tts.svg'
import { ReactComponent as IcnTutorial } from './img/studio/svgs/icn_tutorial.svg'
import { ReactComponent as IcnTutorialCompleted } from './img/studio/svgs/icn_tutorial_completed.svg'
import { ReactComponent as IcnTutorialCompletedText } from './img/studio/svgs/icn_tutorial_completed_text.svg'
import { ReactComponent as IcnTutorialEx } from './img/studio/svgs/icn_tutorial_ex.svg'
import { ReactComponent as IcnTutorialFunctionOpen } from './img/studio/svgs/icn_tutorial_function_open.svg'
import { ReactComponent as IcnTutorialLock } from './img/studio/svgs/icn_tutorial_lock.svg'
import { ReactComponent as IcnTutorialText } from './img/studio/svgs/icn_tutorial_text.svg'
import { ReactComponent as IcnUnderline } from './img/studio/svgs/icn_underline.svg'
import { ReactComponent as IcnUndoWithBorder } from './img/studio/svgs/icn_undo_with_border.svg'
import { ReactComponent as IcnUpload } from './img/studio/svgs/icn_upload.svg'
import { ReactComponent as IcnUploadImage } from './img/studio/svgs/icn_upload_img.svg'
import { ReactComponent as IcnUploadVideo } from './img/studio/svgs/icn_upload_video.svg'
import { ReactComponent as IcnUserProfileDefault2 } from './img/studio/svgs/icn_user_profile_default2.svg'
import { ReactComponent as IcnUserEdit } from './img/studio/svgs/icn_useredit.svg'
import { ReactComponent as IcnValidationCheck } from './img/studio/svgs/icn_validation_check.svg'
import { ReactComponent as IcnValidationError } from './img/studio/svgs/icn_validation_error.svg'
import { ReactComponent as IcnVibration } from './img/studio/svgs/icn_vibration.svg'
import { ReactComponent as StoryCover } from './img/studio/svgs/story_cover.svg'
import { ReactComponent as StoryPlayHeaderLogoOnMobile } from './img/studio/svgs/storyplay_header_logo_on_mobile.svg'
import { ReactComponent as IcnTutorialDone } from './img/studio/svgs/icn_tutorial_done.svg'
import { ReactComponent as IcnChatBotInCircle } from './img/studio/svgs/icn_chatbot_in_circle.svg'
import { ReactComponent as IcnValidationErrorGray } from './img/studio/svgs/icn_validation_error_gray.svg'
import { ReactComponent as IcnBtnQuestion } from './img/studio/svgs/icn_btn_question.svg'
import { ReactComponent as IcnBtnExecuteConditionLeftBorder } from './img/studio/svgs/icn_btn_execute_condition_left_border.svg'
import { ReactComponent as IcnBtnExecuteConditionLeftBorderFirst } from './img/studio/svgs/icn_btn_execute_condition_left_border_first.svg'
import { ReactComponent as IcnChatbot } from './img/studio/svgs/icn_chatbot.svg'
import { ReactComponent as IcnHasDraftText } from './img/studio/svgs/icn_has_draft_text.svg'
import { ReactComponent as IcnDashboardNav } from './img/studio/svgs/icn_dashboard_nav.svg'
import { ReactComponent as IcnChartNav } from './img/studio/svgs/icn_chart_nav.svg'
import { ReactComponent as IcnPresentationChartNav } from './img/studio/svgs/icn_presentation_chart_nav.svg'
import { ReactComponent as IcnRankingChartNav } from './img/studio/svgs/icn_ranking_chart_nav.svg'
import { ReactComponent as IcnChevronRight } from './img/studio/svgs/icn_chevron_right.svg'
import { ReactComponent as IcnChevronLeft } from './img/studio/svgs/icn_chevron_left.svg'
import { ReactComponent as IcnMoreS } from './img/studio/svgs/icn_more_s.svg'
import { ReactComponent as IcnBtnCalendar } from './img/studio/svgs/icn_btn_calendar.svg'
import { ReactComponent as IcnArrowNextLight } from './img/studio/svgs/icn_arrow_next_light.svg'
import { ReactComponent as IcnArrowBeforeLight } from './img/studio/svgs/icn_arrow_before_light.svg'
import { ReactComponent as IcnArrowNarrowShortDown } from './img/studio/svgs/icn_arrow_narrow_short_down.svg'
import { ReactComponent as IcnArrowNarrowShortUp } from './img/studio/svgs/icn_arrow_narrow_short_up.svg'
import { ReactComponent as IcnBtnNavQuestion } from './img/studio/svgs/icn_btn_nav_question.svg'
import { ReactComponent as IcnArrowNarrowShortLine } from './img/studio/svgs/icn_arrow_narrow_short_line.svg'
import { ReactComponent as IcnCommentBest } from './img/studio/svgs/icn_comment_best.svg'
import { ReactComponent as IcnCommentLike } from './img/studio/svgs/icn_comment_like.svg'
import { ReactComponent as IcnCommentOfComment } from './img/studio/svgs/icn_comment_of_comment.svg'
import { ReactComponent as IcnArrowDownGray } from './img/studio/svgs/icn_arrow_down_gray.svg'
import { ReactComponent as IcnStatusBadgeZero } from './img/studio/svgs/icn_status_badge_zero.svg'
import { ReactComponent as IcnStatusBadgeUp } from './img/studio/svgs/icn_status_badge_up.svg'
import { ReactComponent as IcnSelectBoxArrowDown } from './img/studio/svgs/icn_selectbox_arrow_down.svg'
import { ReactComponent as IcnCrownYellow } from './img/studio/svgs/icn_crown_yellow.svg'
import { ReactComponent as IcnMenuNovel } from './img/studio/svgs/icn_menu_novel.svg'
import { ReactComponent as IcnBtnArrowRight } from './img/studio/svgs/icn_btn_arrow_right.svg'
import ContestLogo from './img/studio/contestLogo.jpg'
import { ReactComponent as IcnMobileNavStoryManage } from './img/studio/svgs/icn_mobile_nav_story_manage.svg'
import { ReactComponent as IcnMobileNavChapterList } from './img/studio/svgs/icn_mobile_nav_chapter_list.svg'
import { ReactComponent as IcnMobileNavChr } from './img/studio/svgs/icn_mobile_nav_chr.svg'
import { ReactComponent as IcnMobileNavBackgroundManage } from './img/studio/svgs/icn_mobile_nav_background_manage.svg'
import { ReactComponent as IcnMobileNavEndingManage } from './img/studio/svgs/icn_mobile_nav_ending_manage.svg'
import { ReactComponent as IcnMobileNavProp } from './img/studio/svgs/icn_mobile_nav_prop.svg'
import { ReactComponent as IcnMobileNavItemManage } from './img/studio/svgs/icn_mobile_nav_item_manage.svg'
import { ReactComponent as IcnMobileNavPlayerReport } from './img/studio/svgs/icn_mobile_nav_player_report.svg'
import { ReactComponent as IcnMobileNavMemberManage } from './img/studio/svgs/icn_mobile_nav_member_manage.svg'
import { ReactComponent as IcnMobileNavEtc } from './img/studio/svgs/icn_mobile_nav_etc.svg'
import { ReactComponent as IcnNavManageStory } from './img/studio/svgs/icn_nav_manage_story.svg'
import { ReactComponent as IcnNavListChapter } from './img/studio/svgs/icn_nav_list_chapter.svg'
import { ReactComponent as IcnNavChar } from './img/studio/svgs/icn_nav_char.svg'
import { ReactComponent as IcnNavBackground } from './img/studio/svgs/icn_nav_background.svg'
import { ReactComponent as IcnNavManageEnding } from './img/studio/svgs/icn_nav_manage_ending.svg'
import { ReactComponent as IcnNavProp } from './img/studio/svgs/icn_nav_prop.svg'
import { ReactComponent as IcnNavManageEtc } from './img/studio/svgs/icn_nav_manage_etc.svg'
import { ReactComponent as IcnMenuEffect } from './img/studio/svgs/icn_menu_effect.svg'
import { ReactComponent as IcnUploadImage2 } from './img/studio/svgs/icn_upload_img2.svg'
import { ReactComponent as IcnMagic } from './img/studio/svgs/icn_magic.svg'
import { ReactComponent as IcnMenuPause } from './img/studio/svgs/icn_menu_pause.svg'
import { ReactComponent as IcnBtnFloatingAdd } from './img/studio/svgs/icn_btn_floating_add.svg'
import { ReactComponent as IcnBtnEditPen } from './img/studio/svgs/icn_btn_edit_pen.svg'
import { ReactComponent as IcnExpand } from './img/studio/svgs/icn_expand.svg'
import { ReactComponent as IcnDeleteTrash } from './img/studio/svgs/icn_delete_trash.svg'
import { ReactComponent as IcnBtnAddImgInCircle } from './img/studio/svgs/icn_btn_add_img_in_circle.svg'
import { ReactComponent as IcnBtnDeleteInCircle } from './img/studio/svgs/icn_btn_delete_in_circle.svg'
import { ReactComponent as IcnLlamama } from './img/hellobotStudio/svgs/icn_llamama.svg'
import { ReactComponent as IcnHbAlgorithmBlock } from './img/hellobotStudio/svgs/icn_algorithm_block.svg'
import { ReactComponent as IcnHbAttribute } from './img/hellobotStudio/svgs/icn_attribute.svg'
import { ReactComponent as IcnHbBeginConversation } from './img/hellobotStudio/svgs/icn_begin_conversation.svg'
import { ReactComponent as IcnHbChatbotBlock } from './img/hellobotStudio/svgs/icn_chatbot_block.svg'
import { ReactComponent as IcnHbDashboard } from './img/hellobotStudio/svgs/icn_dashboard.svg'
import { ReactComponent as IcnHbLibrary } from './img/hellobotStudio/svgs/icn_library.svg'
import { ReactComponent as IcnHbMatching } from './img/hellobotStudio/svgs/icn_matching.svg'
import { ReactComponent as IcnHbReportGroup } from './img/hellobotStudio/svgs/icn_report_group.svg'
import { ReactComponent as IcnHbSkillList } from './img/hellobotStudio/svgs/icn_skill_list.svg'
import { ReactComponent as IcnHbStatistics } from './img/hellobotStudio/svgs/icn_statistics.svg'
import { ReactComponent as IcnHbTeachConversation } from './img/hellobotStudio/svgs/icn_teach_conversation.svg'
import { ReactComponent as IcnHbUser } from './img/hellobotStudio/svgs/icn_user.svg'
import { ReactComponent as IcnHbIfBlock } from './img/hellobotStudio/svgs/icn_if_block.svg'

import { ReactComponent as LogoHellobotStudio } from './img/hellobotStudio/svgs/hellobot_studio_logo.svg'
import { ReactComponent as AppIconHellobotDev } from './img/studio/svgs/icn_logo_hellobot_dev.svg'
import { ReactComponent as IcnSideMenuLight } from './img/studio/svgs/icn_side_menu_light.svg'
import { ReactComponent as IcnEditLight } from './img/studio/svgs/icn_edit_light.svg'
import { ReactComponent as IcnGnbMenuLight } from './img/studio/svgs/icn_gnb_menu_light.svg'
import { ReactComponent as IcnStoryLine } from './img/studio/svgs/icn_story_line.svg'
import { ReactComponent as IcnInteractiveLine } from './img/studio/svgs/icn_interactive_line.svg'
import { ReactComponent as IcnEpubUpload } from './img/studio/svgs/icn_epub_upload.svg'
import { ReactComponent as IcnEpubUploaded } from './img/studio/svgs/icn_epub_uploaded.svg'
import { ReactComponent as IcnModalCloseLight } from './img/studio/svgs/icn_modal_close_light.svg'
import { ReactComponent as IcnWriteWithPlus } from './img/studio/svgs/icn_write_with_plus.svg'
import { ReactComponent as IcnEpubUploadInBottomSheet } from './img/studio/svgs/icn_epub_upload_in_bottom_sheet.svg'
import { ReactComponent as IcnCheckInBottomSheet } from './img/studio/svgs/icn_check_in_bottom_sheet.svg'
import { ReactComponent as IcnCheckOn } from './img/studio/svgs/icn_check_on.svg'
import { ReactComponent as IcnCheckOff } from './img/studio/svgs/icn_check_off.svg'
import { ReactComponent as IcnIfBlockSayChatbot } from './img/studio/svgs/icn_ifblock_saychatbot.svg'
import { ReactComponent as IcnFlowChartLink } from './img/studio/svgs/icn_flowchart_link.svg'
import { ReactComponent as BackButtonIcon } from './img/studio/svgs/icn_btn_back_not_padding.svg'
import { ReactComponent as OtherBlockLink } from './img/studio/svgs/icn_other_block_link.svg'
import { ReactComponent as IcnBtnHoverDel } from './img/studio/svgs/icn_btn_hover_del.svg'
import { ReactComponent as IcnLineDrawing } from './img/studio/svgs/icn_line_drawing.svg'
import { ReactComponent as IcnHeartInInput } from './img/studio/svgs/icn_heart.svg'
import { ReactComponent as IcnCloseLight } from './img/studio/svgs/icn_close_light.svg'
import { ReactComponent as IcnTriangle } from './img/studio/svgs/icn_triangle.svg'
import { ReactComponent as IcnAddWhite } from './img/studio/svgs/icn_add_white.svg'
import { ReactComponent as IcnBtnSecondaryAddSmall } from './img/hellobotStudio/svgs/icn_btn_secondary_add_small.svg'
import { ReactComponent as IcnStar } from './img/hellobotStudio/svgs/icn_star.svg'
import IconPlatformHb from './img/hellobotStudio/icon_platform_hellobot.png'
import IconHellobotPreviewLogo from './img/hellobotStudio/img_hellobotlogo_kr.png'
import { ReactComponent as IcnSelected } from './img/hellobotStudio/svgs/icn_selected.svg'
import { ReactComponent as IcnPhotoEdit } from './img/hellobotStudio/svgs/icn_photo_edit.svg'
import { ReactComponent as IcnCheckBoxWithTitleHb } from './img/hellobotStudio/svgs/icn_check_box_with_title_hb.svg'
import IcnHbProfile from './img/hellobotStudio/profile_large.png'
import { ReactComponent as IcnAttributeOn } from './img/hellobotStudio/svgs/icn_attribute_on.svg'
import { ReactComponent as IcnNewChatBot } from './img/hellobotStudio/svgs/icn_new_chatbot.svg'
import { ReactComponent as IcnOutlineExport } from './img/hellobotStudio/svgs/icn_outline_export.svg'
import { ReactComponent as IcnVisibility } from './img/hellobotStudio/svgs/icn_visibility.svg'
import { ReactComponent as IcnWorld } from './img/studio/svgs/icn_world.svg'
import { ReactComponent as IcnDevice } from './img/studio/svgs/icn_device.svg'
import { ReactComponent as IcnWatch } from './img/studio/svgs/icn_watch.svg'
import { ReactComponent as RibbonTestWait } from './img/hellobotStudio/svgs/ribbon_test_wait.svg'
import { ReactComponent as RibbonTesting } from './img/hellobotStudio/svgs/ribbon_testing.svg'
import { ReactComponent as IcnInspection } from './img/hellobotStudio/svgs/icn_inspection.svg'
import { ReactComponent as IcnInspectionWhite } from './img/hellobotStudio/svgs/icn_inspection_white.svg'
import { ReactComponent as IcnBlueCheck } from './img/hellobotStudio/svgs/icn_blue_check.svg'
import { ReactComponent as HellobotTextLogo } from './img/hellobotStudio/svgs/hellobot_text_logo.svg'
import { ReactComponent as MobileBackCircle } from './img/hellobotStudio/svgs/mobile_back_circle.svg'
import { ReactComponent as IcnFresh } from './img/hellobotStudio/svgs/icn_refresh.svg'
import { ReactComponent as IcnArrowLeft } from './img/hellobotStudio/svgs/icn_arrow_left.svg'
import { ReactComponent as IcnArrowLeftWhite } from './img/hellobotStudio/svgs/icn_arrow_left_white.svg'
import IcnFlashMessageWarningOrange from './img/hellobotStudio/icn_flashmessage_warning_orange.png'
import { ReactComponent as IcnLang } from './img/studio/svgs/icn_lang.svg'
import { ReactComponent as IcnNavManagePrice } from './img/studio/svgs/icn_menu_admin9.svg'
import { ReactComponent as IcnMobileNavPrice } from './img/studio/svgs/icn_menu_price.svg'
import { ReactComponent as IcnAddBtn } from './img/studio/svgs/icon_add_btn.svg'
import { ReactComponent as IcnAddBtnGray } from './img/studio/svgs/icn_add_btn_gray.svg'
import { ReactComponent as IcnMenuBanner } from './img/studio/svgs/icn_menu_banner.svg'
import { ReactComponent as IcnMenuRecommend } from './img/studio/svgs/icn_menu_recommend.svg'
import { ReactComponent as IcnMenuMonthly } from './img/studio/svgs/icn_menu_monthly.svg'
import { ReactComponent as IcnAdult } from './img/studio/svgs/icn_adult.svg'
import { ReactComponent as ImgPropsPrimary } from './img/studio/svgs/img_props_primary.svg'

import WebNovelContestImg from './img/studio/img_contest_main.jpg'
import { ReactComponent as IcnSolidAdd } from './img/hellobotStudio/svgs/icn_solid_add.svg'
import { ReactComponent as QwertyKeyboard } from './img/hellobotStudio/svgs/img_keyboard.svg'
import { ReactComponent as NumberKeyboard } from './img/hellobotStudio/svgs/img_keyborard_number.svg'
import TarotCard from './img/hellobotStudio/img_tarotcard.png'

import { ReactComponent as IcnBtnTalkPreviewHasLeftBorder } from './img/studio/svgs/icn_btn_talk_preview_has_left_border.svg'
import { ReactComponent as IcnProfileNothing } from './img/studio/svgs/icn_profile_nothing.svg'

import { ReactComponent as IcnBtnPreviewArrowLeft } from './img/studio/svgs/icn_btn_preview_arrow_left.svg'
import { ReactComponent as IcnBtnPreviewArrowRight } from './img/studio/svgs/icn_btn_preview_arrow_right.svg'
import { ReactComponent as IcnBtnMoveArrowLeft } from './img/studio/svgs/icn_btn_move_arrow_left.svg'
import { ReactComponent as IcnBtnMoveArrowRight } from './img/studio/svgs/icn_btn_move_arrow_right.svg'
import { ReactComponent as IcnVideo } from './img/studio/svgs/icn_video.svg'
import { ReactComponent as IcnUploadPortraitImage } from './img/studio/svgs/icn_upload_portrait_image.svg'
import { ReactComponent as IcnCropHasLeftBorderWithRightBorderRadius } from './img/studio/svgs/icn_crop_has_left_border_with_right_border_radius.svg'
import { ReactComponent as IcnArrowLeftLine } from './img/studio/svgs/icn_arrow_left_line.svg'
import { ReactComponent as IcnArrowRightLine } from './img/studio/svgs/icn_arrow_right_line.svg'
import { ReactComponent as IcnCheckedLine } from './img/studio/svgs/icn_checked_line.svg'
import { ReactComponent as IcnCommonMore } from './img/studio/svgs/icn_common_more.svg'
import { ReactComponent as IcnCommonDetect } from './img/studio/svgs/icn_common_detect.svg'
import { ReactComponent as IcnCommonLanguage } from './img/studio/svgs/icn-common-language.svg'
import { ReactComponent as IcnCommonRegion } from './img/studio/svgs/icn-common-region.svg'
import { ReactComponent as IcnCommonDesktop } from './img/studio/svgs/icn-common-desktop.svg'
import { ReactComponent as IcnCommonTablet } from './img/studio/svgs/icn-common-tablet.svg'
import { ReactComponent as IcnCommonMobile } from './img/studio/svgs/icn-common-mobile.svg'
import { ReactComponent as IcnCommonImage } from './img/studio/svgs/icn-common-image.svg'
import { ReactComponent as IcnCommonDrag } from './img/studio/svgs/icn-common-drag.svg'

export enum SPImageType {
  ImageFile = 'ImageFile', // src 로 등록이 가능한 모든 Image 파일
  Svg = 'Svg',
}

export interface ISPImageInfoResponse {
  imageType: SPImageType
  width?: number
  height?: number
}

export interface ISPImageFileInfoResponse extends ISPImageInfoResponse {
  imageType: SPImageType.ImageFile
  image: string
}

export interface ISPSvgInfoResponse extends ISPImageInfoResponse {
  imageType: SPImageType.Svg
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

export type SPImageInfoResponse = ISPImageFileInfoResponse | ISPSvgInfoResponse

export const SPImageInfo: { [key: string]: SPImageInfoResponse } = {
  [SPImageName.Keyboard]: {
    imageType: SPImageType.Svg,
    image: IcnKeyboard,
    width: 28,
    height: 28,
  },
  [SPImageName.Book]: {
    imageType: SPImageType.ImageFile,
    image: ImgBook,
    width: 28,
    height: 28,
  },
  [SPImageName.MenuNovel]: {
    imageType: SPImageType.ImageFile,
    image: ImgMenuNovel,
    width: 28,
    height: 28,
  },
  [SPImageName.StoryCover]: {
    imageType: SPImageType.Svg,
    image: StoryCover,
    width: 28,
    height: 28,
  },
  [SPImageName.IcnEditS]: {
    imageType: SPImageType.Svg,
    image: IcnEditS,
    width: 20,
    height: 20,
  },
  [SPImageName.IcnPlus]: {
    imageType: SPImageType.Svg,
    image: IcnPlusS,
    width: 20,
    height: 20,
  },
  [SPImageName.BtnMessage]: {
    imageType: SPImageType.Svg,
    image: IcnBtnMessage,
    width: 16,
    height: 15,
  },
  [SPImageName.ArrowRight]: {
    imageType: SPImageType.Svg,
    image: IcnArrowRight,
    width: 16,
    height: 16,
  },
  [SPImageName.UserProfileDefaultWithRing]: {
    imageType: SPImageType.Svg,
    image: IcnUserProfileDefault2,
    width: 110,
    height: 110,
  },
  [SPImageName.UserProfileDefault]: {
    imageType: SPImageType.ImageFile,
    image: ImgUserProfileDefault,
  },
  [SPImageName.Upload]: {
    imageType: SPImageType.Svg,
    image: IcnUpload,
    width: 16,
    height: 16,
  },
  [SPImageName.UploadVideo]: {
    imageType: SPImageType.Svg,
    image: IcnUploadVideo,
    width: 40,
    height: 40,
  },
  [SPImageName.BtnNavClose]: {
    imageType: SPImageType.Svg,
    image: IcnBtnNavClose,
    width: 24,
    height: 24,
  },
  [SPImageName.IcnBlockAdd]: {
    imageType: SPImageType.Svg,
    image: IcnBlockAdd,
    width: 20,
    height: 20,
  },
  [SPImageName.CharDefault]: {
    imageType: SPImageType.Svg,
    image: IcnCharDefault,
  },
  [SPImageName.IcnStarS]: {
    imageType: SPImageType.ImageFile,
    image: IcnStarS,
    width: 28,
    height: 28,
  },
  [SPImageName.IcnEditFile]: {
    imageType: SPImageType.ImageFile,
    image: IcnEditFile,
    width: 28,
    height: 28,
  },
  [SPImageName.IcnPlayerReport]: {
    imageType: SPImageType.Svg,
    image: IcnPlayerReport,
  },
  [SPImageName.IcnPlayerReport2]: {
    imageType: SPImageType.Svg,
    image: IcnPlayerReport2,
  },
  [SPImageName.IcnBtnNavInfo]: {
    imageType: SPImageType.Svg,
    image: IcnBtnNavInfo,
  },
  [SPImageName.IcnFlashMessageWarning]: {
    imageType: SPImageType.Svg,
    image: IcnFlashMessageWarning,
  },
  [SPImageName.IcnRefresh]: {
    imageType: SPImageType.Svg,
    image: IcnRefresh,
  },
  [SPImageName.IcnMenuGallery]: {
    imageType: SPImageType.Svg,
    image: IcnMenuGallery,
  },
  [SPImageName.IcnEmptyCode]: {
    imageType: SPImageType.Svg,
    image: IcnEmptyCode,
  },
  [SPImageName.IcnLink]: {
    imageType: SPImageType.Svg,
    image: IcnLink,
  },
  [SPImageName.IcnBtnNavMore]: {
    imageType: SPImageType.Svg,
    image: IcnBtnNavMore,
  },
  [SPImageName.IcnPlayerReportWithTitle]: {
    imageType: SPImageType.Svg,
    image: IcnPlayerReportWithTitle,
  },
  [SPImageName.IcnCopyWithTitle]: {
    imageType: SPImageType.Svg,
    image: IcnCopyWithTitle,
  },
  [SPImageName.IcnCheckBoxWithTitle]: {
    imageType: SPImageType.Svg,
    image: IcnCheckBoxWithTitle,
  },
  [SPImageName.IcnSmallEdit]: {
    imageType: SPImageType.Svg,
    image: IcnSmallEdit,
  },
  [SPImageName.IcnCopy]: {
    imageType: SPImageType.Svg,
    image: IcnCopy,
  },
  [SPImageName.IcnDel]: {
    imageType: SPImageType.Svg,
    image: IcnDel,
  },
  [SPImageName.IcnBack]: {
    imageType: SPImageType.Svg,
    image: IcnBack,
  },
  [SPImageName.IcnUploadImage]: {
    imageType: SPImageType.Svg,
    image: IcnUploadImage,
  },
  [SPImageName.CharDefault2]: {
    imageType: SPImageType.ImageFile,
    image: ImgUserProfileDefault2,
  },
  [SPImageName.IcnNoImg]: {
    imageType: SPImageType.Svg,
    image: IcnNoImg,
  },
  [SPImageName.IcnProfile2User]: {
    imageType: SPImageType.Svg,
    image: IcnProfile2User,
  },
  [SPImageName.IcnAttack]: {
    imageType: SPImageType.Svg,
    image: IcnAttack,
  },
  [SPImageName.IcnMenuItem]: {
    imageType: SPImageType.Svg,
    image: IcnMenuItem,
  },
  [SPImageName.IcnBookSquare]: {
    imageType: SPImageType.Svg,
    image: IcnBookSquare,
  },
  [SPImageName.IcnChapterEndingBadge]: {
    imageType: SPImageType.Svg,
    image: IcnChapterEndingBadge,
  },
  [SPImageName.IcnFinalEndingBadge]: {
    imageType: SPImageType.Svg,
    image: IcnFinalEndingBadge,
  },
  [SPImageName.LogoStudio]: {
    imageType: SPImageType.ImageFile,
    image: ImgLogoStudio,
    width: 188,
    height: 44,
  },
  [SPImageName.RedWarning]: {
    imageType: SPImageType.ImageFile,
    image: ImgRedWarning,
    width: 100,
    height: 100,
  },
  [SPImageName.IcnProp]: {
    imageType: SPImageType.Svg,
    image: IcnProp,
  },
  [SPImageName.IcnPropSquare]: {
    imageType: SPImageType.Svg,
    image: IcnPropSquare,
  },
  [SPImageName.IcnLinearBox]: {
    imageType: SPImageType.Svg,
    image: IcnLinearBox,
  },
  [SPImageName.IcnNavPlay]: {
    imageType: SPImageType.Svg,
    image: IcnNavPlay,
  },
  [SPImageName.IcnMenuGalleryProp]: {
    imageType: SPImageType.Svg,
    image: IcnMenuGalleryProp,
  },
  [SPImageName.IcnMenuGalleryItem]: {
    imageType: SPImageType.Svg,
    image: IcnMenuGalleryItem,
  },
  [SPImageName.IcnMenuGalleryEnding]: {
    imageType: SPImageType.Svg,
    image: IcnMenuGalleryEnding,
  },
  [SPImageName.IcnMenuGalleryInfo]: {
    imageType: SPImageType.Svg,
    image: IcnMenuGalleryInfo,
  },
  [SPImageName.IcnPublish]: {
    imageType: SPImageType.Svg,
    image: IcnPublish,
  },
  [SPImageName.IcnPublishUndo]: {
    imageType: SPImageType.Svg,
    image: IcnPublishUndo,
  },
  [SPImageName.StoryCoverMini]: {
    imageType: SPImageType.Svg,
    image: IcnStoryCoverMini,
  },
  [SPImageName.IcnCircleWarning]: {
    imageType: SPImageType.Svg,
    image: IcnCircleWarning,
  },
  [SPImageName.IcnBtnHistory]: {
    imageType: SPImageType.Svg,
    image: IcnBtnHistory,
  },
  [SPImageName.IcnDelS2]: {
    imageType: SPImageType.Svg,
    image: IcnDelS2,
  },
  [SPImageName.IcnDeleteWarning]: {
    imageType: SPImageType.Svg,
    image: IcnDeleteWarning,
  },
  [SPImageName.IcnDB]: {
    imageType: SPImageType.Svg,
    image: IcnDB,
  },
  [SPImageName.IcnMenuStats]: {
    imageType: SPImageType.Svg,
    image: IcnMenuStats,
  },
  [SPImageName.IcnSheets]: {
    imageType: SPImageType.Svg,
    image: IcnSheets,
  },
  [SPImageName.IcnStatsInPaper]: {
    imageType: SPImageType.Svg,
    image: IcnStatsInPaper,
  },
  [SPImageName.AppIconDev]: {
    imageType: SPImageType.ImageFile,
    image: AppIconDev,
    width: 47,
    height: 47,
  },
  [SPImageName.AppIconHellobotDev]: {
    imageType: SPImageType.Svg,
    image: AppIconHellobotDev,
    width: 47,
    height: 47,
  },
  [SPImageName.IcnContestCrown]: {
    imageType: SPImageType.Svg,
    image: IcnContestCrown,
  },
  [SPImageName.AppIconBeta]: {
    imageType: SPImageType.ImageFile,
    image: AppIconBeta,
    width: 47,
    height: 47,
  },
  [SPImageName.IcnQna]: {
    imageType: SPImageType.Svg,
    image: IcnQna,
  },
  [SPImageName.IcnEye]: {
    imageType: SPImageType.Svg,
    image: IcnEye,
  },
  [SPImageName.LogoStudioBeta]: {
    imageType: SPImageType.ImageFile,
    image: ImgLogoStudioBeta,
    width: 202.5,
    height: 83,
  },
  [SPImageName.IcnInformationCircle]: {
    imageType: SPImageType.Svg,
    image: IcnInformationCircle,
  },
  [SPImageName.IcnSave]: {
    imageType: SPImageType.Svg,
    image: IcnSave,
  },
  [SPImageName.IcnInspect]: {
    imageType: SPImageType.Svg,
    image: IcnInspect,
  },
  [SPImageName.IcnReleaseNote]: {
    imageType: SPImageType.Svg,
    image: IcnReleaseNote,
  },
  [SPImageName.IcnReleaseNoteNew]: {
    imageType: SPImageType.Svg,
    image: IcnReleaseNoteNew,
  },
  [SPImageName.IcnLightWhite]: {
    imageType: SPImageType.Svg,
    image: IcnLightWhite,
  },
  [SPImageName.IcnCrown]: {
    imageType: SPImageType.Svg,
    image: IcnCrown,
  },
  [SPImageName.IcnChangeImage]: {
    imageType: SPImageType.Svg,
    image: IcnChangeImage,
  },
  [SPImageName.IcnChangeArea]: {
    imageType: SPImageType.Svg,
    image: IcnChangeArea,
  },
  [SPImageName.IcnBtnTtsHasLeftBorder]: {
    imageType: SPImageType.Svg,
    image: IcnBtnTtsHasLeftBorder,
  },
  [SPImageName.IcnTts]: {
    imageType: SPImageType.Svg,
    image: IcnTts,
  },
  [SPImageName.IcnBackground]: {
    imageType: SPImageType.Svg,
    image: IcnBackground,
  },
  [SPImageName.IcnBtnEditLeftSide]: {
    imageType: SPImageType.Svg,
    image: IcnBtnEditLeftSide,
  },
  [SPImageName.IcnBtnExpandImageRightSide]: {
    imageType: SPImageType.Svg,
    image: IcnBtnExpandImageRightSide,
  },
  [SPImageName.IcnComposeIfBlock]: {
    imageType: SPImageType.Svg,
    image: IcnComposeIfBlock,
  },
  [SPImageName.IcnMicrophone]: {
    imageType: SPImageType.Svg,
    image: IcnMicrophone,
  },
  [SPImageName.IcnBold]: {
    imageType: SPImageType.Svg,
    image: IcnBold,
  },
  [SPImageName.IcnItalic]: {
    imageType: SPImageType.Svg,
    image: IcnItalic,
  },
  [SPImageName.IcnUnderline]: {
    imageType: SPImageType.Svg,
    image: IcnUnderline,
  },
  [SPImageName.IcnAlignLeft]: {
    imageType: SPImageType.Svg,
    image: IcnAlignLeft,
  },
  [SPImageName.IcnAlignCenter]: {
    imageType: SPImageType.Svg,
    image: IcnAlignCenter,
  },
  [SPImageName.IcnAlignRight]: {
    imageType: SPImageType.Svg,
    image: IcnAlignRight,
  },
  [SPImageName.IcnUndoWithBorder]: {
    imageType: SPImageType.Svg,
    image: IcnUndoWithBorder,
  },
  [SPImageName.IcnRedoWithBorder]: {
    imageType: SPImageType.Svg,
    image: IcnRedoWithBorder,
  },
  [SPImageName.IcnBtnNewImgAtStart]: {
    imageType: SPImageType.Svg,
    image: IcnBtnNewImgAtStart,
  },
  [SPImageName.IcnBtnNewCoverImgMiddleOf]: {
    imageType: SPImageType.Svg,
    image: IcnBtnNewCoverImgMiddleOf,
  },
  [SPImageName.IcnBtnRemoveAtEnd]: {
    imageType: SPImageType.Svg,
    image: IcnBtnRemoveAtEnd,
  },
  [SPImageName.IcnCrop]: {
    imageType: SPImageType.Svg,
    image: IcnCrop,
  },
  [SPImageName.IcnCropHasLeftBorder]: {
    imageType: SPImageType.Svg,
    image: IcnCropHasLeftBorder,
  },
  [SPImageName.IcnMenuStory]: {
    imageType: SPImageType.Svg,
    image: IcnMenuStory,
  },
  [SPImageName.IcnMenuAuthor]: {
    imageType: SPImageType.Svg,
    image: IcnMenuAuthor,
  },
  [SPImageName.IcnVibration]: {
    imageType: SPImageType.Svg,
    image: IcnVibration,
  },
  [SPImageName.IcnBtnEdit]: {
    imageType: SPImageType.Svg,
    image: IcnBtnEdit,
  },
  [SPImageName.IcnBtnSaveChapter]: {
    imageType: SPImageType.Svg,
    image: IcnBtnSaveChapter,
  },
  [SPImageName.IcnBtnNavInfo2]: {
    imageType: SPImageType.Svg,
    image: IcnBtnNavInfo2,
  },
  [SPImageName.IcnBtnNavPrev]: {
    imageType: SPImageType.Svg,
    image: IcnBtnNavPrev,
  },
  [SPImageName.IcnBtnClose]: {
    imageType: SPImageType.Svg,
    image: IcnBtnClose,
  },
  [SPImageName.IcnBtnCheck]: {
    imageType: SPImageType.Svg,
    image: IcnBtnCheck,
  },
  [SPImageName.StoryPlayHeaderLogoOnMobile]: {
    imageType: SPImageType.Svg,
    image: StoryPlayHeaderLogoOnMobile,
  },
  [SPImageName.IcnChrome]: {
    imageType: SPImageType.Svg,
    image: IcnChrome,
  },
  [SPImageName.IcnMenuButton]: {
    imageType: SPImageType.Svg,
    image: IcnMenuButton,
  },
  [SPImageName.IcnStoryCoverWithLeftDim]: {
    imageType: SPImageType.Svg,
    image: IcnStoryCoverWithLeftDim,
  },
  [SPImageName.IcnInfoShare]: {
    imageType: SPImageType.Svg,
    image: IcnInfoShare,
  },
  [SPImageName.IcnUserEdit]: {
    imageType: SPImageType.Svg,
    image: IcnUserEdit,
  },
  [SPImageName.IcnChrMenu]: {
    imageType: SPImageType.Svg,
    image: IcnChrMenu,
  },
  [SPImageName.IcnValidationCheck]: {
    imageType: SPImageType.Svg,
    image: IcnValidationCheck,
  },
  [SPImageName.IcnValidationError]: {
    imageType: SPImageType.Svg,
    image: IcnValidationError,
  },
  [SPImageName.IcnReleaseVersion]: {
    imageType: SPImageType.Svg,
    image: IcnReleaseVersion,
  },
  [SPImageName.IcnBook]: {
    imageType: SPImageType.Svg,
    image: IcnBook,
  },
  [SPImageName.IcnStudioError]: {
    imageType: SPImageType.Svg,
    image: IcnStudioError,
  },
  [SPImageName.IcnEventStar]: {
    imageType: SPImageType.Svg,
    image: IcnEventStar,
  },
  [SPImageName.IcnEventGrape2]: {
    imageType: SPImageType.Svg,
    image: IcnEventGrape2,
  },
  [SPImageName.IcnChapterInfoInGNB]: {
    imageType: SPImageType.Svg,
    image: IcnChapterInfoInGNB,
  },
  [SPImageName.IcnTestPlayInGNB]: {
    imageType: SPImageType.Svg,
    image: IcnTestPlayInGNB,
  },
  [SPImageName.IcnSearchInGNB]: {
    imageType: SPImageType.Svg,
    image: IcnSearchInGNB,
  },
  [SPImageName.IcnInspectInGNB]: {
    imageType: SPImageType.Svg,
    image: IcnInspectInGNB,
  },
  [SPImageName.IcnExportInGNB]: {
    imageType: SPImageType.Svg,
    image: IcnExportInGNB,
  },
  [SPImageName.IcnPublishInGNB]: {
    imageType: SPImageType.Svg,
    image: IcnPublishInGNB,
  },
  [SPImageName.IcnTutorial]: {
    imageType: SPImageType.Svg,
    image: IcnTutorial,
  },
  [SPImageName.IcnTutorialText]: {
    imageType: SPImageType.Svg,
    image: IcnTutorialText,
  },
  [SPImageName.IcnNavClose]: {
    imageType: SPImageType.Svg,
    image: IcnNavClose,
  },
  [SPImageName.IcnCheckInCircle]: {
    imageType: SPImageType.Svg,
    image: IcnCheckInCircle,
  },
  [SPImageName.IcnTutorialFunctionOpen]: {
    imageType: SPImageType.Svg,
    image: IcnTutorialFunctionOpen,
  },
  [SPImageName.IcnTutorialEx]: {
    imageType: SPImageType.Svg,
    image: IcnTutorialEx,
  },
  [SPImageName.IcnStudioLogoInTutorial]: {
    imageType: SPImageType.Svg,
    image: IcnStudioLogoInTutorial,
  },
  [SPImageName.IcnCloseInDarkCircle]: {
    imageType: SPImageType.Svg,
    image: IcnCloseInDarkCircle,
  },
  [SPImageName.IcnTutorialCompletedText]: {
    imageType: SPImageType.Svg,
    image: IcnTutorialCompletedText,
  },
  [SPImageName.IcnTutorialCompleted]: {
    imageType: SPImageType.Svg,
    image: IcnTutorialCompleted,
  },
  [SPImageName.IcnTutorialLock]: {
    imageType: SPImageType.Svg,
    image: IcnTutorialLock,
  },
  [SPImageName.IcnCheckInTutorialCheckModal]: {
    imageType: SPImageType.Svg,
    image: IcnCheckInTutorialCheckModal,
  },
  [SPImageName.IcnMenuMore]: {
    imageType: SPImageType.Svg,
    image: IcnMenuMore,
  },
  [SPImageName.IcnTutorialDone]: {
    imageType: SPImageType.Svg,
    image: IcnTutorialDone,
  },
  [SPImageName.IcnChatBotInCircle]: {
    imageType: SPImageType.Svg,
    image: IcnChatBotInCircle,
  },
  [SPImageName.IcnValidationErrorGray]: {
    imageType: SPImageType.Svg,
    image: IcnValidationErrorGray,
  },
  [SPImageName.IcnBtnQuestion]: {
    imageType: SPImageType.Svg,
    image: IcnBtnQuestion,
  },
  [SPImageName.IcnBtnExecuteConditionLeftBorder]: {
    imageType: SPImageType.Svg,
    image: IcnBtnExecuteConditionLeftBorder,
  },
  [SPImageName.IcnBtnExecuteConditionLeftBorderFirst]: {
    imageType: SPImageType.Svg,
    image: IcnBtnExecuteConditionLeftBorderFirst,
  },
  [SPImageName.IcnChatbot]: {
    imageType: SPImageType.Svg,
    image: IcnChatbot,
  },
  [SPImageName.IcnHasDraftText]: {
    imageType: SPImageType.Svg,
    image: IcnHasDraftText,
  },
  [SPImageName.IcnDashboardNav]: {
    imageType: SPImageType.Svg,
    image: IcnDashboardNav,
  },
  [SPImageName.IcnChartNav]: {
    imageType: SPImageType.Svg,
    image: IcnChartNav,
  },
  [SPImageName.IcnPresentationChartNav]: {
    imageType: SPImageType.Svg,
    image: IcnPresentationChartNav,
  },
  [SPImageName.IcnRankingChartNav]: {
    imageType: SPImageType.Svg,
    image: IcnRankingChartNav,
  },
  [SPImageName.IcnChevronRight]: {
    imageType: SPImageType.Svg,
    image: IcnChevronRight,
  },
  [SPImageName.IcnChevronLeft]: {
    imageType: SPImageType.Svg,
    image: IcnChevronLeft,
  },
  [SPImageName.IcnMoreS]: {
    imageType: SPImageType.Svg,
    image: IcnMoreS,
  },
  [SPImageName.IcnBtnCalendar]: {
    imageType: SPImageType.Svg,
    image: IcnBtnCalendar,
  },
  [SPImageName.IcnArrowNextLight]: {
    imageType: SPImageType.Svg,
    image: IcnArrowNextLight,
  },
  [SPImageName.IcnArrowBeforeLight]: {
    imageType: SPImageType.Svg,
    image: IcnArrowBeforeLight,
  },
  [SPImageName.IcnArrowNarrowShortDown]: {
    imageType: SPImageType.Svg,
    image: IcnArrowNarrowShortDown,
  },
  [SPImageName.IcnArrowNarrowShortUp]: {
    imageType: SPImageType.Svg,
    image: IcnArrowNarrowShortUp,
  },
  [SPImageName.IcnBtnNavQuestion]: {
    imageType: SPImageType.Svg,
    image: IcnBtnNavQuestion,
  },
  [SPImageName.IcnArrowNarrowShortLine]: {
    imageType: SPImageType.Svg,
    image: IcnArrowNarrowShortLine,
  },
  [SPImageName.IcnCommentBest]: {
    imageType: SPImageType.Svg,
    image: IcnCommentBest,
  },
  [SPImageName.IcnCommentLike]: {
    imageType: SPImageType.Svg,
    image: IcnCommentLike,
  },
  [SPImageName.IcnCommentOfComment]: {
    imageType: SPImageType.Svg,
    image: IcnCommentOfComment,
  },
  [SPImageName.IcnArrowDownGray]: {
    imageType: SPImageType.Svg,
    image: IcnArrowDownGray,
  },
  [SPImageName.IcnStatusBadgeZero]: {
    imageType: SPImageType.Svg,
    image: IcnStatusBadgeZero,
  },
  [SPImageName.IcnStatusBadgeUp]: {
    imageType: SPImageType.Svg,
    image: IcnStatusBadgeUp,
  },
  [SPImageName.IcnSelectBoxArrowDown]: {
    imageType: SPImageType.Svg,
    image: IcnSelectBoxArrowDown,
  },
  [SPImageName.IcnCrownYellow]: {
    imageType: SPImageType.Svg,
    image: IcnCrownYellow,
  },
  [SPImageName.ContestLogo]: {
    imageType: SPImageType.ImageFile,
    image: ContestLogo,
  },
  [SPImageName.IcnMenuNovel]: {
    imageType: SPImageType.Svg,
    image: IcnMenuNovel,
  },
  [SPImageName.IcnBtnArrowRight]: {
    imageType: SPImageType.Svg,
    image: IcnBtnArrowRight,
  },
  [SPImageName.IcnMobileNavStoryManage]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavStoryManage,
  },
  [SPImageName.IcnMobileNavChapterList]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavChapterList,
  },
  [SPImageName.IcnMobileNavChr]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavChr,
  },
  [SPImageName.IcnMobileNavBackgroundManage]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavBackgroundManage,
  },
  [SPImageName.IcnMobileNavEndingManage]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavEndingManage,
  },
  [SPImageName.IcnMobileNavProp]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavProp,
  },
  [SPImageName.IcnMobileNavItemManage]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavItemManage,
  },
  [SPImageName.IcnMobileNavPlayerReport]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavPlayerReport,
  },
  [SPImageName.IcnMobileNavMemberManage]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavMemberManage,
  },
  [SPImageName.IcnMobileNavEtc]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavEtc,
  },
  [SPImageName.IcnNavManageStory]: {
    imageType: SPImageType.Svg,
    image: IcnNavManageStory,
  },
  [SPImageName.IcnNavListChapter]: {
    imageType: SPImageType.Svg,
    image: IcnNavListChapter,
  },
  [SPImageName.IcnNavChar]: {
    imageType: SPImageType.Svg,
    image: IcnNavChar,
  },
  [SPImageName.IcnNavBackground]: {
    imageType: SPImageType.Svg,
    image: IcnNavBackground,
  },
  [SPImageName.IcnNavManageEnding]: {
    imageType: SPImageType.Svg,
    image: IcnNavManageEnding,
  },
  [SPImageName.IcnNavProp]: {
    imageType: SPImageType.Svg,
    image: IcnNavProp,
  },
  [SPImageName.IcnNavManageEtc]: {
    imageType: SPImageType.Svg,
    image: IcnNavManageEtc,
  },
  [SPImageName.IcnMenuEffect]: {
    imageType: SPImageType.Svg,
    image: IcnMenuEffect,
  },
  [SPImageName.IcnUploadImage2]: {
    imageType: SPImageType.Svg,
    image: IcnUploadImage2,
  },
  [SPImageName.IcnMagic]: {
    imageType: SPImageType.Svg,
    image: IcnMagic,
  },
  [SPImageName.IcnMenuPause]: {
    imageType: SPImageType.Svg,
    image: IcnMenuPause,
  },
  [SPImageName.IcnBtnFloatingAdd]: {
    imageType: SPImageType.Svg,
    image: IcnBtnFloatingAdd,
  },
  [SPImageName.IcnBtnEditPen]: {
    imageType: SPImageType.Svg,
    image: IcnBtnEditPen,
  },
  [SPImageName.IcnExpand]: {
    imageType: SPImageType.Svg,
    image: IcnExpand,
  },
  [SPImageName.IcnDeleteTrash]: {
    imageType: SPImageType.Svg,
    image: IcnDeleteTrash,
  },
  [SPImageName.IcnBtnAddImgInCircle]: {
    imageType: SPImageType.Svg,
    image: IcnBtnAddImgInCircle,
  },
  [SPImageName.IcnBtnDeleteInCircle]: {
    imageType: SPImageType.Svg,
    image: IcnBtnDeleteInCircle,
  },
  [SPImageName.IcnLlamama]: {
    imageType: SPImageType.Svg,
    image: IcnLlamama,
    width: 152,
    height: 152,
  },
  [SPImageName.LogoHellobotStudio]: {
    imageType: SPImageType.Svg,
    image: LogoHellobotStudio,
    width: 144,
    height: 52,
  },
  [SPImageName.IcnHbAlgorithmBlock]: {
    imageType: SPImageType.Svg,
    image: IcnHbAlgorithmBlock,
  },
  [SPImageName.IcnHbAttribute]: {
    imageType: SPImageType.Svg,
    image: IcnHbAttribute,
  },
  [SPImageName.IcnHbBeginConversation]: {
    imageType: SPImageType.Svg,
    image: IcnHbBeginConversation,
  },
  [SPImageName.IcnHbChatbotBlock]: {
    imageType: SPImageType.Svg,
    image: IcnHbChatbotBlock,
  },
  [SPImageName.IcnHbDashboard]: {
    imageType: SPImageType.Svg,
    image: IcnHbDashboard,
  },
  [SPImageName.IcnHbLibrary]: {
    imageType: SPImageType.Svg,
    image: IcnHbLibrary,
  },
  [SPImageName.IcnHbMatching]: {
    imageType: SPImageType.Svg,
    image: IcnHbMatching,
  },
  [SPImageName.IcnHbReportGroup]: {
    imageType: SPImageType.Svg,
    image: IcnHbReportGroup,
  },
  [SPImageName.IcnHbSkillList]: {
    imageType: SPImageType.Svg,
    image: IcnHbSkillList,
  },
  [SPImageName.IcnHbStatistics]: {
    imageType: SPImageType.Svg,
    image: IcnHbStatistics,
  },
  [SPImageName.IcnHbTeachConversation]: {
    imageType: SPImageType.Svg,
    image: IcnHbTeachConversation,
  },
  [SPImageName.IcnHbUser]: {
    imageType: SPImageType.Svg,
    image: IcnHbUser,
  },
  [SPImageName.IcnHbIfBlock]: {
    imageType: SPImageType.Svg,
    image: IcnHbIfBlock,
  },
  [SPImageName.IcnSideMenuLight]: {
    imageType: SPImageType.Svg,
    image: IcnSideMenuLight,
  },
  [SPImageName.IcnEditLight]: {
    imageType: SPImageType.Svg,
    image: IcnEditLight,
  },
  [SPImageName.IcnGnbMenuLight]: {
    imageType: SPImageType.Svg,
    image: IcnGnbMenuLight,
  },
  [SPImageName.IcnStoryLine]: {
    imageType: SPImageType.Svg,
    image: IcnStoryLine,
  },
  [SPImageName.IcnInteractiveLine]: {
    imageType: SPImageType.Svg,
    image: IcnInteractiveLine,
  },
  [SPImageName.IcnEpubUpload]: {
    imageType: SPImageType.Svg,
    image: IcnEpubUpload,
  },
  [SPImageName.IcnEpubUploaded]: {
    imageType: SPImageType.Svg,
    image: IcnEpubUploaded,
  },
  [SPImageName.IcnModalCloseLight]: {
    imageType: SPImageType.Svg,
    image: IcnModalCloseLight,
  },
  [SPImageName.IcnWriteWithPlus]: {
    imageType: SPImageType.Svg,
    image: IcnWriteWithPlus,
  },
  [SPImageName.IcnEpubUploadInBottomSheet]: {
    imageType: SPImageType.Svg,
    image: IcnEpubUploadInBottomSheet,
  },
  [SPImageName.IcnCheckInBottomSheet]: {
    imageType: SPImageType.Svg,
    image: IcnCheckInBottomSheet,
  },
  [SPImageName.IcnCheckOn]: {
    imageType: SPImageType.Svg,
    image: IcnCheckOn,
  },
  [SPImageName.IcnCheckOff]: {
    imageType: SPImageType.Svg,
    image: IcnCheckOff,
  },
  [SPImageName.IcnIfBlockSayChatbot]: {
    imageType: SPImageType.Svg,
    image: IcnIfBlockSayChatbot,
  },
  [SPImageName.IcnFlowChartLink]: {
    imageType: SPImageType.Svg,
    image: IcnFlowChartLink,
  },
  [SPImageName.IcnBackButtonIcon]: {
    imageType: SPImageType.Svg,
    image: BackButtonIcon,
  },
  [SPImageName.IcnBlocklink]: {
    imageType: SPImageType.ImageFile,
    image: IcnBlocklink,
    width: 16,
    height: 16,
  },
  [SPImageName.OtherBlockLink]: {
    imageType: SPImageType.Svg,
    image: OtherBlockLink,
  },
  [SPImageName.IcnBtnHoverDel]: {
    imageType: SPImageType.Svg,
    image: IcnBtnHoverDel,
  },
  [SPImageName.IcnLineDrawing]: {
    imageType: SPImageType.Svg,
    image: IcnLineDrawing,
  },
  [SPImageName.IcnHeartInInput]: {
    imageType: SPImageType.Svg,
    image: IcnHeartInInput,
  },
  [SPImageName.IcnCloseLight]: {
    imageType: SPImageType.Svg,
    image: IcnCloseLight,
  },
  [SPImageName.IcnTriangle]: {
    imageType: SPImageType.Svg,
    image: IcnTriangle,
  },

  [SPImageName.IcnHeart]: {
    imageType: SPImageType.ImageFile,
    image: IcnHeart,
  },
  [SPImageName.IcnGrayHeart]: {
    imageType: SPImageType.ImageFile,
    image: IcnGrayHeart,
  },
  [SPImageName.IcnAddWhite]: {
    imageType: SPImageType.Svg,
    image: IcnAddWhite,
  },
  [SPImageName.IconPlatformHb]: {
    imageType: SPImageType.ImageFile,
    image: IconPlatformHb,
    width: 16,
    height: 16,
  },
  [SPImageName.IcnBtnSecondaryAddSmall]: {
    imageType: SPImageType.Svg,
    image: IcnBtnSecondaryAddSmall,
  },
  [SPImageName.IconHellobotPreviewLogo]: {
    imageType: SPImageType.ImageFile,
    image: IconHellobotPreviewLogo,
    width: 106,
    height: 32,
  },
  [SPImageName.IcnStar]: {
    imageType: SPImageType.Svg,
    image: IcnStar,
  },
  [SPImageName.IcnSelected]: {
    imageType: SPImageType.Svg,
    image: IcnSelected,
  },
  [SPImageName.IcnPhotoEdit]: {
    imageType: SPImageType.Svg,
    image: IcnPhotoEdit,
  },
  [SPImageName.IcnCheckBoxWithTitleHb]: {
    imageType: SPImageType.Svg,
    image: IcnCheckBoxWithTitleHb,
  },
  [SPImageName.IcnHbProfile]: {
    imageType: SPImageType.ImageFile,
    image: IcnHbProfile,
    width: 100,
    height: 100,
  },
  [SPImageName.IcnAttributeOn]: {
    imageType: SPImageType.Svg,
    image: IcnAttributeOn,
  },
  [SPImageName.IcnNewChatBot]: {
    imageType: SPImageType.Svg,
    image: IcnNewChatBot,
  },
  [SPImageName.IcnOutlineExport]: {
    imageType: SPImageType.Svg,
    image: IcnOutlineExport,
  },
  [SPImageName.IcnVisibility]: {
    imageType: SPImageType.Svg,
    image: IcnVisibility,
  },
  [SPImageName.IcnWorld]: {
    imageType: SPImageType.Svg,
    image: IcnWorld,
  },
  [SPImageName.IcnDevice]: {
    imageType: SPImageType.Svg,
    image: IcnDevice,
  },
  [SPImageName.IcnWatch]: {
    imageType: SPImageType.Svg,
    image: IcnWatch,
  },
  [SPImageName.ImgWebNovelContest]: {
    imageType: SPImageType.ImageFile,
    image: WebNovelContestImg,
  },
  [SPImageName.RibbonTestWait]: {
    imageType: SPImageType.Svg,
    image: RibbonTestWait,
  },
  [SPImageName.RibbonTesting]: {
    imageType: SPImageType.Svg,
    image: RibbonTesting,
  },
  [SPImageName.IcnInspection]: {
    imageType: SPImageType.Svg,
    image: IcnInspection,
  },
  [SPImageName.IcnInspectionWhite]: {
    imageType: SPImageType.Svg,
    image: IcnInspectionWhite,
  },
  [SPImageName.IcnBlueCheck]: {
    imageType: SPImageType.Svg,
    image: IcnBlueCheck,
  },
  [SPImageName.HellobotTextLogo]: {
    imageType: SPImageType.Svg,
    image: HellobotTextLogo,
  },
  [SPImageName.MobileBackCircle]: {
    imageType: SPImageType.Svg,
    image: MobileBackCircle,
  },
  [SPImageName.IcnFresh]: {
    imageType: SPImageType.Svg,
    image: IcnFresh,
  },
  [SPImageName.IcnArrowLeft]: {
    imageType: SPImageType.Svg,
    image: IcnArrowLeft,
  },
  [SPImageName.IcnArrowLeftWhite]: {
    imageType: SPImageType.Svg,
    image: IcnArrowLeftWhite,
  },
  [SPImageName.IcnFlashMessageWarningOrange]: {
    imageType: SPImageType.ImageFile,
    image: IcnFlashMessageWarningOrange,
    width: 24,
    height: 24,
  },
  [SPImageName.IcnSolidAdd]: {
    imageType: SPImageType.Svg,
    image: IcnSolidAdd,
  },
  [SPImageName.QwertyKeyboard]: {
    imageType: SPImageType.Svg,
    image: QwertyKeyboard,
  },
  [SPImageName.NumberKeyboard]: {
    imageType: SPImageType.Svg,
    image: NumberKeyboard,
  },
  [SPImageName.TarotCard]: {
    imageType: SPImageType.ImageFile,
    image: TarotCard,
    width: 62,
    height: 100,
  },
  [SPImageName.IcnLang]: {
    imageType: SPImageType.Svg,
    image: IcnLang,
  },
  [SPImageName.IcnNavManagePrice]: {
    imageType: SPImageType.Svg,
    image: IcnNavManagePrice,
  },
  [SPImageName.IcnMobileNavPrice]: {
    imageType: SPImageType.Svg,
    image: IcnMobileNavPrice,
  },
  [SPImageName.IcnAddBtn]: {
    imageType: SPImageType.Svg,
    image: IcnAddBtn,
  },
  [SPImageName.IcnAddBtnGray]: {
    imageType: SPImageType.Svg,
    image: IcnAddBtnGray,
  },
  [SPImageName.IcnMenuBanner]: {
    imageType: SPImageType.Svg,
    image: IcnMenuBanner,
  },
  [SPImageName.IcnMenuRecommend]: {
    imageType: SPImageType.Svg,
    image: IcnMenuRecommend,
  },
  [SPImageName.IcnMenuMonthly]: {
    imageType: SPImageType.Svg,
    image: IcnMenuMonthly,
  },
  [SPImageName.IcnAdult]: {
    imageType: SPImageType.Svg,
    image: IcnAdult,
  },
  [SPImageName.ImgPropsPrimary]: {
    imageType: SPImageType.Svg,
    image: ImgPropsPrimary,
  },
  [SPImageName.ImagePreviewStoryGameMo]: {
    imageType: SPImageType.ImageFile,
    image: ImagePreviewStoryGameMo,
  },
  [SPImageName.ImagePreviewStoryGamePc]: {
    imageType: SPImageType.ImageFile,
    image: ImagePreviewStoryGamePc,
  },
  [SPImageName.ImagePreviewChatMo]: {
    imageType: SPImageType.ImageFile,
    image: ImagePreviewChatMo,
  },
  [SPImageName.ImagePreviewChatMoHero]: {
    imageType: SPImageType.ImageFile,
    image: ImagePreviewChatMoHero,
  },
  [SPImageName.ImagePreviewChatProfile]: {
    imageType: SPImageType.ImageFile,
    image: ImagePreviewChatProfile,
  },
  [SPImageName.IcnBtnTalkPreviewHasLeftBorder]: {
    imageType: SPImageType.Svg,
    image: IcnBtnTalkPreviewHasLeftBorder,
  },
  [SPImageName.IcnProfileNothing]: {
    imageType: SPImageType.Svg,
    image: IcnProfileNothing,
  },
  [SPImageName.ImageBgPreviewChatMo]: {
    imageType: SPImageType.ImageFile,
    image: ImageBgPreviewChatMo,
  },
  [SPImageName.ImageBgPreviewStorygameMo]: {
    imageType: SPImageType.ImageFile,
    image: ImageBgPreviewStorygameMo,
  },
  [SPImageName.ImageBgPreviewStorygamePc]: {
    imageType: SPImageType.ImageFile,
    image: ImageBgPreviewStorygamePc,
  },
  [SPImageName.ImageEffectPreviewChat]: {
    imageType: SPImageType.ImageFile,
    image: ImageEffectPreviewChat,
  },
  [SPImageName.ImageEffectPreviewMobile]: {
    imageType: SPImageType.ImageFile,
    image: ImageEffectPreviewMobile,
  },
  [SPImageName.ImageEffectPreviewPC]: {
    imageType: SPImageType.ImageFile,
    image: ImageEffectPreviewPC,
  },
  [SPImageName.IcnBtnPreviewArrowLeft]: {
    imageType: SPImageType.Svg,
    image: IcnBtnPreviewArrowLeft,
  },
  [SPImageName.IcnBtnPreviewArrowRight]: {
    imageType: SPImageType.Svg,
    image: IcnBtnPreviewArrowRight,
  },
  [SPImageName.IcnBtnMoveArrowLeft]: {
    imageType: SPImageType.Svg,
    image: IcnBtnMoveArrowLeft,
  },
  [SPImageName.IcnBtnMoveArrowRight]: {
    imageType: SPImageType.Svg,
    image: IcnBtnMoveArrowRight,
  },
  [SPImageName.IcnVideo]: {
    imageType: SPImageType.Svg,
    image: IcnVideo,
  },
  [SPImageName.IcnUploadPortraitImage]: {
    imageType: SPImageType.Svg,
    image: IcnUploadPortraitImage,
  },
  [SPImageName.IcnCropHasLeftBorderWithRightBorderRadius]: {
    imageType: SPImageType.Svg,
    image: IcnCropHasLeftBorderWithRightBorderRadius,
  },
  [SPImageName.IcnArrowLeftLine]: {
    imageType: SPImageType.Svg,
    image: IcnArrowLeftLine,
  },
  [SPImageName.IcnArrowRightLine]: {
    imageType: SPImageType.Svg,
    image: IcnArrowRightLine,
  },
  [SPImageName.IcnCheckedLine]: {
    imageType: SPImageType.Svg,
    image: IcnCheckedLine,
  },
  [SPImageName.IcnCommonMore]: {
    imageType: SPImageType.Svg,
    image: IcnCommonMore,
  },
  [SPImageName.IcnCommonDetect]: {
    imageType: SPImageType.Svg,
    image: IcnCommonDetect,
  },
  [SPImageName.IcnCommonLanguage]: {
    imageType: SPImageType.Svg,
    image: IcnCommonLanguage,
  },
  [SPImageName.IcnCommonRegion]: {
    imageType: SPImageType.Svg,
    image: IcnCommonRegion,
  },
  [SPImageName.IcnCommonDesktop]: {
    imageType: SPImageType.Svg,
    image: IcnCommonDesktop,
  },
  [SPImageName.IcnCommonTablet]: {
    imageType: SPImageType.Svg,
    image: IcnCommonTablet,
  },
  [SPImageName.IcnCommonMobile]: {
    imageType: SPImageType.Svg,
    image: IcnCommonMobile,
  },
  [SPImageName.IcnCommonImage]: {
    imageType: SPImageType.Svg,
    image: IcnCommonImage,
  },
  [SPImageName.IcnCommonDrag]: {
    imageType: SPImageType.Svg,
    image: IcnCommonDrag,
  },
}
