import { ContentType, IMethodOptions, IRestApiClient } from '@storyplay/core'

export class RESTAPIClient implements IRestApiClient {
  private readonly getAuthToken: () => Promise<string | null>
  private readonly serverAddress: string

  constructor(
    getAuthToken: () => Promise<string | null>,
    serverAddress: string
  ) {
    this.getAuthToken = getAuthToken
    this.serverAddress = serverAddress
  }

  async postFormData<T>(
    url: string,
    payload: FormData,
    options?: IMethodOptions
  ): Promise<T> {
    return this.commonFetch('POST', url, payload, options)
  }

  async put<T>(
    url: string,
    payload: object,
    options?: IMethodOptions
  ): Promise<T> {
    return this.commonFetch('PUT', url, JSON.stringify(payload), {
      contentType: 'application/json',
      ...options,
    })
  }

  async delete<T>(
    url: string,
    payload: object,
    options?: IMethodOptions
  ): Promise<T> {
    return this.commonFetch('DELETE', url, JSON.stringify(payload), {
      contentType: 'application/json',
      ...options,
    })
  }

  async post<T>(
    url: string,
    payloadIn: object,
    options?: IMethodOptions
  ): Promise<T> {
    const payload =
      payloadIn instanceof FormData ? payloadIn : JSON.stringify(payloadIn)
    const contentType: ContentType | undefined =
      payloadIn instanceof FormData ? undefined : 'application/json'
    return this.commonFetch('POST', url, payload, {
      contentType,
      ...options,
    })
  }

  async get<T>(
    url: string,
    qs: object = {},
    options?: IMethodOptions
  ): Promise<T> {
    const query = Object.entries(qs)
      .filter((v) => v[1] !== undefined)
      .map((data) => `${data[0]}=${data[1]}`)
      .join('&')

    return this.commonFetch(
      'GET',
      `${url}${query.length > 0 ? '?' : ''}${query}`,
      undefined,
      {
        contentType: 'application/json',
        ...options,
      }
    )
  }

  async patch<RESULT_TYPE>(
    url: string,
    payload: object,
    options?: IMethodOptions
  ): Promise<RESULT_TYPE> {
    return this.commonFetch<RESULT_TYPE>(
      'PATCH',
      url,
      JSON.stringify(payload),
      {
        contentType: 'application/json',
        ...options,
      }
    )
  }

  async patchFormData<RESULT_TYPE>(
    url: string,
    payload: FormData,
    options?: IMethodOptions
  ): Promise<RESULT_TYPE> {
    return this.commonFetch<RESULT_TYPE>('PATCH', url, payload, { ...options })
  }

  private async commonFetch<RESULT_TYPE>(
    method: 'GET' | 'PATCH' | 'DELETE' | 'POST' | 'PUT',
    url: string,
    payload?: BodyInit | null,
    options?: IMethodOptions
  ): Promise<RESULT_TYPE> {
    const headers = await this.getHeader({
      ...options,
    })

    const res = await fetch(`${this.serverAddress}${url}`, {
      method,
      headers,
      body: payload,
    })

    return this.resultToJson(res, options)
  }

  private async getHeader(options?: IMethodOptions) {
    const token = await this.getAuthToken()

    const headers: HeadersInit = {}

    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    if (options?.contentType === 'application/json') {
      headers['Content-Type'] = 'application/json'
    }

    return headers
  }

  private async resultToJson<RESULT_TYPE>(
    res: Response,
    options?: IMethodOptions
  ): Promise<RESULT_TYPE> {
    let result
    try {
      result = await res.json()
    } catch (ex) {
      if (this.isSuccessStatus(res.status)) {
        result = undefined
      } else {
        throw new Error('unknown Error')
      }
    }

    if (this.isSuccessStatus(res.status) || !!options?.useErrorResponse) {
      return result as RESULT_TYPE
    }

    throw new Error(result?.message ?? 'unknown Error')
  }

  private isSuccessStatus(status: number) {
    return status >= 200 && status < 400
  }
}
