import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

export const StudioCommonToastContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    width: 384px;
  }

  .Toastify__toast {
    padding: 0;
    margin: 0;
    border-radius: 16px;
    //border: 1px solid #999999;
  }

  .Toastify__toast--default {
    background-color: transparent;
  }

  .Toastify__toast-body {
  }

  .Toastify__progress-bar {
  }
`
