import {
  IAlgorithmBlockGroupNodeInfo,
  IDOSTAlgorithm,
  SPImageName,
} from '@storyplay/common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { SPImage } from '../../../../assets/SPImages'
import { STUDIO_COLORS } from '../../../../styles/colors'
import { HbAlgorithmBlockCard } from './HbAlgorithmBlockCard'

interface IHbAlgorithmBlock {
  algorithmMessage: IDOSTAlgorithm
}

export const HbAlgorithmBlock: React.FC<IHbAlgorithmBlock> = observer(
  (props) => {
    const { algorithmMessage } = props

    // 우리는 구현의 편의를 위해 여러개의 알고리즘 메시지가 존재할 때 첫번째 알고리즘 메시지를 Node 로 취급했다.
    // 따라서 모든 알고리즘 메시지를 연동하려면 상위 블록에 연결된 모든 알고리즘메시지를 통해서 데이터를 받아와야 한다.
    const blocks = algorithmMessage.block.algorithmMessages
      .map((m) => m.connectedAlgorithmBlockGroupInfo)
      .filter((v) => v !== undefined) as IAlgorithmBlockGroupNodeInfo[]

    return (
      <Container className="border-primary-500 border-dashed border-2 flex-col space-y-2 justify-content items-center">
        <HeadContainer className="flex justify-center items-center space-x-2">
          <IconContainer className="bg-primary-100 primary">
            <SPImage
              imageName={SPImageName.IcnFlowChartLink}
              style={{ stroke: STUDIO_COLORS.Primary500 }}
            />
          </IconContainer>
          <span
            className="text-black font-bold font-sm"
            style={{ marginTop: 2 }}
          >
            알고리즘 블록 연결&nbsp;&nbsp;
          </span>
        </HeadContainer>
        <ScrollContainer className="space-y-2">
          {blocks.map((bl, index) => (
            <HbAlgorithmBlockCard {...bl} key={index} />
          ))}
        </ScrollContainer>
      </Container>
    )
  }
)

const Container = styled.div`
  width: 100%;
  min-width: 180px;
  max-width: 180px;
  padding: 8px;
  min-height: 56px;
  border-radius: 8px;
  background: white;
`

const HeadContainer = styled.div``

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ScrollContainer = styled.ol`
  max-height: 250px;
  overflow-y: auto;
`
