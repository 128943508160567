// import 'bulma/css/bulma.min.css'
import React from 'react'
import { ApolloProvider } from 'react-apollo-hooks'
import { Route, BrowserRouter as Router } from 'react-router-dom'
// react-toastify 9.0.3 으로 pinning
// https://github.com/fkhadra/react-toastify/issues/775
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Slide, Zoom } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { QueryParamProvider } from 'use-query-params'
import { createApolloClient } from './apolloClientUtils'
import { MetaTags } from './components/MetaTags'
import { StudioTitleHelmet } from './components/StudioTitleHelmet'
import { ReactChannelIOWrapper } from './containers/ReactChannelIOWrapper'
import { StudioRoot } from './containers/route/StudioRoot'
import { StudioCommonToastContainer } from './containers/studio/ui/common/StudioCommonToastContainer'
import { TutorialAllFinishToastContainer } from './containers/studio/ui/common/TutorialAllFinishToastContainer'
import { TutorialToastContainer } from './containers/studio/ui/common/TutorialToastContainer'
import { OverlayProvider } from './contexts/overlay/overlay-provider'
import { GlobalStyle } from './global-styles'
import { Fonts } from './styles/fonts'
import { AppOptions } from './utils/AppOptions'
import { registerServerErrorMessages } from './utils/registerServerErrorMessages'

const { client } = createApolloClient(
  async () => localStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN),
  AppOptions.SERVER_ADDRESS,
  AppOptions.WS_SERVER_ADDRESS
)

const reactQueryClient = new QueryClient()

const containerStyle = {
  zIndex: 9999,
}

const tutorialCheckToastContainerStyle = {
  ...containerStyle,
  width: '100%',
  maxWidth: 512,
  borderRadius: 16,
}

registerServerErrorMessages()

function App() {
  return (
    <>
      <StudioTitleHelmet />
      <MetaTags />
      <Fonts />
      <QueryClientProvider client={reactQueryClient}>
        <ApolloProvider client={client}>
          <GlobalStyle />
          <StudioCommonToastContainer
            position="top-right"
            enableMultiContainer
            containerId="default"
            autoClose={5000}
            style={containerStyle}
            transition={Slide}
          />
          <TutorialToastContainer
            position="top-center"
            enableMultiContainer
            containerId="tutorial"
            autoClose={false}
            style={tutorialCheckToastContainerStyle}
            transition={Zoom}
          />
          <TutorialAllFinishToastContainer
            position="top-center"
            enableMultiContainer
            containerId="tutorialAll"
            autoClose={false}
            style={containerStyle}
            transition={Zoom}
          />
          <Router>
            <QueryParamProvider ReactRouterRoute={Route}>
              <ReactChannelIOWrapper>
                <OverlayProvider>
                  <StudioRoot />
                </OverlayProvider>
              </ReactChannelIOWrapper>
            </QueryParamProvider>
          </Router>
        </ApolloProvider>
      </QueryClientProvider>
    </>
  )
}

export default App
