import { SPImageName, StudioUrls } from '@storyplay/common'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { SPImage } from '../assets/SPImages'
import { VSpace } from '../components/VSpace'
import { useTrans } from '../hooks/i18n/useTrans'
import { useStudioStore } from './studio/di/configureRootStore'

export const StudioError: React.FC = observer(() => {
  const trans = useTrans()
  const store = useStudioStore()
  const history = useHistory()
  const message = store.textStore.translateError(
    store.unrecoverableError ?? trans('legacy_StudioError_unknown_error') // store.unrecoverableError ?? '알 수 없는 오류입니다.'
  )

  // 해결불가 오류가 발생하면 이 페이지로 이동하게되고, 이 페이지를 떠날 때 reset 해주도록 한다.
  React.useEffect(() => {
    return () => {
      store.setUnrecoverableError(null)
    }
  }, [store])

  const titleSize = store.isMobileSize ? 'text-3xl' : 'text-5xl'

  return (
    <div
      className={'w-screen h-screen bg-white flex justify-center items-center'}
    >
      <div className={'flex flex-col items-center'}>
        <SPImage imageName={SPImageName.IcnStudioError} />
        <VSpace h={64} />
        <span className={classNames(titleSize, 'font-bold')}>
          {trans('legacy_StudioError_error_occurred')}
        </span>
        <VSpace h={8} />
        <span className={'text-base text-gray-500'}>{message}</span>
        <VSpace h={24} />
        <span
          className={'text-base text-primary-500 cursor-pointer'}
          style={{ fontWeight: 500 }}
          onClick={() => history.replace(StudioUrls.Story.List)}
        >
          {trans('legacy_StudioError_return_to_work')}
        </span>
      </div>
    </div>
  )
})
