/** Todo: 이미지는 STImages 에서 등록하여 사용한다. */

export const StudioLogo = require('./img/studio/Logo.png')
export const CombinedShape = require('./img/studio/CombinedShape.png')
export const TopBarSearchIcon = require('./img/studio/TopBarSearchIcon.png')
export const TopBarSaveIcon = require('./img/studio/SaveIcon.png')
export const IcnStar = require('./img/studio/icn_star_s.png')
export const InputEnterInActive = require('./img/studio/input_enter_nor.png')
export const InputEnterActive = require('./img/studio/input_enter_active.png')
export const IcnMenuIfChoice = require('./img/studio/icn_menu_if_choice.png')
export const IcnMenuHbMessageNext = require('./img/studio/icn_hb_message_next_block.png')
export const IcnMenuIfCondition = require('./img/studio/icn_menu_if_condition.png')
export const IcnMenuGoToBlock = require('./img/studio/icn_menu_go_to_block.png')
export const IcnCharacter = require('./img/studio/icnCharacter.png')
export const IcnCharacterActive = require('./img/studio/icnCharacterActive.png')

export const IcnMenuToast = require('./img/studio/icn_menu_toast.png')
export const IcnMenuGallery = require('./img/studio/icn_menu_gallery.png')
export const IcnMenuMusic = require('./img/studio/icn_menu_music.png')
export const IcnMenuVibration = require('./img/studio/icn_menu_vibration.png')
export const IcnMenuSave = require('./img/studio/icn_menu_save.png')
export const IcnMenuEnding = require('./img/studio/icn_menu_ending.png')

export const IcnBtnNavInfo = require('./img/studio/btn_nav_info.png')
export const IcnBtnNavQuestion = require('./img/studio/btn_nav_question.png')

export const IcnCharAdd = require('./img/studio/btn_char_add_image.png')
export const IcnProfileEdit = require('./img/studio/icn_profile_edit.png')

export const IcnMenuNovel = require('./img/studio/icn_menu_novel.png')
export const IcnSendSquare = require('./img/studio/send-square.png')
export const IcnBtnNavInfo2 = require('./img/studio/btn_nav_info2.png')
export const LogoStudio = require('./img/studio/Logo_spstudio.png')

export const IcnLoginApple = require('./img/studio/icn_login_apple.png')
export const IcnLoginGoogle = require('./img/studio/icn_login_google.png')
export const IcnLoginFacebook = require('./img/studio/icn_login_facebook.png')
export const IcnDefaultImage = require('./img/studio/defaultImg.png')
export const IcnMenuAlgorithm = require('./img/studio/icn_menu_algorithm.png')
