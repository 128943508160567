import {
  i18nTextTranslationByClass,
  StudioUrls,
  TabForStoryList,
} from '@storyplay/common'
import {
  EndingRateRangeLevel,
  GQLSTORY_PLAY_TYPE,
  ScreenSize,
  ScreenSizeToNumber,
  STORY_PROP_TYPE,
} from '@storyplay/core'
import * as Josa from 'josa-js'
import { isFunction, isString, random, startsWith } from 'lodash'
import moment from 'moment'
import { ToastOptions } from 'react-toastify'
import { GQLSTORY_PROP_TYPE } from '../@types/server'
import { ODToastType, showODToast } from '../components/ODToast'
import { BEMessageTheme } from '../hooks/useGetStyleForBEMessage'
import { ServerErrors } from '../i18n_deprecated/res'
import { STUDIO_COLORS } from '../styles/colors'
import { AppOptions } from './AppOptions'

const { trans } = i18nTextTranslationByClass()
export interface IDimensions {
  width: string | number
  height: string | number
}

const josaPairs = [
  ['[[은/는]]', '은/는'],
  ['[[이/가]]', '이/가'],
  ['[[을/를]]', '을/를'],
  ['[[와/과]]', '와/과'],
]

export function convertJosa(msg: string): string {
  let converted = msg

  for (const pairs of josaPairs) {
    const [token, checkToken] = pairs

    do {
      const josaIndex = converted.indexOf(token)
      if (josaIndex >= 1) {
        const before = converted[josaIndex - 1]
        const josa = Josa.c(before, checkToken)
        converted = converted.replace(token, josa)
      } else {
        break
      }
    } while (true)
  }

  return converted
}

export const ENDING_RATE_RANGE_LEVEL_STRING: { [key: string]: string } = {
  [EndingRateRangeLevel.Minority]: trans('legacy_utils_best_rare'),
  [EndingRateRangeLevel.VeryRarity]: trans('legacy_utils_very_rare'),
  [EndingRateRangeLevel.Rarity]: trans('legacy_utils_rare'),
  [EndingRateRangeLevel.Normal]: trans('legacy_utils_common'),
  [EndingRateRangeLevel.Unknown]: trans('legacy_utils_common'),
}

const isErrorCannotConnectToServer = (e: any) => e.message === 'Failed to fetch'
let lastServerConnectionError: number | null = null

export const STORY_PLAY_QUERY_PARAM_NAME = 'storyPlayTypeTab'
export const STORY_STATUS_QUERY_PARAM_NAME = 'storyStatusTab'

export const Utils = {
  extractGQLResponse(r: any) {
    return r.data[Object.keys(r.data)[0]]
  },
  parseErrorMessage(error: Error) {
    const graphQLErrorPrefix = 'GraphQL error: '
    return ((e) => {
      try {
        if (isErrorCannotConnectToServer(e)) {
          if (
            lastServerConnectionError &&
            lastServerConnectionError + 2000 > new Date().getTime()
          ) {
            return null // don't show duplicated error. (using throttle with rx-js would be better)
          }
          lastServerConnectionError = new Date().getTime()
          return 'Cannot connect to server.'
        }

        if (startsWith(e.message, graphQLErrorPrefix)) {
          return e.message.substring(graphQLErrorPrefix.length)
        }

        return e.message || e
      } catch (ex) {
        return e.message || e
      }
    })(error)
  },
  /**
   * 오류의 종류에 따라서 적절하게 오류를 보여준다. 해당 컨테이너/컴포넌트에서 자체 오류 처리가 없는
   * 경우 전역적으로 활용한다.
   */
  showError(
    error: Error | string,
    title: string = '',
    options: Partial<ToastOptions> = {}
  ) {
    console.error(error)

    if (isString(error)) {
      showODToast(ODToastType.Error, title, error)
      return
    }

    let msg = (Utils.parseErrorMessage(error) || 'Unknown error') as string
    if (startsWith(msg, 'SP-')) {
      const errorCode = parseInt(msg.split(' ')[0].split('SP-')[1], 10)
      const m = ServerErrors[errorCode]
      if (isFunction(m)) {
        msg = m(msg)
      } else {
        msg = (ServerErrors[errorCode] as string) || msg
      }
    }

    if (msg) {
      showODToast(ODToastType.Error, 'Error', msg, {
        ...AppOptions.TOAST_ERROR_OPTIONS,
        ...options,
      })
    }
  },
  showInfo(
    message: string,
    title: string = '',
    options: Partial<ToastOptions> = {}
  ) {
    showODToast(ODToastType.Info, title, message, {
      ...AppOptions.TOAST_INFO_OPTIONS,
      ...options,
    })
  },
  showSuccess(
    message: string,
    title: string = '',
    options: Partial<ToastOptions> = {}
  ) {
    showODToast(ODToastType.Success, title, message, {
      ...AppOptions.TOAST_SUCCESS_OPTIONS,
      ...options,
    })
  },
  formatDate(data: any): string {
    return moment(data).format('lll')
  },
  formatMonth(data: any): string {
    return moment(data).format('YYYY-MM')
  },
  noop(...args: Array<any>) {
    // nothing.
  },
  /**
   * 주어진 JSON 데이터를 파싱하나, 실패하는 경우에도 디폴트값을 반환한다.
   */
  parseJSONSafe<T>(s: string, defValue: T): T {
    try {
      return JSON.parse(s)
    } catch (ex) {
      console.warn('Parsing json failed', s)
      return defValue
    }
  },
  colorsForDecay(days: number): string {
    if (days >= -30) {
      return 'green'
    }

    if (days < -60) {
      return 'red'
    }
    return 'gray'
  },
  urlOnly(url: string) {
    return url.split('?')[0]
  },
  formatStoryPropertyType(propType: STORY_PROP_TYPE | GQLSTORY_PROP_TYPE) {
    if ((propType as unknown as STORY_PROP_TYPE) === STORY_PROP_TYPE.NUMBER) {
      return trans('const.ts_number')
    }
    return trans('const.ts_text')
  },
  convertSecToString(sec: number) {
    let remaining = Math.max(sec, 0)
    const day = Math.floor(remaining / (60 * 60 * 24))
    remaining -= day * 60 * 60 * 24
    const hour = Math.floor(remaining / (60 * 60))
    remaining -= hour * 60 * 60
    const min = Math.floor(remaining / 60)
    remaining -= min * 60

    return [
      { value: day, postfix: trans('const.ts_day') },
      { value: hour, postfix: trans('legacy_ODSChapterFreeSettingModal_hour') },
      {
        value: min,
        postfix: trans('legacy_ODSChapterFreeSettingModal_minute'),
      },
      { value: remaining, postfix: trans('legacy_utils_second') },
    ]
      .filter((v) => v.value > 0)
      .map((v) => `${v.value}${v.postfix}`)
      .join(' ')
  },
  generateSourceLine() {
    // TODO: 좀 더 정확하게 unique 한 방식이 필요하다. 가능하면 number 가 아니면 좋겠는데..
    return new Date().getTime() * 1000 + random(0, 999)
  },
  getBEMessageDefaultColor(theme: BEMessageTheme): {
    borderColor: string
    backgroundColor: string
    color: string
  } {
    switch (theme) {
      case BEMessageTheme.LeftTalk: {
        return {
          borderColor: STUDIO_COLORS.White,
          backgroundColor: STUDIO_COLORS.White,
          color: STUDIO_COLORS.Gray700,
        }
      }
      case BEMessageTheme.RightTalk: {
        return {
          borderColor: STUDIO_COLORS.Gray900,
          backgroundColor: STUDIO_COLORS.Gray900,
          color: STUDIO_COLORS.White,
        }
      }
      case BEMessageTheme.RightThink:
      case BEMessageTheme.LeftThink: {
        return {
          borderColor: STUDIO_COLORS.Gray400,
          backgroundColor: 'transparent',
          color: STUDIO_COLORS.Gray600,
        }
      }
      case BEMessageTheme.Script: {
        return {
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          color: STUDIO_COLORS.Gray700,
        }
      }
      case BEMessageTheme.FullWidthText: {
        return {
          borderColor: STUDIO_COLORS.Gray300,
          backgroundColor: 'transparent',
          color: STUDIO_COLORS.Gray900,
        }
      }
      case BEMessageTheme.FullWidthImageOverlay: {
        return {
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          color: 'black',
        }
      }
      default: {
        return {
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          color: 'transparent',
        }
      }
    }
  },
  getBlockEditorMaxWidth() {
    return 800
  },
  getBlockEditorMinWidth() {
    return 375
  },
  getStudioHeaderHeight() {
    return 74
  },
  formatDateForStory(date: Date) {
    return moment(date).format('YYYY.MM.DD HH시 mm분')
  },
  showODToastIfWorkRequired() {
    return showODToast(
      ODToastType.Info,
      ODToastType.Info,
      trans('legacy_utils_future_feature')
    )
  },
  removeEmptyText(text: string) {
    return text.replace(/(\s*)/g, '')
  },
  shouldShowProdIcon() {
    return !AppOptions.SHOW_ICON_AS_DEV
  },
  shouldShowBetaIcon() {
    return AppOptions.SHOW_BETA_ICON
  },
  getFeedFeedBackChannelLink() {
    return AppOptions.FEEDBACK_CHANNEL_LINK
  },
  jsonParseWithException(json: string | null, showError?: (ex: any) => void) {
    if (!json) {
      return null
    }
    try {
      return JSON.parse(json)
    } catch (ex) {
      console.log(`json parse error -> ${ex}`)
      showError?.(ex)
      return null
    }
  },
  getSSOLoginURL() {
    return AppOptions.SSO_LOGIN_URL
  },
  getEmailRegisterURL() {
    return AppOptions.EMAIL_REGISTER_URL
  },
  getScreenSize() {
    if (document.body.clientWidth < ScreenSizeToNumber[ScreenSize.NotMobile]) {
      return ScreenSize.Mobile
    }

    return ScreenSize.NotMobile
  },
  // 좋지 않은 방법이지만, 일단 임시 작업
  getCurrentPageNameInStory(storyId: number, pathName: string) {
    if (
      pathName.startsWith(StudioUrls.Story.Detail.ManageStory.Main(storyId))
    ) {
      return trans('legacy_SPAStoryMainPageContainer_work_management') // return 작품 관리
    } else if (
      pathName.startsWith(StudioUrls.Story.Detail.Chapter.List(storyId))
    ) {
      return trans('legacy_ChapterListPage_chapter_list') // return 회차 목록
    } else if (
      pathName.startsWith(StudioUrls.Story.Detail.Character.List(storyId))
    ) {
      return trans('legacy_ODSCharacterModal_characters') // return 등장인물
    } else if (
      pathName.startsWith(
        StudioUrls.Story.Detail.ManageBackground.List(storyId)
      )
    ) {
      return trans('legacy_BackgroundListPage_background_management') // return 배경 관리
    } else if (
      pathName.startsWith(StudioUrls.Story.Detail.ManageEnding.List(storyId))
    ) {
      return trans('legacy_EndingListPage_ending_management') // return 엔딩 관리
    } else if (
      pathName.startsWith(StudioUrls.Story.Detail.Prop.List(storyId))
    ) {
      return trans('legacy_PropList_property') // return 속성
    } else if (
      pathName.startsWith(StudioUrls.Story.Detail.ManageItem.List(storyId))
    ) {
      return trans('legacy_ItemListPage_item_management') // return 아이템 관리
    } else if (
      pathName.startsWith(StudioUrls.Story.Detail.PlayerReport.List(storyId))
    ) {
      return trans('legacy_utils_player_report') // return 플레이어 보고서
    } else if (
      pathName.startsWith(StudioUrls.Story.Detail.ManageMember.List(storyId))
    ) {
      return trans('legacy_utils_member_management') // return '멤버 관리'
    } else if (
      pathName.startsWith(StudioUrls.Story.Detail.ManagePrice.Edit(storyId))
    ) {
      return '가격 설정'
    }
  },
  getHourBySec(sec: number) {
    return Math.floor(sec / (60 * 60))
  },
  getMinBySec(sec: number) {
    return Math.floor((sec - Utils.getHourBySec(sec) * 60 * 60) / 60)
  },
  isDefined<T>(value: T | null | undefined): value is T {
    return !!value
  },
  convertTabNumToStoryPlayType(tabNum: number) {
    switch (tabNum) {
      case 1: {
        return GQLSTORY_PLAY_TYPE.Interactive
      }
      case 2: {
        return GQLSTORY_PLAY_TYPE.EPUB
      }
      default: {
        return null
      }
    }
  },
  convertTabNumToStoryStatus(tabNum: number) {
    switch (tabNum) {
      case 1: {
        return TabForStoryList.UnPublished
      }
      case 2: {
        return TabForStoryList.Finished
      }
      case 3: {
        return TabForStoryList.Sample
      }
      default: {
        return TabForStoryList.Published
      }
    }
  },
}
