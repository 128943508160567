import { jointPointSymbols } from "@storyplay/common/lib/studio/aop/index"
import type { DOSTMessageImage, IDOStudioFile, HB_MESSAGE_IMAGE_STATEMENT } from "@storyplay/common";
import { hbAdvisor } from "./hbAdvisorContainer";
import { hbApiClient } from "./advisorHbApiClient";

class DOSTMessageImageAdvisor {

  @hbAdvisor(jointPointSymbols.DOSTMessageImage.onChangeFile, 'post')
  postOnChangeFile(target: DOSTMessageImage, file: IDOStudioFile) {
    const st = target.st as HB_MESSAGE_IMAGE_STATEMENT
    hbApiClient.message.update(st.hbExtensionData.id!, { type: 'image', imageId: st.studioFileId, isBig: false }, st.hbExtensionData.algorithmId !== undefined)
  }

}

const doSTMessageImageAdvisor = new DOSTMessageImageAdvisor()

export { doSTMessageImageAdvisor }