import {
  EndBlockType,
  FEATURE_FLAG,
  FlowChartNodeType,
  IDOBlock,
  IDOSTAlgorithm,
  IFlowChartNode,
  IStudioValidator,
  SPImageName,
  StudioValidationSeverity,
} from '@storyplay/common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import {
  IcnMenuGoToBlock,
  IcnMenuIfChoice,
  IcnMenuIfCondition,
} from '../../../../assets/images'
import { useTrans } from '../../../../hooks/i18n/useTrans'
import { useContextMenu } from '../../../../hooks/useContextMenu'
import { useOuterClick } from '../../../../hooks/useOuterClick'
import { useStudioStore } from '../../di/configureRootStore'
import { IStudioDropDownRoute } from '../dropdown/StudioDropDownForRoute'
import { FlowChartDefaultBlock } from './block/FlowChartDefaultBlock'
import { FlowChartOtherGroupBlock } from './block/FlowChartOtherGroupBlock'
import { FlowChartRemoteScriptBlock } from './block/FlowChartRemoteScriptBlock'
import { HbAlgorithmBlock } from './HbAlgorithmBlock'

interface IFlowChartBlockProps {
  block: IFlowChartNode
}

export const FlowChartBlock: React.FC<IFlowChartBlockProps> = observer(
  (props) => {
    const { block: node } = props
    const store = useStudioStore()

    const [openDropDown, setOpenDropDown] = React.useState(false)
    const trans = useTrans()
    const validationTarget = node as unknown as IStudioValidator
    const lastValidationResults = validationTarget?.lastValidationResults ?? []
    const hasError = lastValidationResults.length > 0
    const severity = lastValidationResults.find(
      (v) => v.severity === StudioValidationSeverity.Error
    )
      ? StudioValidationSeverity.Error
      : lastValidationResults.find(
          (v) => v.severity === StudioValidationSeverity.Warning
        )
      ? StudioValidationSeverity.Warning
      : StudioValidationSeverity.Recommend

    const isReadOnly = store.storyEditing?.isReadOnly

    const spDropdownOptions = (() => {
      const result: IStudioDropDownRoute[] = [
        {
          routeName: trans('legacy_FlowChartBlock_create_branch'), // routeName: 분기점 만들기,
          options: [
            {
              value: EndBlockType.Choice,
              label: trans('legacy_ODSKeyboardShortcutInfoModal_option_branch'), // label: 선택지로 분기,
              imgSrc: IcnMenuIfChoice,
            },
            {
              value: EndBlockType.Condition,
              label: trans('legacy_BEConditionSubBlock_condition_branch'), // label: 조건으로 분기,
              imgSrc: IcnMenuIfCondition,
            },
          ],
        },
        {
          routeName: trans('legacy_FlowChartBlock_just_connect'), // routeName: 그냥 연결하기,
          options: [
            {
              value: EndBlockType.ToBlock,
              label: trans('legacy_ODSToBlock_scene_connection'), // label: 장면 연결,
              imgSrc: IcnMenuGoToBlock,
            },
          ],
        },
      ]

      if (store.di.isFeatureEnabled(FEATURE_FLAG.CHAT_BOT_BLOCK)) {
        result[1].options.push({
          value: EndBlockType.CallRemoteScript,
          label: trans('legacy_BECallRemoteScript_chatbot_connection'), // label: 챗봇 연결,
          imageName: SPImageName.IcnChatBotInCircle,
        })
      }

      return result
    })()

    const hbDropdownOptions: IStudioDropDownRoute[] = [
      {
        routeName: '연결하기',
        options: [
          {
            value: EndBlockType.HbMessageNext,
            label: '블록 연결',
          },
        ],
      },
    ]
    const dropdownOptions =
      store.serviceType === 'sp' ? spDropdownOptions : hbDropdownOptions

    const ref = useOuterClick(() => setOpenDropDown(false), [setOpenDropDown])
    useContextMenu(
      ref,
      (ev) => {
        if (isReadOnly) {
          return false
        }
        return store.contextMenuManager.onFlowChartBlockClicked(ev, node)
      },
      [node]
    )

    if (node.nodeType === FlowChartNodeType.Block) {
      return (
        <FlowChartDefaultBlock
          block={node as IDOBlock}
          isReadOnly={!!isReadOnly}
          containerRef={ref}
          hasError={hasError}
          severity={severity}
          openDropDown={openDropDown}
          setOpenDropDown={setOpenDropDown}
          dropdownOptions={dropdownOptions}
        />
      )
    }

    if (node.nodeType === FlowChartNodeType.RemoteScriptSubBlock) {
      return (
        <FlowChartRemoteScriptBlock
          containerRef={ref}
          hasError={hasError}
          severity={severity}
        />
      )
    }

    if (node.nodeType === FlowChartNodeType.NodeOtherGroupBlock) {
      return <FlowChartOtherGroupBlock containerRef={ref} node={node} />
    }

    if (node.nodeType === FlowChartNodeType.AlgorithmBlock) {
      return <HbAlgorithmBlock algorithmMessage={node as IDOSTAlgorithm} />
    }

    return <div>Unknown node type : {node.nodeType}</div>
  }
)
