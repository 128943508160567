import {
  IStudioMenuSeparator,
  IStudioMenuTextWithIcon,
  StudioMenu,
  StudioMenuType,
} from '@storyplay/common'
import { observer } from 'mobx-react-lite'
import React from 'react'
import Popup from 'reactjs-popup'
import { useOuterClick } from '../../../../hooks/useOuterClick'
import { useStudioStore } from '../../di/configureRootStore'
import './StudioContextMenu.css'

const MenuTextWithIcon: React.FC<{ menu: IStudioMenuTextWithIcon }> = ({
  menu,
}) => {
  if (!menu.subMenus || menu.subMenus.length === 0) {
    return (
      <div className="studio-menu-item" onClick={menu.onClick}>
        {menu.text}
      </div>
    )
  }
  return (
    <Popup
      trigger={<div className="studio-menu-item">{menu.text}</div>}
      position="right top"
      keepTooltipInside
      on="hover"
      closeOnDocumentClick
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ padding: '0px', border: 'none' }}
      arrow={false}
    >
      <div className="studio-menu">
        {menu.subMenus.map((m, index) => (
          <MenuItem key={index} menu={m} />
        ))}
      </div>
    </Popup>
  )
}

const MenuSeparator: React.FC<{ menu: IStudioMenuSeparator }> = ({ menu }) => {
  return <div className="studio-menu-item">-</div>
}

const MenuItem: React.FC<{ menu: StudioMenu }> = ({ menu }) => {
  if (menu.type === StudioMenuType.TextWithIcon) {
    return <MenuTextWithIcon menu={menu} />
  }

  if (menu.type === StudioMenuType.Separator) {
    return <MenuSeparator menu={menu} />
  }

  return <div>{menu.type}</div>
}

export const StudioContextMenu: React.FC = observer(() => {
  const man = useStudioStore().contextMenuManager
  const menu = man.menu

  const menuRootRef = useOuterClick(() => man.closeMenu(), [man])

  if (!menu) {
    return null
  }

  return (
    <div
      className="studio-menu"
      style={{ left: menu.x, top: menu.y }}
      ref={menuRootRef}
    >
      {menu.menus.map((m, index) => (
        <MenuItem menu={m} key={index} />
      ))}
    </div>
  )
})
