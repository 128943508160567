export const LOCAL_STORAGE_KEY_TOKEN = 'token'
export const LOCAL_STORAGE_KEY_TOKEN_EXPIRE_AT = 'tokenExpireAt' // epoch
export const TOKEN_EXPIRE_AFTER_SEC_WRITER = 60 * 60 * 12
export const TOKEN_EXPIRE_AFTER_SEC_ADMIN = 60 * 30 * 48

export enum STORY_SECTION_TYPE {
  OnStage = 'OnStage', // 정식작품
  Challenge = 'Challenge', // 도전 스토리
  Monthly = 'Monthly', // 월간 대표작
}

export enum ENDING_RATE_RANGE_LEVEL {
  Unknown = 'Unknown', // 알수없음
  Normal = 'Normal', // 보통
  Rarity = 'Rarity', // 희소
  VeryRarity = 'VeryRarity', // 매우 희소
  Minority = 'Minority', //최고 희소
}

export enum STORY_ENDING_ARRIVAL_RATE_TYPE {
  Unknown = 'Unknown',
  RealValue = 'RealValue',
  SettingValue = 'SettingValue',
  InVisible = 'InVisible',
}

export enum ENDING_CARD_TYPE {
  ArrivalRate = 'ArrivalRate', // 최종 엔딩 도착률
  ChoiceCount = 'ChoiceCount', // 선택의 기로 횟수
  EndingCollectRate = 'EndingCollectRate', // 작품 엔딩 수집률
}

export enum ONBOARDING_POPUP_TYPE {
  Text = 'Text', // 설명 팝업
  Choice = 'Choice', // 선택의 기로 횟수
  Profile = 'Profile', // 프로필 팝업
  Name = 'Name',
  OtherName = 'OtherName', // 상대역 이름 팝업
}

export enum STORY_TYPE {
  Novel = 'Novel',
  Chat = 'Chat',
}

export enum STATUS {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
}

export interface IAspectRatio {
  w: number
  h: number
}
