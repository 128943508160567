import { I18nFileName } from '@storyplay/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { AppOptions } from '../../utils/AppOptions'

export const useTrans = () => {
  const serviceType: I18nFileName = AppOptions.USE_HELLOBOT ? 'hb' : 'sp'
  const { t } = useTranslation()

  return React.useCallback(
    (key: string, values?: Record<string, string | number> | undefined) => {
      return t(`${serviceType}.${key}`, values)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
}
