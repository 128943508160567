import { ButtonPaddingSize, ButtonTheme } from '@storyplay/common'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled, { css } from 'styled-components'
import { STUDIO_COLORS } from '../../../../../styles/colors'

export type ButtonType = 'button' | 'submit' | 'reset' | undefined

interface IBEButton {
  text: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  style?: React.CSSProperties
  theme?: BEButtonTheme | ButtonTheme
  active?: boolean // 외부에서 호버, 액티브 이외의 상황에서 active 상태를 표현하고 싶을 때
  buttonType?: ButtonType
  leftImage?: React.ReactNode
  rightImage?: React.ReactNode
  rightText?: React.ReactNode
  // renderLeftImage 는 함수가 리렌더시마다 재생성되므로 사용하지 않도록 하겠습니다.
  // 위의 leftImage 를 사용
  renderLeftImage?: () => React.ReactNode
  isSizingByPadding?: boolean
  className?: string
  buttonPaddingSize?: ButtonPaddingSize
  testId?: string
  isFullWidth?: boolean
  isRounded?: boolean
  backgroundColor?: string
}

export enum BEButtonTheme {
  General = 'General',
  Disabled = 'Disabled',
  Filled = 'Filled',
  RedOutlined = 'RedOutlined',
  PrimaryOutlined = 'PrimaryOutlined',
  GreyFilled = 'GreyFilled',
  RedFilled = 'RedFilled',
  OnlyTextFilled = 'OnlyTextFilled',
  Primary100Filled = 'Primary100Filled',
  GrayOutline = 'GrayOutline',
}

export const BEButton: React.FC<IBEButton> = observer((props) => {
  const {
    text,
    onClick,
    style,
    theme = BEButtonTheme.General,
    active = false,
    buttonType = 'button',
    leftImage,
    rightText,
    renderLeftImage,
    isSizingByPadding = false,
    className = '',
    buttonPaddingSize,
    testId,
    isFullWidth = false,
    rightImage,
    isRounded = false,
    backgroundColor,
  } = props

  const customStyle = isSizingByPadding
    ? style || {}
    : { width: 84, height: 36, ...style }

  return (
    <Button
      data-test-id={testId}
      className={classNames(
        className,
        'flex justify-center items-center focus-visible:ring text-sm rounded-md font-bold flex-shrink-0',
        {
          'border-1 border-primary-300 text-primary-500 font-bold hover:border-primary-500':
            theme === BEButtonTheme.PrimaryOutlined,
          'bg-danger-primary500 font-bold text-white':
            theme === BEButtonTheme.RedFilled,
          'bg-primary-100 shadow-sm text-primary-500':
            theme === BEButtonTheme.Primary100Filled,
          'border-1 border-gray-400 text-gray-400 font-bold hover:border-gray-500':
            theme === BEButtonTheme.GrayOutline,
          'text-primary-500': theme === BEButtonTheme.OnlyTextFilled,
          'as:px-6 as:py-2': buttonPaddingSize === ButtonPaddingSize.PX6PY2,
          'as:px-6 as:py-3': buttonPaddingSize === ButtonPaddingSize.PX6PY3,
          'as:px-7.5 as:py-2': buttonPaddingSize === ButtonPaddingSize.PX75PY2,
          'as:px-12 as:py-2': buttonPaddingSize === ButtonPaddingSize.PX12PY2,
          'as:px-11 as:py-3': buttonPaddingSize === ButtonPaddingSize.PX11PY3,
          'as:py-3': buttonPaddingSize === ButtonPaddingSize.PY3,
          'w-full': isFullWidth,
          'rounded-3xl': isRounded,
        }
      )}
      style={customStyle}
      theme={theme}
      active={active}
      onClick={(e) => {
        if (theme === BEButtonTheme.Disabled) {
          return false
        }
        onClick?.(e)
      }}
      type={buttonType}
      backgroundColor={backgroundColor}
    >
      {leftImage}
      {!!renderLeftImage && renderLeftImage()}
      <span>{text}</span>
      {rightText}
      {rightImage}
    </Button>
  )
})

const Button = styled.button<{
  theme: BEButtonTheme
  active: boolean
  backgroundColor?: string
}>`
  text-align: center;

  ${(p: { theme: BEButtonTheme; active: boolean; backgroundColor?: string }) =>
    p.theme === BEButtonTheme.General &&
    css`
      background-color: ${p.backgroundColor ?? 'white'};
      border: 1px solid ${STUDIO_COLORS.Gray200};
      color: ${p.backgroundColor ?? STUDIO_COLORS.Gray700};
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);

      &:hover,
      &:active {
        border: 1px solid ${STUDIO_COLORS.Primary500};
        color: ${STUDIO_COLORS.Primary500};
      }

      ${p.active &&
      css`
        border: 1px solid ${STUDIO_COLORS.Primary500};
        color: ${STUDIO_COLORS.Primary500};
      `}
    `}

  ${(p: { theme: BEButtonTheme; active: boolean }) =>
    p.theme === BEButtonTheme.Filled &&
    css`
      background-color: ${STUDIO_COLORS.Primary500};
      color: white;

      &:hover,
      &:active {
        background-color: ${STUDIO_COLORS.PrimaryDark};
      }

      ${p.active &&
      css`
        background-color: ${STUDIO_COLORS.PrimaryDark};
      `}
    `}

  ${(p: { theme: BEButtonTheme }) =>
    p.theme === BEButtonTheme.Disabled &&
    css`
      border: 0;
      background-color: ${STUDIO_COLORS.Gray500};
      color: white;
      cursor: not-allowed;
      pointer-events: none;
    `}

  ${(p: { theme: BEButtonTheme; backgroundColor?: string }) =>
    p.theme === BEButtonTheme.RedOutlined &&
    css`
      background-color: ${p.backgroundColor ?? 'white'};
      border: 1px solid ${STUDIO_COLORS.DangerPrimary500};
      color: ${STUDIO_COLORS.DangerPrimary500};
    `}

  ${(p: { theme: BEButtonTheme }) =>
    p.theme === BEButtonTheme.GreyFilled &&
    css`
      background-color: ${STUDIO_COLORS.Gray200};
      border-radius: 12px;
      font-weight: 500;
      color: ${STUDIO_COLORS.Gray600};
    `}
`
