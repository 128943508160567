import { SPImageName } from '@storyplay/common'
import React from 'react'
import styled from 'styled-components'
import { SPImage } from '../../../../assets/SPImages'
import { STUDIO_COLORS } from '../../../../styles/colors'

interface IHbAlgorithmBlockBadgeProps {}

export const HbAlgorithmBlockBadge: React.FC<IHbAlgorithmBlockBadgeProps> = (
  props
) => {
  return (
    <Container className="border-primary-300 border-1 flex items-center space-x-2">
      <IconContainer className="bg-primary-100 primary">
        <SPImage
          imageName={SPImageName.IcnHbAlgorithmBlock}
          style={{ fill: STUDIO_COLORS.Primary500 }}
        />
      </IconContainer>
      <span
        className="text-primary-500 font-bold font-sm"
        style={{ marginTop: 2 }}
      >
        알고리즘 블록&nbsp;&nbsp;
      </span>
    </Container>
  )
}

const Container = styled.div`
  border-radius: 24px;
  height: 32px;
  padding: 3px;
`

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`
