import React, { DependencyList, useEffect, useRef } from 'react'

/**
 * 우클릭시에 호출되는 hook.
 *
 * ref 를 null 로 주면 생성한 것을 반환한다. (좀 더 쉽게 사용 가능)
 * onClick 이 true 를 반환하면 기본 컨텍스트 메뉴는 뜨지 않는다.
 * 또한 onClick 은 dependency list 에 기본으로 포함되어 있지 않으므로, 외부에서 알아서 사용하도록 한다.
 */
export function useContextMenu(
  ref: React.MutableRefObject<HTMLElement | null> | null,
  onClick: (ev: PointerEvent) => boolean,
  deps: DependencyList
): React.RefObject<any> {
  const innerRef = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const parent = ref ? ref.current : innerRef.current
    if (!parent) {
      return
    }

    const handler = (e: any) => {
      const res = onClick(e)
      if (res) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    parent.addEventListener('contextmenu', handler)
    return () => {
      parent.removeEventListener('contextmenu', handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [innerRef.current, ref, ...deps])

  return innerRef
}
