import { SPImageName, StudioValidationSeverity } from '@storyplay/common'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { SPImage } from '../../../../../assets/SPImages'
import { ErrorMessage } from '../../common/ErrorMessage'

interface IProps {
  containerRef: React.MutableRefObject<any>
  hasError: boolean
  severity: StudioValidationSeverity
}

export const FlowChartRemoteScriptBlock = observer((props: IProps) => {
  const { containerRef, hasError, severity } = props
  return (
    <Container
      ref={containerRef}
      className={classNames(
        'border-chatBot-100 bg-chatBot-50',
        'relative w-full flex flex-wrap justify-center items-center w-full border-1 space-y-1',
        'border-gray-300 bg-gray-200'
      )}
      style={{ padding: 8 }}
    >
      <SPImage
        imageName={SPImageName.IcnChatBotInCircle}
        className={'mr-1.5'}
      />
      <Text
        className={classNames('relative -top-px text-gray-900 font-medium')}
      >
        챗봇 블록
      </Text>
      {hasError && (
        <ErrorMessage
          errorMessage={''}
          severity={severity}
          isShowOnlyErrorIcon
          iconStyle={{ marginLeft: 4, top: -1 }}
        />
      )}
    </Container>
  )
})

const Container = styled.div`
  width: 100%;
  min-width: 180px;
  max-width: 180px;
  padding: 8px;
  min-height: 56px;
  border-radius: 8px;
`

const Text = styled.span`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.3px;
`
