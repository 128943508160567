import { jointPointSymbols } from "@storyplay/common/lib/studio/aop";
import { hbAdvisor } from "./hbAdvisorContainer";
//we need to use import type to use these types on decorated functions
import type { IBubbleStatement, StudioChangeOp } from '@storyplay/common';
import { CHR_TALK_STATEMENT, DOSTBubbleWithChrBase, HbApiClient, IStudioChangeOpResult, StudioChangeActionType, StudioChangeOpType } from "@storyplay/common";
import { HB_CHR_TALK_STATEMENT } from "@storyplay/common/lib/hellobot/types/statement";
import { RESTAPIClient } from "../../containers/studio/di/client/RESTAPIClient";
import { AppOptions } from "../../utils/AppOptions";
import { HbMessage, HbMessageText } from "@storyplay/core";

const api = new HbApiClient(new RESTAPIClient(
  async () => localStorage.getItem(AppOptions.LOCAL_STORAGE_KEY_TOKEN),
  AppOptions.SERVER_ADDRESS
))

class DOSTBubbleBaseAdvisor {

  @hbAdvisor(jointPointSymbols.DOSTBubbleBase.applyChangeOp, 'pre')
  preApplyChangeOp(target: IBubbleStatement, op: StudioChangeOp, type: StudioChangeActionType) {
  }

  @hbAdvisor(jointPointSymbols.DOSTBubbleBase.applyChangeOp, 'post')
  async postApplyChangeOp(target: IBubbleStatement, res: IStudioChangeOpResult | null, op: StudioChangeOp, type: StudioChangeActionType) {
    const convertedTarget = target as unknown as DOSTBubbleWithChrBase<CHR_TALK_STATEMENT>
    const hbStatement = convertedTarget.data as HB_CHR_TALK_STATEMENT
    console.log('hbStatement', hbStatement)
    const originalHbMessage: HbMessage<HbMessageText> = hbStatement.hbExtensionData as HbMessage<HbMessageText>
    if (res && originalHbMessage) {
      if (op.opType === StudioChangeOpType.ChangeMessage) {
        if (originalHbMessage.sheetId !== undefined) {
          await api.message.update(originalHbMessage.id!, { type: 'text', text: op.message })
        } else if (originalHbMessage.algorithmId !== undefined) {
          await api.message.update(originalHbMessage.id!, { type: 'text', text: op.message }, true)
        }
      }
    }
  }

}

const doStBubbleBaseAdvisor = new DOSTBubbleBaseAdvisor()

export { doStBubbleBaseAdvisor };
