import classNames from 'classnames'
import React from 'react'

interface ILoading {
  containerHeight?: number
  size?: number
  text?: string
  className?: string
}

export const AnimationLoading: React.FC<ILoading> = (props) => {
  const { size = 32, text, containerHeight, className = '' } = props
  return (
    <div
      className={classNames(
        className,
        'flex justify-center items-center space-x-3'
      )}
      style={{ height: containerHeight }}
    >
      <div
        className={`animate-spin rounded-full border-b-2 border-primary-500`}
        style={{
          width: size,
          height: size,
        }}
      />
      {!!text && <span className={'text-sm text-primary-500'}>{text}</span>}
    </div>
  )
}
