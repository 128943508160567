import { SPImageName } from '@storyplay/common'
import React from 'react'
import { SPImageInfo, SPImageType } from './SPImageInfo'

interface ISPImageProps {
  imageName: SPImageName
  style?: React.CSSProperties
  className?: string
  hasPreventDefault?: boolean
}

export const SPImage: React.FC<ISPImageProps> = React.memo((props) => {
  const { imageName, style, className = '', hasPreventDefault = false } = props
  const imageInfo = SPImageInfo[imageName]
  const { width, height } = imageInfo

  const customStyle = React.useMemo(() => {
    return {
      width,
      height,
      ...style,
    }
  }, [width, height, style])

  if (imageInfo.imageType === SPImageType.Svg) {
    const { image: Image } = imageInfo

    return (
      <Image
        style={customStyle}
        className={className}
        onClick={(e) => {
          if (hasPreventDefault) {
            e.preventDefault()
          }
        }}
      />
    )
  }

  if (imageInfo.imageType === SPImageType.ImageFile) {
    const { image } = imageInfo

    return (
      <img
        src={image}
        style={customStyle}
        className={className}
        alt={`st-${imageName}`}
        onClick={(e) => {
          if (hasPreventDefault) {
            e.preventDefault()
          }
        }}
      />
    )
  }

  return <span>'이미지를 등록해 주세요.'</span>
})
