import React from 'react'
import { STUDIO_COLORS } from '../styles/colors'
import { Utils } from '../utils/utils'

export enum BEMessageTheme {
  // Talk
  LeftTalk = 'LeftTalk',
  LeftThink = 'LeftThink',
  RightTalk = 'RightTalk',
  RightThink = 'RightThink',

  // 지문
  Script = 'Script',

  // 소설 지문
  FullWidthText = 'FullWidthText',
  // 삽화 이미지에 포함될 수 있는 오버레이 텍스트
  FullWidthImageOverlay = 'FullWidthImageOverlay',
}

export enum BEMessageStatus {
  Error = 'Error',
}

interface IGetStyleForBEMessageResponse extends React.CSSProperties {
  borderColor: string
  backgroundColor: string
  color: string
}

/**
 * 블록에디터에 표시되는 메시지(Statement?)의 스타일을 관리
 * 목록은 BEMessageTheme 을 확인
 * 반환되는 color 들이 전부 'transparent' 라면 theme Utils.getBEMessageDefaultColor 에 theme 에 해당하는 컬러를 등록 필요
 */
export function useGetStyleForBEMessage(
  theme: BEMessageTheme,
  status?: BEMessageStatus | null
): IGetStyleForBEMessageResponse {
  return React.useMemo(() => {
    const { borderColor, backgroundColor, color } =
      Utils.getBEMessageDefaultColor(theme)

    const style: IGetStyleForBEMessageResponse = {
      borderColor: borderColor,
      backgroundColor: backgroundColor,
      color: color,
    }

    style.fontSize = '14px'
    style.lineHeight = '20px'
    style.letterSpacing = '-0.3px'

    style.borderWidth = '1px'
    style.borderStyle = 'solid'

    switch (theme) {
      case BEMessageTheme.Script: {
        style.padding = 12
        style.borderRadius = 8
        break
      }
      case BEMessageTheme.FullWidthImageOverlay: {
        style.width = '100%'
        break
      }
      case BEMessageTheme.FullWidthText: {
        style.width = '100%'

        style.padding = 20
        style.borderRadius = 8

        if (status === BEMessageStatus.Error) {
          style.borderColor = STUDIO_COLORS.PointRedHeart
        }
        break
      }
    }

    /** theme 의 공통적인 스타일이 있을 경우 및 에러, 수정 관련의 경우 if 문으로 처리 */
    if (isTalkTheme(theme)) {
      style.minHeight = '44px'
      style.padding = '12px'

      if (isLeftTalkTheme(theme)) {
        style.borderRadius = '0px 8px 8px 8px'
      }

      if (isRightTalkTheme(theme)) {
        style.borderRadius = '8px 0px 8px 8px'
      }

      // status 가 있을 경우 기존 설정된 color 들을 무시
      if (status === BEMessageStatus.Error) {
        style.borderColor = STUDIO_COLORS.PointRedHeart
        style.backgroundColor = STUDIO_COLORS.White
        style.color = STUDIO_COLORS.Gray700
      }
    }

    return style
  }, [status, theme])
}

const listOfTalkTheme = [
  BEMessageTheme.LeftTalk,
  BEMessageTheme.LeftThink,
  BEMessageTheme.RightTalk,
  BEMessageTheme.RightThink,
]

function isTalkTheme(theme: BEMessageTheme) {
  return listOfTalkTheme.includes(theme)
}

const listOfLeftTalkTheme = [BEMessageTheme.LeftTalk, BEMessageTheme.LeftThink]

function isLeftTalkTheme(theme: BEMessageTheme) {
  return listOfLeftTalkTheme.includes(theme)
}

const listOfRightTalkTheme = [
  BEMessageTheme.RightTalk,
  BEMessageTheme.RightThink,
]

function isRightTalkTheme(theme: BEMessageTheme) {
  return listOfRightTalkTheme.includes(theme)
}
