import { ErrorCode, IAPIClient, SPCError } from '@storyplay/common'
import { IFileDefinition } from '@storyplay/core'
import { ApolloClient } from 'apollo-client'
import gql from 'graphql-tag'
import { isArray } from 'lodash'
import { createApolloClient } from '../../../../apolloClientUtils'

export class StudioAPIClient implements IAPIClient {
  private client: ApolloClient<any>
  private readonly serverAddress: string

  constructor(
    getAuthToken: () => Promise<string | null>,
    serverAddress: string
  ) {
    const { client } = createApolloClient(getAuthToken, serverAddress)
    this.client = client
    this.serverAddress = serverAddress
  }

  async mutate<
    INPUT_TYPE extends object,
    OUTPUT_TYPE extends object,
    ADDITIONAL_VARS extends object = {}
  >(
    query: string,
    keyOfFiles: Array<IFileDefinition<INPUT_TYPE>>,
    data: INPUT_TYPE,
    operationName: string = 'unnamed_mutation',
    additionalVariables?: ADDITIONAL_VARS
  ): Promise<{ data: OUTPUT_TYPE }> {
    const modifiedData = {
      ...data,
    }

    for (const { fieldName: key } of keyOfFiles) {
      if (modifiedData.hasOwnProperty(key)) {
        const fieldData = modifiedData[key]
        if (isArray(fieldData)) {
          // @ts-ignore
          modifiedData[key] = await Promise.all(fieldData.map((f, index) => f))
        } else {
          // @ts-ignore
          // modifiedData[key] = await generateRNFile(fieldData, mimeType, `file-${Date.now()}`)
          modifiedData[key] = fieldData
        }
      }
    }

    const variables = {
      data: modifiedData,
      ...additionalVariables,
    }

    try {
      const r = await this.client.mutate<OUTPUT_TYPE, { data: INPUT_TYPE }>({
        context: {
          uri: `${this.serverAddress}/graphql/${operationName}`,
        },
        mutation: gql(query),
        variables,
        errorPolicy: 'none',
      })
      return { data: r.data! }
    } catch (ex: any) {
      if (ex.message.includes('SP-')) {
        const code = parseInt(
          ex.message.split('SP-')[1]?.split(' ')?.[0] ?? '-1',
          10
        )
        if (!isNaN(code)) {
          throw new SPCError(code)
        }
      }
      const err = new SPCError(ErrorCode.FATAL)
      // @ts-ignore
      err.origin = ex
      throw err
    }
  }

  async query<
    INPUT_TYPE extends object,
    OUTPUT_TYPE extends object,
    ADDITIONAL_VARS extends object = {}
  >(
    query: string,
    data: INPUT_TYPE,
    operationName: string = 'unnamed_query',
    additionalVariables?: ADDITIONAL_VARS
  ): Promise<{ data: OUTPUT_TYPE }> {
    const variables = {
      data,
      ...additionalVariables,
    }
    try {
      const r = await this.client.query<OUTPUT_TYPE, { data: INPUT_TYPE }>({
        context: {
          uri: `${this.serverAddress}/graphql/${operationName}`,
        },
        query: gql(query),
        variables,
        errorPolicy: 'none',
      })
      return { data: r.data! }
    } catch (ex: any) {
      if (ex.message.includes('SP-')) {
        const code = parseInt(
          ex.message.split('SP-')[1]?.split(' ')?.[0] ?? '-1',
          10
        )
        if (!isNaN(code)) {
          throw new SPCError(code)
        }
      }
      const err = new SPCError(ErrorCode.FATAL)
      // @ts-ignore
      err.origin = ex
      throw err
    }
  }
}
