import { GNBStyleLayout, HbStudioUrls, StudioUrls } from '@storyplay/common'
import { ScreenSize } from '@storyplay/core'
import { observer } from 'mobx-react-lite'
import React, { lazy } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { SPScreenSizeLoading } from '../../components/loading/SPScreenSizeLoading'
import { RequireLogin } from '../../components/RequireLogin'
import { useRouteChangeTrackerForGA } from '../../hooks/ga/useRouteChangeTrackerForGA'
import { Utils } from '../../utils/utils'
import { setHistory, useStudioStore } from '../studio/di/configureRootStore'
import { StudioContextMenu } from '../studio/ui/common/StudioContextMenu'
import { useGlobalTitleStore } from '../studio/useGlobalTitle'
import { StudioError } from '../StudioError'

const BuildInfo = lazy(() =>
  import('../../components/BuildInfo').then((module) => ({
    default: module.BuildInfo,
  }))
)
const SPMGnbMenu = lazy(() =>
  import('../../components/menu/gnb/SPMGnbMenu').then((module) => ({
    default: module.SPMGnbMenu,
  }))
)
const ODSGNBModalRenderer = lazy(() =>
  import('../../components/menu/ODSGNBModalRenderer').then((module) => ({
    default: module.ODSGNBModalRenderer,
  }))
)

const StoryRoot = lazy(() =>
  import('./StoryRoot').then((module) => ({
    default: module.StoryRoot,
  }))
)
const AdminRoot = lazy(() =>
  import('../admin/AdminRoot').then((module) => ({
    default: module.AdminRoot,
  }))
)
const ChatBotRoot = lazy(() =>
  import('./ChatBotRoot').then((module) => ({
    default: module.ChatBotRoot,
  }))
)

const StudioLoginContainer = lazy(() =>
  import('../studioLoginContainer/StudioLoginContainer').then((module) => ({
    default: module.StudioLoginContainer,
  }))
)
const ViewTermsContainer = lazy(() =>
  import('../ViewTermsContainer').then((module) => ({
    default: module.ViewTermsContainer,
  }))
)
const AutoLayoutContainer = lazy(() =>
  import('../../autoLayout/AutoLayoutContainer').then((module) => ({
    default: module.AutoLayoutContainer,
  }))
)
const EditAuthorInfoPage = lazy(() =>
  import('../../components/EditAuthorInfoPage').then((module) => ({
    default: module.EditAuthorInfoPage,
  }))
)
const EditCompletedAuthorPage = lazy(() =>
  import('../../components/EditCompletedAuthorPage').then((module) => ({
    default: module.EditCompletedAuthorPage,
  }))
)
const PauseAccountPage = lazy(() =>
  import('../../components/PauseAccountPage').then((module) => ({
    default: module.PauseAccountPage,
  }))
)
const ChangePasswordContainer = lazy(() =>
  import('../ChangePasswordContainer').then((module) => ({
    default: module.ChangePasswordContainer,
  }))
)
const FinishResetPasswordContainer = lazy(() =>
  import('../resetPassword/FinishResetPasswordContainer').then((module) => ({
    default: module.FinishResetPasswordContainer,
  }))
)
const ResetPasswordContainer = lazy(() =>
  import('../resetPassword/ResetPasswordContainer').then((module) => ({
    default: module.ResetPasswordContainer,
  }))
)
const StoryListContainer = lazy(() =>
  import('../StoryListContainer').then((module) => ({
    default: module.StoryListContainer,
  }))
)
const StudioLogoutContainer = lazy(() =>
  import('../StudioLogoutContainer').then((module) => ({
    default: module.StudioLogoutContainer,
  }))
)
const StudioReleaseVersionContainer = lazy(() =>
  import('../StudioReleaseVersionContainer').then((module) => ({
    default: module.StudioReleaseVersionContainer,
  }))
)

interface IStudioRootProps {}

export const StudioRoot: React.FC<IStudioRootProps> = observer((props) => {
  const store = useStudioStore()
  const history = useHistory()

  useRouteChangeTrackerForGA()

  const globalTitleStore = useGlobalTitleStore()

  React.useEffect(() => {
    setHistory(history)

    return () => {
      setHistory(null)
    }
  }, [history])

  // 공모전이 진행중인지 체크합니다.
  React.useEffect(() => {
    store.gnbStore.eventManager.loadCurrentEvent()
  }, [store, store.loginStore.user])

  React.useEffect(() => {
    const getScreenSize = function () {
      const screenSize = store.screenSize
      const currentScreenSize = Utils.getScreenSize()

      if (screenSize !== currentScreenSize) {
        store.setScreenSize(currentScreenSize)
      }
      if (screenSize === ScreenSize.Mobile) {
        store.chapterEditing?.blockEditor?.input.setWidth(
          document.body.clientWidth
        )
      }
    }

    getScreenSize()
    window.addEventListener('resize', getScreenSize)

    return () => {
      window.removeEventListener('resize', getScreenSize)
    }
  }, [store])

  React.useEffect(() => {
    if (store.loginStore.user?.isSameOrGreaterThanAdmin) {
      store.getServiceLanguages()
      store.getServiceCountries()
    }
  }, [store, store.loginStore.user])

  return (
    <React.Suspense fallback={<SPScreenSizeLoading />}>
      <Switch>
        <Route
          exact
          path={StudioUrls.Login}
          render={(props: any) => <StudioLoginContainer {...props} />}
        />
        <Route
          exact
          path={StudioUrls.ViewTerms}
          render={() => <ViewTermsContainer />}
        />
        <Route
          exact
          path={StudioUrls.EditAuthorInfo}
          render={() => {
            return (
              <RequireLogin doNotCheckIsRequiredAuthor>
                <EditAuthorInfoPage />
              </RequireLogin>
            )
          }}
        />
        <Route
          exact
          path={StudioUrls.FirstEditCompletedAuthorInfo}
          render={() => {
            return (
              <RequireLogin>
                <EditCompletedAuthorPage />
              </RequireLogin>
            )
          }}
        />
        <Route
          exact
          path={StudioUrls.BannedAccount}
          render={(props: any) => <PauseAccountPage {...props} />}
        />
        <Route
          path={StudioUrls.Logout}
          render={() => (
            <StudioLogoutContainer urlAfterLogout={StudioUrls.Login} />
          )}
        />
        <Route
          exact
          path={StudioUrls.ResetPassword}
          render={() => <ResetPasswordContainer />}
        />
        <Route
          path={StudioUrls.FinishResetPassword}
          render={() => <FinishResetPasswordContainer />}
        />
        <Route
          path={StudioUrls.ChangePassword}
          render={() => (
            <RequireLogin>
              <ChangePasswordContainer />
            </RequireLogin>
          )}
        />
        <Route
          path={StudioUrls.ReleaseVersion}
          render={() => {
            return (
              <RequireLogin>
                <StudioReleaseVersionContainer />
              </RequireLogin>
            )
          }}
        />
        <Route
          path={StudioUrls.Story.List}
          exact
          render={() => (
            <RequireLogin>
              <StoryListContainer />
            </RequireLogin>
          )}
        />
        <Route
          path={StudioUrls.Story.Detail.Root(':storyId')}
          render={(props: any) => {
            return (
              <RequireLogin>
                <StoryRoot
                  storyId={parseInt(props.match.params.storyId, 10)}
                  pathName={props.location.pathname}
                  globalTitleStore={globalTitleStore}
                />
              </RequireLogin>
            )
          }}
        />
        <Route
          path={HbStudioUrls.Bot.Detail.Root(':botId')}
          render={(props: any) => {
            return (
              <RequireLogin>
                <ChatBotRoot
                  botId={parseInt(props.match.params?.botId, 10)}
                  pathName={props.location.pathname}
                  globalTitleStore={globalTitleStore}
                />
              </RequireLogin>
            )
          }}
        />
        <Route
          path={StudioUrls.Admin.Root}
          render={(props: any) => {
            return (
              <RequireLogin>
                <AdminRoot />
              </RequireLogin>
            )
          }}
        />
        {/* Story NotFound Page */}
        <Route
          exact
          path={StudioUrls.Story.NotFound}
          render={() => <StudioError />}
        />
        <Route
          exact
          path={StudioUrls.GlobalError}
          render={() => <StudioError />}
        />
        <Redirect to={StudioUrls.Main} />
      </Switch>
      <AutoLayoutContainer man={store.autoLayoutManager} />
      <StudioContextMenu />
      <BuildInfo />
      <ODSGNBModalRenderer />
      {store.gnbStore.menuManager.isOpen &&
        store.gnbStore.currentLayout !== GNBStyleLayout.EditChapter && (
          <SPMGnbMenu
            onClose={() => store.gnbStore.menuManager.setIsOpen(false)}
          />
        )}
    </React.Suspense>
  )
})
