import { SPImageName } from '@storyplay/common'
import React from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { ReactComponent as LogoSPLoading } from '../../assets/img/studio/svgs/icn_logo_sp_loading.svg'
import { SPImage } from '../../assets/SPImages'
import { useServiceType } from '../../hooks/useServiceType'
import { AnimationLoading } from './AnimationLoading'

interface ISPScreenSizeLoadingProps {}

export const SPScreenSizeLoading: React.FC<ISPScreenSizeLoadingProps> = (
  props
) => {
  const isHelloBot = useServiceType() === 'hb'

  return ReactDOM.createPortal(
    <Wrapper className={'fixed inset-0 flex justify-center items-center'}>
      <AnimationLoading size={110} />
      <LogoWrapper className={'flex justify-center items-center rounded-full'}>
        {isHelloBot ? (
          <SPImage imageName={SPImageName.IcnLlamama} />
        ) : (
          <LogoSPLoading />
        )}
      </LogoWrapper>
    </Wrapper>,
    document.body
  )
}

const Wrapper = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 9999;
`

const LogoWrapper = styled.div`
  background-color: rgba(117, 59, 189, 0.2);
  position: fixed;
  width: 100px;
  height: 100px;
`
