import React from 'react'
import { ReactChannelIO } from 'react-channel-plugin'
import { AppOptions } from '../utils/AppOptions'

interface IReactChannelIOWrapperProps {}

const CHANNEL_IO_PLUGIN_KEY = AppOptions.CHANNEL_IO_PLUGIN_KEY

export const ReactChannelIOWrapper: React.FC<IReactChannelIOWrapperProps> = (
  props
) => {
  if (!!CHANNEL_IO_PLUGIN_KEY) {
    return (
      <ReactChannelIO
        pluginKey={CHANNEL_IO_PLUGIN_KEY}
        language={'ko'}
        verbose={false}
      >
        {props.children}
      </ReactChannelIO>
    )
  }

  return <>{props.children}</>
}
