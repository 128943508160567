import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

interface IHbAlgorithmBlockCardProps {
  algorithmText: string
  blockGroupName: string
  num: number
}

export const HbAlgorithmBlockCard: React.FC<IHbAlgorithmBlockCardProps> =
  observer((props) => {
    const { algorithmText, blockGroupName, num } = props
    return (
      <CardContainer className="flex justify-between border-1 border-gray-300 space-x-4">
        <Left className="flex flex-col space-y-0.5 items-start">
          <LeftLogicName className="font-weight-bold font-xs">
            {algorithmText}
          </LeftLogicName>
          <LeftGroupName className="font-sm">{blockGroupName}</LeftGroupName>
        </Left>
        <Right className="flex items-center justify-center">
          <Badge
            className="font-weight-bold flex items-center justify-center"
            style={{ marginTop: 2 }}
          >
            {num}
          </Badge>
        </Right>
      </CardContainer>
    )
  })

const CardContainer = styled.li`
  padding: 8px;
  border-radius: 8px;
`

const Left = styled.div``

const LeftLogicName = styled.div`
  color: #5f8bff;
`

const LeftGroupName = styled.div`
  color: #242526;
`

const Right = styled.div``

const Badge = styled.div`
  background: #fef3c7;
  color: #92400e;
  border-radius: 10px;
  padding: 2px 10px;
`
