import type {
  DOSTHbMessageNext,
  HB_MESSAGE_NEXT_STATEMENT,
} from '@storyplay/common'
import { jointPointSymbols } from '@storyplay/common/lib/studio/aop'
import { HbExtBlock } from '@storyplay/common/src/hellobot/types/block'
import { HbMessage, HbMessageNext, HbMessageObject } from '@storyplay/core'
import { hbApiClient } from './advisorHbApiClient'
import { hbAdvisor } from './hbAdvisorContainer'

const mock: HbMessageObject = {
  type: 'next',
  linkBlocks: [
    {
      items: [
        {
          kind: 'attribute',
          inputIds: [1913837],
          operator: 'equals',
          compareValue: '남자',
        },
        {
          kind: 'attribute',
          inputIds: [1913837],
          operator: 'equals',
          compareValue: '여자',
        },
      ],
      logic: 'and',
      blockIds: [628416, 628911, 628913, 628917],
    },
    {
      items: [
        {
          kind: 'attribute',
          inputIds: [1893962],
          operator: 'exists',
          compareValue: null,
        },
      ],
      logic: 'and',
      blockIds: [625576],
    },
    { blockIds: [628416, 626805] },
  ],
}

class DOSTHbMessageNextAdvisor {
  // @hbAdvisor(jointPointSymbols.DOSTAlgorithm.updateAlgorithmBlockGroup, 'pre')
  // async preUpdateAlgorithmBlockGroup(target: DOSTToBlock, toBlockNames: string[]) {}

  @hbAdvisor(jointPointSymbols.DOSTHbMessageNext.updateLinkBlock, 'post')
  async updateLinkBlock(
    target: DOSTHbMessageNext,
    ret: any, // return
    newValue: HbMessageNext | null
  ) {
    const hbStatement = target.st as HB_MESSAGE_NEXT_STATEMENT
    const block = target.block as unknown as HbExtBlock
    if (hbStatement.nextData === null) {
      const data = {
        type: 'next',
        linkBlocks: [
          {
            items: [
              {
                kind: 'attribute',
                inputIds: [1893962],
                operator: 'equals',
                compareValue: '1',
              },
              {
                kind: 'attribute',
                inputIds: [1887114],
                operator: 'equals',
                compareValue: '444',
              },
            ],
            logic: 'and',
            blockIds: [627032, 628356],
          },
          {
            items: [
              {
                kind: 'attribute',
                inputIds: [1406930],
                operator: 'greater',
                compareValue: '55',
              },
            ],
            logic: 'and',
            blockIds: [627032, 628381],
          },
          { blockIds: [627032] },
        ],
      } as HbMessageObject
      const payload = {
        data,
        kind: 'chatbot',
        sheetId: block.hbExtensionData.sheetId,
      } as HbMessage<HbMessageObject>

      const res = await hbApiClient.message.create(payload)
      target.updateData(res)
      return
    }
    if (hbStatement.nextData.id) {
      const res = await hbApiClient.message.update(
        hbStatement.nextData.id,
        mock
      )
      target.updateData(res)
    }

    // const hbExtensionData = hbStatement.hbExtensionData
  }
}

const dosttoalgorithmadvisor = new DOSTHbMessageNextAdvisor()

export { dosttoalgorithmadvisor }
